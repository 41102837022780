<template>
    <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M20.6561 11.2959C20.6883 11.5344 20.5204 11.7537 20.2817 11.7847L18.2362 12.0504C17.9993 12.0812 17.7819 11.9147 17.7499 11.6779C17.7177 11.4394 17.8856 11.2201 18.1244 11.1891L20.1698 10.9234C20.4068 10.8926 20.6241 11.0591 20.6561 11.2959Z"
            :fill="color" :stroke="color" stroke-width="0.3" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M19.1393 15.8065C18.9804 15.9848 18.7078 16.0028 18.5268 15.8468L16.9857 14.5184C16.8005 14.3587 16.782 14.0782 16.9447 13.8956C17.1037 13.7173 17.3763 13.6993 17.5573 13.8553L19.0984 15.1837C19.2836 15.3434 19.3021 15.6239 19.1393 15.8065Z"
            :fill="color" :stroke="color" stroke-width="0.3" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M15.9996 18.4604C15.772 18.5417 15.5213 18.4247 15.4374 18.198L14.7415 16.3169C14.6563 16.0866 14.7755 15.831 15.0068 15.7484C15.2344 15.667 15.4851 15.784 15.569 16.0107L16.2649 17.8918C16.3502 18.1222 16.2309 18.3777 15.9996 18.4604Z"
            :fill="color" :stroke="color" stroke-width="0.3" />
        <path
            d="M1 5.22913C1 5.22913 2.87637 4.40933 3.55372 4.20969L8.09208 1.39258C8.09208 1.39258 7.55158 3.57644 7.56731 3.98777C7.58304 4.3991 8.8619 4.88676 9.65503 5.47334C10.4482 6.05993 10.6705 7.46965 11.1716 8.74545L14.548 10.0729C14.548 10.0729 14.8594 11.2576 13.3026 12.6844C13.3026 12.6844 13.083 13.0157 12.3871 12.8242L9.50841 12.2841L10.4383 14.0815C10.4383 14.0815 10.0417 15.3548 8.85222 14.478C6.41869 12.6844 5.33525 14.1132 5.33525 14.1132L1.8753 18.1017L1 5.22913Z"
            :fill="color" :stroke="color" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

</template>
  
<script>
export default {
    name: "IconItching",

    props: {
        color: {
            type: String,
            requied: true,
        },
    },

    data() {
        return {};
    },
};
</script>
  
<style lang="scss">

</style>