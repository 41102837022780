<template>
    <div class="graph-container graph-respiratory-rate">
        <template v-if="this.series[0].data.length">
            <div class="graph-container graph-heart-rate">
                <template v-if="this.series[0].data.length && this.getHealthRangeType != 'day'">
                    <apexchart type="line" :options="chartOptions" :series="series" :height="'100%'"></apexchart>
                </template>
                <template v-else-if="this.daily_series[0].data.length && this.getHealthRangeType == 'day'">
                    <apexchart type="line" :options="chartDailyOptions" :series="daily_series" :height="'100%'"></apexchart>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { useCssVar } from '@vueuse/core'
import { ref } from 'vue'

import moment from "moment";

const basicGrid = {
    show: true,
    borderColor: '#D9D9D9',
    strokeDashArray: 3,
    position: 'back',
    xaxis: {
        lines: {
            show: true
        }
    },
    yaxis: {
        lines: {
            show: false
        }
    },
    row: {
        colors: undefined,
        opacity: 0.5
    },
    column: {
        colors: undefined,
        opacity: 0.5
    },
    padding: {
        top: 0,
        right: 0,
        bottom: -5,
        left: 0
    },
};



export default {
    name: "GraphRespiratoryRate",

    data() {
        const defaultFont = useCssVar('--default-font', ref(null));
        return {
            series: [{
                data: []
            }],
            daily_series: [{
                data: []
            }],

            chartOptions: {
                theme: {
                    monochrome: {
                        enabled: true,
                        color: '#48E3C7',
                    }
                },

                markers: {
                    size: 3,
                    colors: '#EFFCFA',
                    strokeColors: '#399D92',
                    strokeWidth: 2,
                    strokeOpacity: 1,
                    shape: "circle",
                    showNullDataPoints: true,
                },
                stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    width: 3,
                },
                xaxis: {
                    tickPlacement: 'between',
                    labels: {
                        style: {
                            colors: '#8C8C8C',
                            fontSize: '10px',
                            fontFamily: defaultFont,
                            fontWeight: 400,
                            cssClass: 'apexcharts-xaxis-label',
                        },
                        formatter: function (value) {
                            return moment(value).format('ddd')
                        },
                    },
                    type: 'category',
                    axisBorder: {
                        show: false,
                        offsetY: -15,
                    },
                }
            },
            chartDailyOptions: {
            }
        };
    },

    methods: {
        ...mapActions("health", ["fetchHealth"]),

        async changevalue(startDate, endDate) {
            let data = { start: startDate, end: endDate, type: 'RR', entity_id: localStorage.getItem('entityId') };
            if (this.getHealthRangeType == 'day') {
                data.history = true;
            }
            await this.fetchHealth(data)
            if (this.getHealthRangeType == 'day') {
                this.daily_series = [{
                    data: [...this.getCurrentHealth]
                }];
            } else {
                this.series = [{
                    data: [...this.getCurrentHealth]
                }];
            }
        },
        enumerateDaysBetweenDates(startDate, endDate) {
            var dates = [];

            while (startDate.diff(endDate) <= 0) {
                dates.push(startDate.clone().toDate().valueOf());
                startDate = startDate.clone().add(1, 'days');
            }

            return dates;
        },

        async updateTheme() {
            const defaultFont = useCssVar('--default-font', ref(null));
            if (this.getCurrentDate) {
                if (this.getHealthRangeType == 'day') {
                    let dateStart = moment(this.getCurrentDate).clone().startOf('day');
                    let today = dateStart.format('YYYY-MM-DD');

                    await this.changevalue(today, today);

                    this.chartDailyOptions = {
                        ...this.basicOptions,
                        xaxis: {
                            type: 'datetime',
                            tickAmount: 6,
                            forceNiceScale: true,
                            min: dateStart.toDate().valueOf(),
                            max: moment(this.getCurrentDate).clone().endOf('day').toDate().valueOf(),
                            labels: {
                                style: {
                                    colors: '#8C8C8C',
                                    fontSize: '10px',
                                    fontFamily: defaultFont,
                                    fontWeight: 400,
                                    cssClass: 'apexcharts-xaxis-label',
                                },
                                formatter: function (value) {
                                    let _val = Math.round(value / 10) * 10;
                                    return moment(_val).format("HH:mm");
                                },
                                offsetX: 10,
                            },
                            axisBorder: {
                                show: false,
                                offsetY: -15
                            },
                        },
                        yaxis: {
                            tickAmount: 2,
                            opposite: true,
                            min: 0,
                            max: this.respiratoryRateMax ? (Math.round(this.respiratoryRateMax / 10) * 10) + 10 : 40,
                            labels: {
                                style: {
                                    colors: '#1B1C1E',
                                    fontSize: '10px',
                                    fontFamily: defaultFont,
                                    fontWeight: 400,
                                    cssClass: 'apexcharts-yaxis-label',
                                },
                                formatter: function (val) {
                                    return val.toFixed(0);
                                },
                                offsetX: -5,
                            },
                        }
                    };
                } else if (this.getHealthRangeType == 'week') {
                    await this.changevalue(moment(this.getCurrentDate).clone().startOf('isoWeek').format('YYYY-MM-DD'), moment(this.getCurrentDate).clone().endOf('isoWeek').format('YYYY-MM-DD'));

                    this.chartOptions = {
                        ...this.basicOptions,
                        xaxis: {
                            type: 'category',
                            tickAmount: 10,
                            tickPlacement: 'between',
                            forceNiceScale: false,
                            labels: {
                                style: {
                                    colors: '#8C8C8C',
                                    fontSize: '10px',
                                    fontFamily: defaultFont,
                                    fontWeight: 400,
                                    cssClass: 'apexcharts-xaxis-label',
                                },
                                formatter: function (value) {
                                    return moment(value).format('ddd')
                                },
                            },
                            categories: this.enumerateDaysBetweenDates(moment(this.getCurrentDate).clone().startOf('isoWeek'), moment(this.getCurrentDate).clone().endOf('isoWeek')),
                            axisBorder: {
                                show: false,
                                offsetY: -15,
                            },
                        },
                        yaxis: {
                            tickAmount: 3,
                            min: 0,
                            max: this.respiratoryRateMax ? (Math.round(this.respiratoryRateMax / 10) * 10) + 10 : 40,
                            forceNiceScale: true,
                            opposite: true,
                            labels: {
                                style: {
                                    colors: '#1B1C1E',
                                    fontSize: '10px',
                                    fontFamily: defaultFont,
                                    fontWeight: 400,
                                    cssClass: 'apexcharts-yaxis-label',
                                },
                                formatter: function (val) {
                                    return val.toFixed(0);
                                },
                                offsetX: -5,
                            },
                        }
                    }
                } else if (this.getHealthRangeType == 'month') {
                    await this.changevalue(moment(this.getCurrentDate).clone().startOf('month').format('YYYY-MM-DD'), moment(this.getCurrentDate).clone().endOf('month').format('YYYY-MM-DD'))

                    this.chartOptions = {
                        ...this.basicOptions,
                        grid: {
                            ...basicGrid,
                            padding: {
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0
                            },
                        },
                        xaxis: {
                            type: 'datetime',
                            tickAmount: 5,
                            forceNiceScale: true,
                            tickPlacement: 'between',
                            labels: {
                                style: {
                                    colors: '#8C8C8C',
                                    fontSize: '10px',
                                    fontFamily: defaultFont,
                                    fontWeight: 400,
                                    cssClass: 'apexcharts-xaxis-label',
                                },
                                formatter: function (value) {
                                    return moment(value).format('D')
                                },
                            },
                            categories: this.enumerateDaysBetweenDates(moment(this.getCurrentDate).clone().startOf('month'), moment(this.getCurrentDate).clone().endOf('month')),
                            axisBorder: {
                                show: false,
                                offsetY: -15,
                            },    
                        },
                        yaxis: {
                            tickAmount: 3,
                            min: 0,
                            max: this.respiratoryRateMax ? (Math.round(this.respiratoryRateMax / 10) * 10) + 10 : 40,
                            forceNiceScale: true,
                            opposite: true,
                            labels: {
                                style: {
                                    colors: '#1B1C1E',
                                    fontSize: '10px',
                                    fontFamily: defaultFont,
                                    fontWeight: 400,
                                    cssClass: 'apexcharts-yaxis-label',
                                },
                                formatter: function (val) {
                                    return val.toFixed(0);
                                },
                                offsetX: -5,
                            },
                        }
                    }
                }
            }
        }
    },

    computed: {
        ...mapGetters("general", ["getCurrentDate"]),
        ...mapGetters("health", ["getHealthRangeType", "getCurrentHealth", "getCurrentHealthAverage"]),

        basicOptions() {
            let self = this;
            const defaultFont = useCssVar('--default-font', ref(null));
            return {
                chart: {
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    },
                    animations: {
                        enabled: false,
                    }
                },
                dataLabels: {
                    enabled: false,
                },
                theme: {
                    monochrome: {
                        enabled: true,
                        color: '#48E3C7',
                    }
                },
                xaxis: {
                            type: 'datetime',
                            tickAmount: 5,
                            forceNiceScale: true,
                            tickPlacement: 'between',
                            labels: {
                                style: {
                                    colors: '#8C8C8C',
                                    fontSize: '10px',
                                    fontFamily: defaultFont,
                                    fontWeight: 400,
                                    cssClass: 'apexcharts-xaxis-label',
                                },
                            },
                            axisBorder: {
                                show: false,
                                offsetY: -15,
                            },    
                        },
                annotations: {
                    position: 'back',
                    yaxis: [
                        {
                            y: 0,
                            borderColor: '#6DDFC0',
                            opacity: 1,
                            borderWidth: 2,
                            label: {
                                borderColor: "transparent",
                                textAnchor: 'start',
                                position: 'left',
                                style: {
                                    color: "#6DDFC0",
                                    fontSize: '12px',
                                    fontWeight: 600,
                                    fontFamily: defaultFont,
                                    background: "transparent"
                                },
                            }
                        },
                        {
                            y: 30,
                            borderColor: '#6DDFC0',
                            opacity: 1,
                            borderWidth: 2,
                            label: {
                                borderColor: "transparent",
                                textAnchor: 'start',
                                position: 'left',
                                style: {
                                    color: "#6DDFC0",
                                    fontSize: '12px',
                                    fontWeight: 600,
                                    fontFamily: defaultFont,
                                    background: "transparent"
                                },
                                text: 'Normal range'
                            }
                        },
                        {
                            y: 0,
                            y2: 30,
                            fillColor: '#E5F9EA',
                        },
                        {
                            y: this.respiratoryRateAverage?this.respiratoryRateAverage:0,
                            borderColor: "#BFBFBF",
                            borderWidth: 2,
                            opacity: 0.5,
                            strokeDashArray: 0,
                            label: {
                                borderColor: "transparent",
                                textAnchor: 'start',
                                offsetY: 5,
                                offsetX: 5,
                                style: {
                                    color: "#BFBFBF",
                                    fontSize: '12px',
                                    fontWeight: 600,
                                    fontFamily: defaultFont,
                                    background: "transparent"
                                },
                                text: this.respiratoryRateAverage?this.respiratoryRateAverage:""
                            }
                        }
                    ],
                },
                markers: {
                    size: 3,
                    colors: '#EFFCFA',
                    strokeColors: '#399D92',
                    strokeWidth: 2,
                    strokeOpacity: 1,
                    shape: "circle",
                    showNullDataPoints: true,
                },
                tooltip: {
                    enabled: true,
                    x: {
                        show: false,
                    },
                    y: {
                        title: {
                            formatter: function () {
                                return ''
                            },
                        },
                        formatter: function (val) {
                            if (val == null) {
                                return val
                            }
                            return Math.round(val) + ' ' + self.$t('health.heart_rate.unit').toUpperCase()
                        }
                    },
                    marker: {
                        show: false,
                    }
                },
                stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    width: 3,
                },
                grid: { ...basicGrid },
            }
        },
        dataHealthRate() {
            if (this.getHealthRangeType == "day") {
                return this.getCurrentHealth.filter(v => v != null).map(v => v[1]);
            }
            return this.getCurrentHealth.filter(v => v != null)
        },

        respiratoryRateAverage() {
            if (this.getHealthRangeType == "day" && this.getCurrentHealthAverage) {
                return Math.round(this.getCurrentHealthAverage,0);
            }
            let sum = this.dataHealthRate.reduce((a, b) => a + b, 0);
            let avg = Math.round((sum / this.dataHealthRate.filter(v => v != null).length) || 0, 0);
            return avg;
        },

        respiratoryRateMax() {
            return this.dataHealthRate.filter(v => v != null).length ? Math.max.apply(Math, this.dataHealthRate.filter(v => v != null)) : 0;
        }
    },

    watch: {
        getHealthRangeType() {
            this.updateTheme()
        },
        getCurrentDate() {
            this.updateTheme()
        }
    },
    created() {
        this.chartDailyOptions = { ...this.basicOptions };
        this.chartOptions = { ...this.basicOptions };
    },
    mounted() {

        this.updateTheme();
    },
}
</script>

<style lang="scss" scoped>
.graph-container {
    margin: -1em -0em -1em 0.5em;
    width: 100%;
    aspect-ratio: 16/10;
}
</style>