<template>
  <header class="icp-header">
    <nav class="icp-header-nav">
      <div @click="$router.go(-1)" class="icp-header-back nav-link">
        <template v-if="getMobileOperatingSystem() == 'iOS'">
          <icon-chevron :iconDirection="'left'" :iconColor="'black'" :iconSize="15" />
          {{ $filters.capitalize($t('global.back')) }}
        </template>
        <template v-else>
          <icon-arrow />
        </template>
      </div>
      <div v-if="$route.params.activityType || $route.meta.titleKey" class="icp-header-title">{{
        $filters.capitalize($t(($route.path == '/heart-rate' || $route.path == '/respiratory-rate' || $route.path ==
          '/heart-signature' ? 'health.' : 'activity.') + $filters.lowerCase($route.params.activityType ?
            $route.params.activityType : $route.meta.titleKey) + '.title')) }}</div>
    </nav>
  </header>
</template>

<script>
import IconChevron from "../svg/IconChevron.vue";
import IconArrow from "../svg/IconArrow.vue";

export default {
  name: "TheNavBar",

  components: {
    IconChevron,
    IconArrow,
  },

  data() {
    return {};
  },

  methods: {
    getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
      }

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      return "unknown";
    }
  },
};
</script>

<style lang="scss" scoped>
/* .icp-header {
  height: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: #F2F8FD;
  user-select: none;

  &-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1.5em 1em;
    background-color: #F2F8FD;
  }

  &-back {
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
    text-decoration: none;
  }

  &-tips {
    margin: 1em 1em 0em 1em;
  }

  &-title {
    font-weight: 400;
    font-size: var(--default-font-size-normal);
    line-height: 22px;
    color: #000000;
  }
} */

.nav-link {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #5a5a5a;
  // align-self: baseline;
  display: flex;
  gap: 0.5em;
  position: absolute;
  left: 1em;
}

@media (prefers-color-scheme: dark) {
  /* .icp-header {
    background-color: #010213;

    &-nav {
      background-color: #010213;
    }

    &-title {
      color: #F0F0F0;
    }
  } */

  .nav-link {
    color: #F0F0F0;
  }
}
</style>