<template>
    <div class="respiratoryRate-layout">
        <section class="health-section">
            <h3>
                {{ $filters.capitalize($t('health.respiratory_rate.sleeping')) }}
            </h3>
            <base-card>
                <graph-date-switcher>
                    <card-content
                        :iconWrapper="{ iconType: 'icon-lung' }">
                        <template v-slot:header>
                            {{ $filters.capitalize($t('health.respiratory_rate.title')) }}
                        </template>
                        <template v-slot:content>
                            <h5>
                                {{ $filters.upperCase($t('global.average')) }}
                            </h5>
                            <h2 class="no-data-rate" v-if="respiratoryRateAverage == 0">
                                {{ $filters.capitalize($t('global.no_data')) }}
                            </h2>
                            <p class="data-rate" v-else>
                                <strong>
                                    {{ respiratoryRateAverage }}
                                </strong>
                                {{ $filters.upperCase($t('health.respiratory_rate.unit')) }}
                                <health-rate-formater :value=Number(respiratoryRateAverage) type="respiratory_rate"/>
                            </p>
                            <graph-respiratory-rate />
                        </template>
                    </card-content>
                </graph-date-switcher>
            </base-card>
            <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 0.5em">
                <base-card class="extrem-card" :cardSize="1">
                    <div class="extrem-card-title">
                        {{ $filters.capitalize($t('global.minimum')) }}
                    </div>
                    <div v-if="respiratoryRateMin" class="extrem-card-content">
                        <div class="extrem-card-value">
                            {{ respiratoryRateMin }}
                        </div>
                        {{ $filters.upperCase($t('health.respiratory_rate.unit')) }}
                    </div>
                    <div v-else class="extrem-card-value">
                        {{ $filters.capitalize($t('global.no_data')) }}
                    </div>
                </base-card>
                <base-card class="extrem-card" :cardSize="1">
                    <div class="extrem-card-title">
                        {{ $filters.capitalize($t('global.maximum')) }}
                    </div>
                    <div v-if="respiratoryRateMax" class="extrem-card-content">
                        <div class="extrem-card-value">
                            {{ respiratoryRateMax }}
                        </div>
                        {{ $filters.upperCase($t('health.respiratory_rate.unit')) }}
                    </div>
                    <div v-else class="extrem-card-value">
                        {{ $filters.capitalize($t('global.no_data')) }}
                    </div>
                </base-card>
            </div>
        </section>
        <section class="health-section">
            <h3>
                {{ $filters.capitalize($t('health.respiratory_rate.about_title')) }}
            </h3>
            <base-card>
                <i18n-t v-if="isEntityCat()" tag="p" keypath="health.respiratory_rate.about_card_content_cat">
                    <template #br>
                        <br />
                    </template>
                </i18n-t>
                <i18n-t v-else tag="p" keypath="health.respiratory_rate.about_card_content">
                    <template #br>
                        <br />
                    </template>
                </i18n-t>
                <!-- <p>
                    {{ $filters.capitalize($t('health.respiratory_rate.about_card_content')) }}
                </p> -->
            </base-card>
        </section>
        <!-- <section class="health-section">
            <h3>
                {{ $filters.capitalize($t('health.respiratory_rate.blog_links_title')) }}
            </h3>
            <blog-card :blogImageSrc="'dog-goldensleeping.png'" :blogName="'activity.resting.blog_name_sleepquality'" />
            <blog-card :blogImageSrc="'dog-pugsleeping.png'" :blogName="'activity.resting.blog_name_regular'" />
        </section> -->
    </div>
</template>
  
<script>
import { mapGetters, mapActions } from "vuex";

import LayoutDefault from "@/layouts/LayoutDefault.vue";
import BaseCard from '@/components/ui/BaseCard.vue';
import CardContent from '@/components/ui/CardContent.vue';
import GraphDateSwitcher from "@/components/graphics/GraphDateSwitcher.vue";
import GraphRespiratoryRate from '@/components/graphics/GraphRespiratoryRate.vue';
import HealthRateFormater from '@/components/ui/HealthRateFormater.vue'
// import BlogCard from '@/components/ui/BlogCard.vue'

export default {
    name: "RespiratoryRate",

    components: {
        BaseCard,
        CardContent,
        GraphDateSwitcher,
        GraphRespiratoryRate,
        HealthRateFormater,
        // BlogCard
    },

    methods: {
        ...mapActions("general", ["setDayPicker"]),

        isEntityCat() {
            return this.getEntityType == "cat"
        },
    },

    computed: {
        ...mapGetters("health", ["getCurrentHealth", "getHealthRangeType", "getCurrentHealthAverage"]),
        ...mapGetters("user", ["getEntityType"]),

        dataRespiratoryRate() {
            return this.getCurrentHealth.filter(v => v != null).map(v => v[1]?v[1]:v);
        },
        respiratoryRateAverage() {
          if (this.getHealthRangeType == "day" && this.getCurrentHealthAverage) {
            return Math.round(this.getCurrentHealthAverage, 0);
          }
            let sum = this.dataRespiratoryRate.reduce((a, b) => a + b, 0);
            let avg = Math.round((sum / this.dataRespiratoryRate.filter(v => v != null).length) * 10 || 0) / 10;
            return Math.round(avg);
        },

        respiratoryRateMin() {
            return Math.round(this.dataRespiratoryRate.filter(v => v != null).length ? Math.min.apply(Math, this.dataRespiratoryRate.filter(v => v != null)) : 0);
        },

        respiratoryRateMax() {
            return Math.round(this.dataRespiratoryRate.filter(v => v != null).length ? Math.max.apply(Math, this.dataRespiratoryRate.filter(v => v != null)) : 0);
        }
    },

    created() {
        this.setDayPicker(true);
        this.$emit("update:layout", LayoutDefault);
    },
};
</script>
  
<style lang="scss" scoped>
.respiratoryRate-layout {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.data-rate {
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    color: #8C8C8C;
    font-family: var(--default-font);

    strong {
        font-weight: 700;
        font-size: 32px;
        line-height: 45px;
        color: #3B3B3B;
    }
}

.extrem-card {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    &-title {
        font-family: var(--default-font);
        font-weight: 700;
        font-size: var(--default-font-size-small);
        line-height: 20px;
        color: #3B3B3B;
    }

    &-content {
        display: flex;
        gap: 0.5em;
        align-items: baseline;
        font-family: var(--default-font);
        font-weight: 600;
        font-size: 12px;
        line-height: 17px;
        color: #8C8C8C;
    }

    &-value {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: #434343;
    }
}

@media (prefers-color-scheme: dark) {
    .data-rate {
        color: #8C8C8C;

        strong {
            color: #F0F0F0;
        }
    }

    .extrem-card {

        &-title {
            color: #F0F0F0;
        }

        &-content {
            color: #8C8C8C;
        }

        &-value {
            color: #F0F0F0;
        }
    }
}
</style>