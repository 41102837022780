<template>
  <div class="icon-wrapper" :class="[iconType]">
    <component :is="iconType" />
  </div>
</template>
  
<script>
import QuestionMark from "@/components/svg/QuestionMark.vue";
import IconHeartSleeping from "@/components/svg/IconHeartSleeping.vue";
import IconHeartSignature from "../svg/IconHeartSignature.vue";
import IconLung from "@/components/svg/IconLung.vue";
import IconLightning from "@/components/svg/IconLightning.vue";
import IconResting from "@/components/svg/IconResting.vue";
import IconRestingCat from "@/components/svg/IconRestingCat.vue";
import IconWalking from "@/components/svg/IconWalking.vue";
import IconWalkingCat from "@/components/svg/IconWalkingCat.vue";
import IconRunning from "@/components/svg/IconRunning.vue";
import IconRunningCat from "@/components/svg/IconRunningCat.vue";
import IconBarking from "@/components/svg/IconBarking.vue";
import IconEating from "@/components/svg/IconEating.vue";
import IconSleeping from "@/components/svg/IconSleeping.vue";

import IconDrinking from "@/components/svg/IconDrinking.vue";
import IconDriving from "@/components/svg/IconDriving.vue";
import IconGnawing from "@/components/svg/IconGnawing.vue";
import IconPetting from "@/components/svg/IconPetting.vue";
import IconPettingCat from "@/components/svg/IconPettingCat.vue";
import IconPlaying from "@/components/svg/IconPlaying.vue";
import IconTrotting from "@/components/svg/IconTrotting.vue";
import IconTrottingCat from "@/components/svg/IconTrottingCat.vue";
import IconUnwear from "@/components/svg/IconUnwear.vue";
import IconEmpty from "@/components/svg/IconEmpty.vue";
import IconOther from "@/components/svg/IconOther.vue";
import IconItching from "@/components/svg/IconItching.vue";
import IconHuman_walking from "@/components/svg/IconHumanwalking.vue";

export default {
  name: "IconWrapper",

  components: {
    QuestionMark,
    IconHeartSleeping,
    IconHeartSignature,
    IconLung,
    IconLightning,
    IconResting,
    IconRestingCat,
    IconWalking,
    IconWalkingCat,
    IconRunning,
    IconRunningCat,
    IconBarking,
    IconEating,
    IconDrinking,
    IconDriving,
    IconGnawing,
    IconPetting,
    IconPettingCat,
    IconPlaying,
    IconTrotting,
    IconTrottingCat,
    IconUnwear,
    IconEmpty,
    IconOther,
    IconItching,
    IconHuman_walking,
    IconSleeping
  },

  props: {
    iconType: {
      type: String,
      requied: true,
    }
  },

  data() {
    return {};
  },
};
</script>
  
<style lang="scss">
.icon-wrapper {
  display: flex;
  width: 34px;
  height: 34px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  min-width: 34px;
}

.icon-empty, .icon-other, .icon-unwear {
  background-color: lightgrey;

  svg {
    fill: black;
  }
}

.icon-resting, .icon-resting-cat, .icon-walking, .icon-walking-cat, .icon-running, .icon-running-cat, .icon-lightning, .icon-human_walking, .icon-driving, .icon-trotting, .icon-trotting-cat {
  background-color: #E0F0FF;

  svg {
    fill: #1D60E0;
  }
}

.icon-lung, .icon-sleeping {
  background-color: #EFFCFA;

  svg {
    fill: #399D92;
  }
}

.icon-heart-sleeping, .icon-heart-signature {
  background-color: #FEF1F1;

  svg {
    fill: #FF1449;
  }
}

.icon-barking, .icon-eating, .question-mark, .icon-drinking, .icon-gnawing, .icon-petting, .icon-petting-cat, .icon-playing, .icon-itching {
  background-color: #FFFAF0;

  svg {
    fill: #F79D00;
  }
}

@media (prefers-color-scheme: dark) {
  .icon-empty, .icon-other, .icon-unwear {
  background-color: lightgrey;

  svg {
    fill: black;
  }
}

.icon-resting, .icon-resting-cat, .icon-walking, .icon-walking-cat, .icon-running, .icon-running-cat, .icon-lightning, .icon-human_walking, .icon-driving, .icon-trotting, .icon-trotting-cat {
  background-color: #17306B;

  svg {
    fill: #418FDE;
  }
}

.icon-lung, .icon-sleeping {
  background-color: #274653;

  svg {
    fill: #399D92;
  }
}

.icon-heart-sleeping, .icon-heart-signature {
  background-color: #56163A;

  svg {
    fill: #FF1449;
  }
}

.icon-barking, .icon-eating, .question-mark, .icon-drinking, .icon-gnawing, .icon-petting, .icon-petting-cat, .icon-playing, .icon-itching {
  background-color: #604627;

  svg {
    fill: #F79D00;
  }
}
}
</style>