import { createRouter, createWebHistory } from 'vue-router';

import Login from '../views/Login.vue';
import ErrorPage from '../views/ErrorPage.vue';
import ErrorPage404 from '../views/ErrorPage404.vue';

import DashBoard from '../views/DashBoard.vue';

import HealthSummary from '../views/summary/HealthSummary.vue';
import HeartRate from '../views/health/HeartRate.vue';
import HeartSignature from '../views/health/HeartSignature.vue';
import RespiratoryRate from '../views/health/RespiratoryRate.vue';

import ActivitySummary from '../views/summary/ActivitySummary.vue';
import ExerciseLevel from '../views/activity/ExerciseLevel.vue';
import ActivityDetail from '../views/activity/ActivityDetail.vue';
import HeartSignatureSummary from "../views/summary/HeartSignatureSummary";
import ActivitiesList from '@/views/activity/ActivitiesList.vue';

const routes = [
  {
    path: '/login',
    alias: ['/start_session/'],
    name: 'Login',
    meta: { title: 'Smart Dog Collar - Login' },
    component: Login
  },
  {
    path: '/activity/:activityType',
    name: 'activityDetail',
    meta: { title: 'Smart Dog Collar - Activity Detail' },
    component: ActivityDetail
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: { title: 'Smart Dog Collar - Dashboard' },
    component: DashBoard,
  },
  {
    path: '/health-summary',
    name: 'Health Summary',
    meta: { title: 'Smart Dog Collar - Health Summary' },
    component: HealthSummary
  },
  {
    path: '/heart-rate',
    name: 'Heart Rate',
    meta: { title: 'Smart Dog Collar - Heart Rate', titleKey: 'heart_rate' },
    component: HeartRate
  },
  {
    path: '/heart-signature',
    name: 'Heart Signature',
    meta: { title: 'Smart Dog Collar - Heart Signature', titleKey: 'heart_signature' },
    component: HeartSignature
  },
  {
    path: '/heart-signature-summary',
    name: 'Heart Signature Summary',
    meta: {title: 'Smart Dog Collar - Heart Signature Summary', titleKey: 'heart_signature'},
    component: HeartSignatureSummary
  },
  {
    path: '/respiratory-rate',
    name: 'Respiratory Rate',
    meta: { title: 'Smart Dog Collar - Respiratory Rate', titleKey: 'respiratory_rate' },
    component: RespiratoryRate
  },
  {
    path: '/activity-summary',
    name: 'Activity Summary',
    meta: { title: 'Smart Dog Collar - Activity Summary' },
    component: ActivitySummary
  },
  {
    path: '/exercise-level',
    name: 'Exercise Level',
    meta: { title: 'Smart Dog Collar - Exercise Level', titleKey: 'exercise_level'  },
    component: ExerciseLevel
  },
  {
    path: '/activities-list',
    name: 'Activities List',
    meta: { title: 'Smart Dog Collar - Activities List', titleKey: 'activities_list'  },
    component: ActivitiesList
  },
  {
    path: '/error',
    name: 'error',
    meta: { title: 'Smart Dog Collar - Error' },
    component: ErrorPage
  },
  {
    path: "/:catchAll(.*)",
    name: '404',
    meta: { title: 'Smart Dog Collar - Error 404' },
    component: ErrorPage404
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

export default router
