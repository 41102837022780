<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="info-icon"
            d="M8.00379 -6.99051e-07C6.9033 -7.95259e-07 5.87096 0.209596 4.90677 0.628788C3.94258 1.04293 3.0945 1.61869 2.36252 2.35606C1.62549 3.08838 1.04749 3.93687 0.628492 4.90151C0.209498 5.86616 7.95636e-07 6.89899 6.99382e-07 8C6.0357e-07 9.09596 0.209498 10.1263 0.628492 11.0909C1.04748 12.0556 1.62549 12.9066 2.36252 13.6439C3.09449 14.3813 3.9451 14.9571 4.91434 15.3712C5.87853 15.7904 6.90835 16 8.00379 16C9.09923 16 10.1316 15.7904 11.1008 15.3712C12.065 14.9571 12.9156 14.3813 13.6526 13.6439C14.3846 12.9066 14.9601 12.0556 15.3791 11.0909C15.793 10.1263 16 9.09596 16 8C16 6.89899 15.793 5.86616 15.3791 4.90151C14.9601 3.93687 14.3821 3.08838 13.6451 2.35606C12.908 1.61869 12.0574 1.04293 11.0932 0.628789C10.129 0.209596 9.09923 -6.03285e-07 8.00379 -6.99051e-07ZM7.99621 6.87121C8.54646 6.87121 8.83673 7.14646 8.86702 7.69697L8.98817 10.9773C9.00331 11.2601 8.92002 11.4899 8.73829 11.6667C8.55151 11.8434 8.30667 11.9318 8.00379 11.9318C7.70595 11.9318 7.46364 11.8409 7.27686 11.6591C7.09008 11.4823 7.00174 11.2525 7.01183 10.9697L7.14056 7.70454C7.17085 7.14899 7.45607 6.87121 7.99621 6.87121ZM7.99621 4.12879C8.29405 4.12879 8.54898 4.21717 8.761 4.39394C8.96798 4.57071 9.07146 4.80303 9.07146 5.09091C9.07146 5.37879 8.96798 5.61111 8.761 5.78788C8.55403 5.96465 8.2991 6.05303 7.99621 6.05303C7.69333 6.05303 7.44092 5.96465 7.239 5.78788C7.03203 5.61111 6.92854 5.37879 6.92854 5.09091C6.92854 4.80303 7.03203 4.57071 7.239 4.39394C7.44092 4.21717 7.69333 4.12879 7.99621 4.12879Z"
            :fill="color" />
    </svg>
</template>
  
<script>
export default {
    name: "IconInformation",

    props: {
        color: {
            type: String,
            requied: false,
            default: "#1D60E0"
        },
    },

    data() {
        return {};
    },
};
</script>
  
<style lang="scss">
    .info-icon {
        fill: var(--accent-color);
    }

    @media (prefers-color-scheme: dark) {
        .info-icon {
            fill: var(--accent-color-dark);
        }
    }
</style>