<template>
    <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.6947 7.36322C19.4519 6.73666 18.9485 5.43697 17.9581 2.79134C17.9393 2.72444 17.9107 2.65981 17.8737 2.5988C17.303 1.53697 14.667 0.445312 9.9999 0.445312C5.22986 0.445312 2.58157 1.5857 2.09149 2.6691C2.0822 2.68841 2.07341 2.70782 2.06582 2.72754L2.06342 2.73322C1.05868 5.41849 0.549813 6.73201 0.305318 7.36322C0.0172784 8.10697 0 8.15146 0 8.39149C0 9.46467 1.07186 10.2633 3.277 10.8331C5.08195 11.2996 7.46956 11.5564 10 11.5564C12.5304 11.5564 14.9181 11.2996 16.723 10.8331C18.928 10.2633 20 9.46467 20 8.39149C20 8.15146 19.9827 8.10697 19.6947 7.36322Z"
            :fill="color" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M3.88941 2.44437C4.87239 1.98863 6.77047 1.4668 9.99993 1.4668C13.2294 1.4668 15.1276 1.98863 16.1105 2.44437C16.2957 2.53022 16.4146 2.71539 16.4155 2.91949C16.4164 3.12359 16.2992 3.30983 16.1148 3.39734C15.0437 3.9057 13.0708 4.55697 9.99993 4.55697C6.92871 4.55697 4.95612 3.90559 3.88514 3.39735C3.70074 3.30984 3.58356 3.12361 3.58448 2.9195C3.58539 2.7154 3.70423 2.53022 3.88941 2.44437Z"
            fill="#FFF3D7" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M5.84899 2.71218C6.51674 2.47122 7.80611 2.19531 9.9999 2.19531C12.1937 2.19531 13.4831 2.47122 14.1509 2.71218C14.2767 2.75757 14.3574 2.85547 14.358 2.96339C14.3586 3.0713 14.279 3.16977 14.1538 3.21604C13.4262 3.48482 12.086 3.82916 9.9999 3.82916C7.91361 3.82916 6.57361 3.48476 5.84609 3.21604C5.72083 3.16977 5.64123 3.07131 5.64185 2.96339C5.64247 2.85548 5.7232 2.75757 5.84899 2.71218Z"
            :fill="color" />
    </svg>
</template>
  
<script>
export default {
    name: "IconEating",

    props: {
        color: {
            type: String,
            requied: true,
        },
    },

    data() {
        return {};
    },
};
</script>
  
<style lang="scss">

</style>