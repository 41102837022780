<template>
  <component :is="layout" :dayPicker="isDayPicker">
    <router-view v-model:layout="layout"></router-view>
  </component>
</template>

<script>
export default {
  name: 'app',

  data() {
    return {
      layout: 'div',
      isDayPicker: false,
    };
  },
}

</script>

<style lang="scss">

* {
  margin: 0;
  padding: 0;
}

#app {
  position: relative;
}

.frozen-container {
  pointer-events: none;
}

a:active {
  background: none;
}
</style>
