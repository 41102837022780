<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M22.676,7.362C22.188,7.078 21.724,7.766 21.072,7.781C19.973,7.807 17.563,8.777 16.312,9.017C15.143,9.241 13.238,8.078 11.755,8.183C9.091,8.371 7.398,8.696 7.398,8.696C7.398,8.696 4.35,8.744 3.715,8.982C1.775,9.707 -0.024,10.947 0.205,12.668C0.327,13.588 1.303,11.181 3.09,10.236C4.659,9.405 5.695,10.115 5.695,10.115C5.695,10.115 5.562,11.165 5.432,11.928C5.324,12.562 5.6,13.002 5.579,13.326C5.566,13.53 5.304,13.875 5.304,13.875C5.304,13.875 4.063,14.604 3.668,14.734C3.14,14.909 3.128,15.252 2.619,15.451C2.007,15.691 2.316,15.378 1.227,15.816C0.592,16.071 0.283,16.383 0.521,16.802C0.9,17.468 0.621,17.293 1.725,17.207C2.069,17.18 4.215,16.804 4.215,16.804C4.215,16.804 4.576,16.85 5.058,16.717C5.362,16.633 7.115,16.064 7.115,16.064C7.115,16.064 7.978,15.858 8.405,15.74C8.878,15.608 9.955,15.275 9.955,15.275C9.955,15.275 10.461,15.225 10.807,15.223C11.131,15.221 11.535,15.23 11.898,15.207C12.446,15.171 13.314,15.068 13.314,15.068C13.314,15.068 14.053,15.055 14.759,15.129C15.359,15.192 16.166,15.107 16.166,15.107C16.166,15.107 17.054,15.447 17.216,15.561C17.586,15.821 17.928,15.74 18.725,15.977C19.648,16.252 20.001,16.084 20.666,16.385C21.34,16.69 22.165,16.705 22.495,16.732C23.31,16.8 23.465,16.303 23.439,15.961C23.375,15.151 22.253,15.323 22.253,15.323C22.253,15.323 21.895,15.231 21.723,15.094C21.551,14.956 21.545,15.046 21.282,14.821C21.019,14.596 20.7,14.653 20.457,14.432C20.241,14.236 19.098,14.365 19.045,13.934C18.945,13.125 19.112,12.59 19.112,12.59C19.112,12.59 19.448,12.481 19.623,12.478C19.815,12.474 20.171,12.536 20.508,12.548C20.803,12.558 20.918,12.524 21.124,12.528C21.386,12.532 21.56,12.588 21.779,12.578C22.388,12.549 22.635,11.962 22.635,11.962C22.635,11.962 22.97,11.82 22.978,11.596C22.988,11.352 22.807,11.188 22.764,10.926C22.717,10.639 22.877,10.158 22.872,9.771C22.865,9.114 22.452,8.885 22.369,8.317C22.304,7.87 22.928,7.509 22.676,7.362Z"
            :fill="color" />
        <path d="M10 4H16" stroke="#1D60E0" stroke-linecap="round" />
        <path d="M3 6L13 6" stroke="#1D60E0" stroke-linecap="round" />
    </svg>
</template>
  
<script>
export default {
    name: "IconRunningCat",

    props: {
        color: {
            type: String,
            requied: true,
        },
    },

    data() {
        return {};
    },
};
</script>
  
<style lang="scss"></style>