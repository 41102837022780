<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.1401 11.8603L14.9972 11.5936L15.6067 10.7174C15.8099 10.4253 15.7337 10.0317 15.4416 9.8285C15.1496 9.62532 14.7559 9.70151 14.5527 9.99358L13.7273 11.1872L10.6035 9.68882V1.16813C10.6035 0.81257 10.3241 0.533203 9.96859 0.533203C9.61303 0.533203 9.33366 0.81257 9.33366 1.16813V9.71421L6.33682 11.2888L5.44793 10.0063C5.24475 9.71421 4.8511 9.65072 4.55903 9.8412C4.26697 10.0444 4.20347 10.438 4.39395 10.7301L5.06697 11.7079L3.92411 11.9745C3.58125 12.0507 3.36537 12.3936 3.44156 12.7365C3.50506 13.0285 3.77173 13.2317 4.06379 13.2317C4.11458 13.2317 4.15268 13.2317 4.20347 13.219L5.25745 12.9777L5.06697 13.7777C4.97808 14.1206 5.19396 14.4634 5.53682 14.5396C5.58761 14.5396 5.6384 14.5523 5.6892 14.5523C5.98126 14.5523 6.23523 14.3619 6.31142 14.0698L6.67968 12.546L9.98129 10.819L13.3972 12.4571L13.7654 13.9682C13.8416 14.2603 14.0956 14.4507 14.3877 14.4507C14.4385 14.4507 14.4893 14.4507 14.54 14.4381C14.8829 14.3492 15.0861 14.0063 15.0099 13.6761L14.8194 12.8761L15.8734 13.1174C15.8734 13.1174 15.975 13.1301 16.0131 13.1301C16.3051 13.1301 16.5591 12.9269 16.6353 12.6349C16.7115 12.292 16.4956 11.9491 16.1528 11.873L16.1401 11.8603Z"
            :fill="color" />
        <path
            d="M16.2161 5.91788L14.9081 5.07978C14.197 4.62263 13.3462 4.59724 12.6097 4.99089C11.797 5.43534 11.3018 6.31153 11.3018 7.28932V9.32107L13.524 10.3877L14.0446 9.63854C14.2859 9.29568 14.6669 9.0925 15.0859 9.0925C15.3399 9.0925 15.5938 9.16869 15.8097 9.32107C16.3812 9.71473 16.5335 10.5147 16.1272 11.0862L16.051 11.1878L16.2796 11.2385C16.6097 11.3147 16.8891 11.5179 17.0669 11.7973C17.2447 12.0893 17.3081 12.4322 17.232 12.7624C17.0923 13.3465 16.5843 13.7528 16.0002 13.7528C15.8986 13.7528 15.8097 13.7528 15.7208 13.7148H15.6573C15.6827 13.9687 15.6192 14.2227 15.4796 14.464C15.3018 14.756 15.0224 14.9592 14.6923 15.0354C14.5907 15.0608 14.4891 15.0735 14.3875 15.0735C13.8034 15.0735 13.2954 14.6798 13.1557 14.1084L12.8637 12.8893L11.3145 12.1401V17.7275C11.3145 18.6672 12.0383 19.4418 12.9272 19.4418C12.978 19.4418 13.0288 19.4418 13.0668 19.4418L18.4637 18.1846C19.3145 18.1465 20.0002 17.3846 20.0002 16.4703V13.156C20.0002 10.1973 18.5526 7.4163 16.2288 5.93058L16.2161 5.91788Z"
            :fill="color" />
        <path
            d="M15.4415 9.84183C15.3272 9.76563 15.2002 9.72754 15.0859 9.72754C14.8827 9.72754 14.6923 9.82913 14.5653 10.0069L13.7399 11.2006L11.3145 10.0323V11.4418L13.4097 12.4577L13.778 13.9688C13.8542 14.2609 14.1081 14.4514 14.4002 14.4514C14.451 14.4514 14.5018 14.4514 14.5526 14.4387C14.8954 14.3498 15.0986 14.0069 15.0224 13.6768L14.8319 12.8768L15.8859 13.118C15.8859 13.118 15.9875 13.1307 16.0256 13.1307C16.3177 13.1307 16.5716 12.9276 16.6478 12.6355C16.724 12.2926 16.5081 11.9498 16.1653 11.8736L15.0224 11.6069L15.6319 10.7307C15.8351 10.4387 15.7589 10.045 15.4669 9.84183H15.4415Z"
            :fill="color" />
        <path
            d="M7.23814 12.9646L6.93338 14.209C6.79369 14.7804 6.28575 15.1741 5.70162 15.1741C5.60003 15.1741 5.49845 15.1741 5.39686 15.136C5.0667 15.0598 4.78733 14.8566 4.60955 14.5646C4.46987 14.336 4.40638 14.0693 4.43177 13.8027H4.36828C4.27939 13.8408 4.1778 13.8535 4.07622 13.8535C3.47939 13.8535 2.97145 13.4471 2.84446 12.863C2.76827 12.5328 2.83176 12.1899 3.00954 11.8979C3.18732 11.6058 3.46669 11.4153 3.79685 11.3392L4.02542 11.2884L3.88574 11.0852C3.69526 10.8058 3.61907 10.463 3.68256 10.1328C3.74605 9.80263 3.93653 9.51057 4.2032 9.32009C4.41907 9.16771 4.66035 9.09152 4.92701 9.09152C5.34606 9.09152 5.73972 9.29469 5.96829 9.63755L6.53972 10.463L8.67307 9.34549V7.32643C8.67307 6.34864 8.16513 5.45975 7.36512 5.0153C6.62861 4.62165 5.77781 4.64705 5.0667 5.10419L3.75875 5.94229C1.44763 7.42802 0 10.1963 0 13.1677V16.4693C0 17.3836 0.67302 18.1455 1.53652 18.1963L6.92068 19.4408C6.92068 19.4408 7.02227 19.4662 7.07306 19.4662C7.96195 19.4662 8.68577 18.6916 8.68577 17.7392V12.2026L7.23814 12.9646Z"
            :fill="color" />
        <path
            d="M5.54902 14.5139C5.54902 14.5139 5.65061 14.5266 5.7014 14.5266C5.99347 14.5266 6.24744 14.3361 6.32363 14.0441L6.69188 12.5202L8.68555 11.479V10.044L6.34902 11.2758L5.46013 9.99323C5.33315 9.81545 5.14267 9.71387 4.93949 9.71387C4.81251 9.71387 4.68552 9.75196 4.58393 9.82815C4.29187 10.0313 4.22838 10.425 4.41885 10.717L5.09187 11.6948L3.94901 11.9615C3.60615 12.0377 3.39028 12.3806 3.46647 12.7234C3.52996 13.0155 3.79663 13.2187 4.08869 13.2187C4.13949 13.2187 4.17758 13.2187 4.22838 13.206L5.28235 12.9647L5.09187 13.7647C5.00298 14.1075 5.21886 14.4504 5.56172 14.5266L5.54902 14.5139Z"
            :fill="color" />
    </svg>
</template>
  
<script>
export default {
    name: "IconLung",

    props: {
        color: {
            type: String,
            requied: true,
        },
    },

    data() {
        return {};
    },
};
</script>
  
<style lang="scss">

</style>