<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M19.452,14.837C19.452,14.837 19.917,14.81 20.149,14.814C20.446,14.819 20.643,14.882 20.891,14.871C21.581,14.838 21.86,14.173 21.86,14.173C21.86,14.173 22.24,14.013 22.25,13.759C22.26,13.483 22.056,13.297 22.007,13C21.954,12.675 22.134,12.13 22.129,11.692C22.121,10.949 21.654,10.689 21.56,10.045C21.486,9.54 22.193,9.131 21.907,8.964C21.355,8.642 20.827,9.499 20.091,9.439C18.634,9.32 16.953,10.517 15.531,10.646C14.189,10.767 13.411,9.079 10.872,9.083C7.994,9.088 3.646,11.12 2.018,14.128C1.672,14.769 1.732,16.169 1.81,16.387C2.047,17.05 3.541,18.216 4.675,18.668C5.814,19.121 8.251,19.092 8.643,19.107C9.31,19.132 11.572,18.8 11.977,18.721C12.689,18.581 18.603,17.608 18.91,17.3C19.277,16.932 19.187,16.039 19.24,15.654C19.259,15.52 19.452,14.837 19.452,14.837Z"/>
        <path d="M9.703,8.049C9.703,8.049 8.514,8.349 7.838,8.092C7.256,7.872 7.05,7.402 7.05,7.402L-1.527,5.405L-1.514,2.747L9.148,6.413C9.148,6.413 10.576,6.292 11.233,6.519C12.083,6.812 12.841,7.917 13.772,8.889C14.073,9.204 14.301,9.117 14.102,9.482C13.927,9.805 13.637,9.488 13.486,9.37C13.321,9.242 12.479,8.708 12.479,8.708C12.479,8.708 12.251,9.016 12.082,8.949C11.938,8.893 9.703,8.049 9.703,8.049Z"/>
    </svg>
</template>
  
<script>
export default {
    name: "IconPettingCat",

    props: {
        color: {
            type: String,
            requied: true,
        },
    },

    data() {
        return {};
    },
};
</script>
  
<style lang="scss"></style>