<template>
  <base-card :cardSize="2">
    <form
      class="login-form"
      v-on:submit.prevent="submitUserData(), (submitted = true)"
    >
      <h1>Connect to Smart Dog Collar</h1>
      <label for="inputEmail" class="sr-only">Email</label>
      <input
        v-model="email"
        id="inputUserId"
        class="form-control"
        :placeholder="'Email'"
        required
        autofocus
      />
      <label for="inputPassword" class="sr-only">Password</label>
      <input
        v-model="password"
        id="inputQrCodeId"
        class="form-control"
        :placeholder="'Password'"
        required
      />
      <button class="bic-button" type="submit" :disabled="submitted">
        {{ $t("login_signin") }}
      </button>
      <div>
        <h5 v-if="error">{{ $t("login_error") }}</h5>
      </div>
    </form>
  </base-card>
</template>

<script>
import router from '@/router'

import { mapActions } from "vuex";

import BaseCard from "../components/ui/BaseCard.vue";

export default {
  components: { BaseCard },
  name: "LoginPage",
  data() {
    return {
      email: null,
      password: null,
      error: null,
      submitted: false,
    };
  },

  computed: {
    user_data() {
      return { email: this.email, password: this.password };
    },
  },

  methods: {
    ...mapActions("user", ["fetchUser", "setToken"]),
    async submitUserData() {
      this.error = await this.fetchUser(this.user_data);
      this.submitted = false;
    },
  },

  created() {
  },
  
  mounted: function () {
    let parameters = this.$route.query;
    if (parameters['token']) {
        let token = parameters['token'];
        this.setToken(token);
        router.push('/dashboard');
    }
  },
};
</script>

<style scoped>
.login-form {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.bic-button {
    background-color: #f7941c;
    border-radius: 4px;
    color: white;
    width: 100%;
    padding: 1em;
    border: none;
    margin-top: 1em;
  }
</style>