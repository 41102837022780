<template>
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.63086 6.38635C3.52344 6.38635 3.43392 6.35055 3.3623 6.27893C3.29069 6.20732 3.25488 6.1178 3.25488 6.01038C3.25488 5.90653 3.29069 5.81881 3.3623 5.74719C3.43392 5.67558 3.52344 5.63977 3.63086 5.63977H6.06396V2.39026C6.06396 2.28642 6.09977 2.19869 6.17139 2.12708C6.243 2.05546 6.33073 2.01965 6.43457 2.01965C6.54199 2.01965 6.63151 2.05546 6.70312 2.12708C6.77474 2.19869 6.81055 2.28642 6.81055 2.39026V6.01038C6.81055 6.1178 6.77474 6.20732 6.70312 6.27893C6.63151 6.35055 6.54199 6.38635 6.43457 6.38635H3.63086ZM6.43994 11.2848C7.18831 11.2848 7.89193 11.1416 8.55078 10.8551C9.21322 10.5686 9.79688 10.173 10.3018 9.66809C10.8066 9.16321 11.2023 8.58134 11.4888 7.92249C11.7752 7.26005 11.9185 6.55465 11.9185 5.80627C11.9185 5.0579 11.7752 4.35429 11.4888 3.69543C11.2023 3.033 10.8066 2.44934 10.3018 1.94446C9.79688 1.43958 9.21322 1.0439 8.55078 0.757446C7.88835 0.470988 7.18294 0.327759 6.43457 0.327759C5.6862 0.327759 4.98079 0.470988 4.31836 0.757446C3.65951 1.0439 3.07764 1.43958 2.57275 1.94446C2.07145 2.44934 1.67757 3.033 1.39111 3.69543C1.10465 4.35429 0.961426 5.0579 0.961426 5.80627C0.961426 6.55465 1.10465 7.26005 1.39111 7.92249C1.67757 8.58134 2.07324 9.16321 2.57812 9.66809C3.08301 10.173 3.66488 10.5686 4.32373 10.8551C4.98617 11.1416 5.69157 11.2848 6.43994 11.2848Z"
            :fill="color" />
    </svg>
</template>

<script>
export default {
    name: "IconClock",

    props: {
        color: {
            type: String,
            requied: true,
        },
    },

    data() {
        return {};
    },
};
</script>
  
<style lang="scss">

</style>