<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M8.24715 16.5181C8.72814 15.9152 9.08832 15.1674 9.36797 14.4351C9.84791 14.3255 10.1367 14.4351 11.5862 14.9214C14.5 16 13.5143 14.7285 15.5 14.5C16.6775 14.3645 16.4719 14.8295 17.4666 14.9214C18.7714 15.044 18.906 14.1981 19.9834 15.733C20.3324 16.2324 20.8492 16.4006 21.3526 16.6128C21.4577 16.6568 21.5741 16.6982 21.6814 16.6594C21.8828 16.587 21.8246 16.2997 21.7597 16.1341C21.5204 15.5493 21.149 15.055 20.791 14.5634C20.4689 14.1235 20.2161 13.6292 19.7284 13.417C18.2289 12.7707 15.6088 13.9194 17.4666 10.3903C17.6389 10.0617 17.7865 9.54158 18.1445 9.54416C18.7217 9.54934 20.0684 10.2584 20.6434 10.129C20.9588 10.0591 21.3078 9.76929 21.5495 9.54416C21.6546 9.44842 22.0022 9.29308 22 9.10935C21.9933 8.72638 21.2966 8.48063 21.0685 8.30467C20.4957 7.86217 19.8604 7.36534 19.5494 6.63044C19.3727 6.21382 19.2049 6.01974 18.8693 5.77391C18.8626 5.40129 18.8917 5.03125 18.8872 4.66121C18.885 4.31188 18.9454 3.79951 18.5293 4.08157C18.0908 4.38174 17.7261 4.89928 17.4666 5.39611C16.9991 6.29145 14.5561 9.18706 14.5561 9.18706C14.5561 9.18706 13.9453 9.91679 13.131 9.96337C11.9341 10.0306 10.735 10.0048 9.5381 10.0074C8.99446 10.0074 8.45083 9.97372 7.92286 10.1342C7.41726 10.2894 6.75292 10.587 6.45528 10.763C6.15764 10.9389 6.09983 11.0361 5.86269 11.0626C5.62554 11.0892 3.7161 11.0347 2.71161 10.3903C2.46105 10.2299 1.72054 9.9349 2.11205 10.587C2.37827 11.0269 2.69371 11.4151 3.0181 11.798C3.92437 12.868 6.06825 11.7618 6.17116 11.6842C6.13313 11.7644 5.38815 13.3558 5.76623 15.3302C5.11745 15.3302 4.14652 15.0818 3.69909 15.7365C3.29416 16.3291 3.1219 17.095 2.85344 17.7704C2.71474 18.1197 2.5984 18.482 2.41943 18.8081C2.28073 19.0642 2.02121 19.248 2.03911 19.5688C2.07714 20.2313 2.6163 20.2339 2.99885 19.9182C3.91385 19.1652 4.05704 17.3771 4.05704 17.3771C4.35682 17.3822 4.65212 17.4107 4.94967 17.4469C5.4575 17.509 5.88257 17.7911 6.4083 17.7186C7.15582 17.6166 7.79503 17.0909 8.24715 16.5181Z"
            :fill="color" :stroke="color" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M10 4H16" stroke="#1D60E0" stroke-linecap="round" />
        <path d="M3 6L13 6" stroke="#1D60E0" stroke-linecap="round" />
    </svg>
</template>
  
<script>
export default {
    name: "IconRunning",

    props: {
        color: {
            type: String,
            requied: true,
        },
    },

    data() {
        return {};
    },
};
</script>
  
<style lang="scss"></style>