<template>
  <div class="main_error">
    <p>{{$t('page_not_found')}}</p>
    <h1>{{$t('error_404')}}</h1>
    <li class="link-back">
      <router-link class="nav-link" to="/">{{$t('go_back')}}</router-link>
    </li>
    <!-- <img class="error-invoxia" src="../assets/invoxia.jpg" alt="invoxia" /> -->
  </div>
</template>

<script>
/* import "@/assets/scss/style.scss";
 */
export default {
  name: "ErrorPage404"
};
</script>