<template>
    <base-card class="blog-card">
        <img class="blog-card-image" :src="require('@/assets/images/blog/' + blogImageSrc)" />
        {{ $filters.capitalize($t(blogName)) }}
    </base-card>
</template>

<script>
import BaseCard from '@/components/ui/BaseCard.vue'

export default {
    name: 'BlogCard',

    components: {
        BaseCard,
    },

    props: {
        blogImageSrc: {
            type: String,
            default: "dog.png"
        },
        blogName: {
            type: String,
            default: "global.available_soon",
        }
    }
}
</script>

<style lang="scss" scoped>
.blog-card {
    display: flex;
    flex-direction: row;
    gap: 1em;
    justify-content: flex-start;
    align-items: center;
    font-family: var(--default-font);
    font-style: normal;
    font-weight: 700;
    font-size: var(--default-font-size-normal);
    line-height: 22px;
    color: #3B3B3B;

    &-image {
        width: 62px;
        height: 62px;
        border-radius: 1em;
    }
}

@media (prefers-color-scheme: dark) {
    .blog-card {
        color : #F0F0F0;
    }
}
</style>