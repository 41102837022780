<template>
    <div ref="graph">
        <slot />
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from 'moment';

export default {
    name: 'GraphDateSwitcher',

    data() {
        return {
            touch: {
                startX: 0,
                startY: 0,
                endX: 0,
                endY: 0,
            },
        };
    },

    methods: {
        ...mapActions("general", ["setIsPageFrozen", "setCurrentDate"]),

        nextDay() {
            if (moment().startOf('day') >= moment(this.getCurrentDate)) {
                this.setCurrentDate(moment(this.getCurrentDate).clone().add(1, 'day'));
            }
        },

        previousDay() {
            this.setCurrentDate(moment(this.getCurrentDate).clone().add(-1, 'day'));
        },

        nextWeek() {
            if (moment().startOf('week') >= moment(this.getCurrentDate)) {
                this.setCurrentDate(moment(this.getCurrentDate).clone().add(7, 'day'));
            }
        },

        previousWeek() {
            this.setCurrentDate(moment(this.getCurrentDate).clone().add(-7, 'day'));
        },

        nextMonth() {
            if (moment().startOf('month') >= moment(this.getCurrentDate)) {
                this.setCurrentDate(moment(this.getCurrentDate).clone().add(1, 'month'));
            }
        },

        previousMonth() {
            this.setCurrentDate(moment(this.getCurrentDate).clone().add(-1, 'month'));
        },

        touchstart(event) {
            this.touch.startX = event.touches[0].clientX;
            this.touch.startY = event.touches[0].clientY;
            this.touch.endX = 0;
            this.touch.endY = 0;
        },

        touchmove(event) {
            this.touch.endX = event.touches[0].clientX;
            this.touch.endY = event.touches[0].clientY;
            let degree = Math.abs(Math.atan2(this.touch.endY - this.touch.startY, this.touch.endX - this.touch.startX) * 180 / Math.PI);
            if (degree >= 45 && degree <= 135) {
                this.touch.endX = this.touch.startX;
                this.touch.endY = this.touch.startY;
            } else {
                this.setIsPageFrozen(true);
            }
        },

        touchend() {
            if (
                !this.touch.endX ||
                Math.abs(this.touch.endX - this.touch.startX) < 20
            ) {
                this.setIsPageFrozen(false);
                return;
            }
            if (this.$route.name == 'activityDetail' || this.$route.name == 'Exercise Level') {
                if (this.touch.endX < this.touch.startX) {
                if (this.getActivityRangeType == 'day') {
                    this.nextDay();
                } else if (this.getActivityRangeType == 'week') {
                    this.nextWeek();
                } else if (this.getActivityRangeType == 'month') {
                    this.nextMonth();
                }
            } else {
                if (this.getActivityRangeType == 'day') {
                    this.previousDay();
                } else if (this.getActivityRangeType == 'week') {
                    this.previousWeek();
                } else if (this.getActivityRangeType == 'month') {
                    this.previousMonth();
                }
            }
            } else {
                if (this.touch.endX < this.touch.startX) {
                if (this.getHealthRangeType == 'day') {
                    this.nextDay();
                } else if (this.getHealthRangeType == 'week') {
                    this.nextWeek();
                } else if (this.getHealthRangeType == 'month') {
                    this.nextMonth();
                }
            } else {
                if (this.getHealthRangeType == 'day') {
                    this.previousDay();
                } else if (this.getHealthRangeType == 'week') {
                    this.previousWeek();
                } else if (this.getHealthRangeType == 'month') {
                    this.previousMonth();
                }
            }
            }
            this.setIsPageFrozen(false);
        },
    },

    computed: {
        ...mapGetters("general", ["getCurrentDate"]),
        ...mapGetters("health", ["getHealthRangeType"]),
        ...mapGetters("activity", ["getActivityRangeType"])
    },

    mounted() {
        this.$refs.graph.addEventListener("touchstart", (event) => this.touchstart(event), {
            passive: true,
        });
        this.$refs.graph.addEventListener("touchmove", (event) => this.touchmove(event), {
            passive: true,
        });
        this.$refs.graph.addEventListener("touchend", () => this.touchend(), {
            passive: true,
        });
    },

    unmounted() {
        this.$el.removeEventListener("touchstart", (event) => this.touchstart(event));
        this.$el.removeEventListener("touchmove", (event) => this.touchmove(event));
        this.$el.removeEventListener("touchend", this.touchend());
    }
}
</script>

<style lang="scss" scoped>

</style>