<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="icp-black" d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z" fill="none" />
    </svg>
</template>

<script>
export default {
    name: 'IconArrow',
    props: {
        iconColor: {
            type: String,
            default: "black",
        },
        iconSize: {
            type: Number,
            default: 10,
        },
        iconDirection: {
            type: String,
            default: "left",
        },
    },
};
</script>
  
<style lang="scss" scoped>
.chevron {
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    -ms-transition: -ms-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;

    &-right {
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }

    &-down {
        transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
    }

    &-left {
        transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
    }

    &-up {
        transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        -webkit-transform: rotate(270deg);
    }
}

.active {
    transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    -ms-transition: -ms-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    opacity: 0.8;
}

.icp-black {
    fill: #1B1C1E;
}

@media (prefers-color-scheme: dark) {
    .icp-black {
        fill: #F5F5F5;
    }
}
</style>