<template>
  <div class="summary-layout">
    <div v-show="loading" class="loading-report">
      <div class="spinner-container">
        <three-dots :iconColor="'heart'" />
      </div>
    </div>
    <transition-expand>
      <template v-if="!getLastHealthDataDate">
        <base-card>
        <card-content :iconWrapper="{ iconType: 'question-mark' }">
          <template v-slot:header>
            {{ $filters.capitalize($t('health.summary.help_card_title')) }}
          </template>
          <template v-slot:content>
            <p v-if="isEntityCat()" v-html="$t('health.summary.help_card_content_cat', { entityName: getEntityName })"></p>
            <p v-else v-html="$t('health.summary.help_card_content', { entityName: getEntityName })">
            </p>
          </template>
        </card-content>
      </base-card>
    </template>
  </transition-expand>
  <section class="summary-section">
    <h3>
      {{ $filters.capitalize($t(yesterdaySummary ? 'health.summary.yesterday_summary' : 'health.summary.today_summary')) }}
    </h3>
    <base-card :cardLink="getLastHealthDataDate ? '/heart-rate' : null">
      <card-content :iconWrapper="{ iconType: 'icon-heart-sleeping' }">
        <template v-slot:header>
          {{ $filters.capitalize($t('health.heart_rate.sleeping')) }}
        </template>
        <template v-slot:content>
          <three-dots v-if="getLoadingTodayHR" :iconColor="'heart'" />
          <p class="data-rate" v-else-if="getTodayHR">
            <strong>
              {{ $filters.roundInt(getTodayHR) }}
            </strong>
            {{ $filters.upperCase($t('health.heart_rate.unit')) }}
            
            <health-rate-formater :value=Number(getTodayHR) type="heart_rate"/>
          </p>
          <h2 class="no-data-rate-small heart" v-else>
            {{ $filters.capitalize($t('global.no_data_yet')) }}
          </h2>
        </template>
      </card-content>
    </base-card>
    <base-card :cardLink="getLastHealthDataDate ? '/respiratory-rate' : null">
      <card-content :iconWrapper="{ iconType: 'icon-lung' }">
        <template v-slot:header>
          {{ $filters.capitalize($t('health.respiratory_rate.sleeping')) }}
        </template>
        <template v-slot:content>
          <three-dots v-if="getLoadingTodayRR" :iconColor="'lung'" />
          <p class="data-rate" v-else-if="getTodayRR">
            <strong>
              {{ $filters.roundInt(getTodayRR) }}
            </strong>
            {{ $filters.upperCase($t('health.respiratory_rate.unit')) }}
            <health-rate-formater :value=Number(getTodayRR) type="respiratory_rate"/>
          </p>
          <h2 class="no-data-rate-small lung" v-else>
            {{ $filters.capitalize($t('global.no_data_yet')) }}
          </h2>
        </template>
      </card-content>
    </base-card>
  </section>
    <section class="summary-section">
      <h3>
        {{ $filters.capitalize($t('health.summary.last_week_summary')) }}
      </h3>
      <base-card :cardLink="getLastHealthDataDate ? '/heart-signature' : null">
        <card-content :iconWrapper="{ iconType: 'icon-heart-signature' }">
          <template v-slot:header>
            {{ $filters.capitalize($t('health.heart_signature.title')) }}
          </template>
          
          <template v-slot:content>
            <health-rate-formater v-if="isHeartSignature" type="is_afib" :afibValue="getLastWeekAfibRisk" />
            <three-dots class="loading-heart-signature" v-if="getLoadingLastWeekPoincare"
            :iconColor="'heart'" />
            <graph-heart-signature :isSummary="true" v-if="isHeartSignature" />
            <p v-else-if="isEntityCat()" v-html="$t('health.heart_signature.help_card_content_cat_fmt', { productName: this.$t(this.getProductNameKey) })"></p>
            <p v-else v-html="$t('health.heart_signature.help_card_content_dog_fmt', { productName: this.$t(this.getProductNameKey) })"></p>
          </template>
        </card-content>
      </base-card>
    </section>
    <section class="summary-section">
      <h3>
        {{ $filters.capitalize($t('health.summary.export_your_data')) }}
      </h3>
      <base-card :backgroundColor="'purple'" style="position: relative;">
      <card-content>
        <template v-slot:content>
          <div style="display:flex; flex-direction: row;">
            <div style="position: absolute; display: contents;">
              <img v-if="isEntityCat()" style="display:flex; margin: 0 0 -1em -1em; width:125px;"
              src="@/assets/images/collar/veterinary-cat.png" />
              <img v-else style="display:flex; margin: 0 0 -1em -1em; width:125px;"
              src="@/assets/images/collar/veterinary-dog.png" />
            </div>
            <div style="display: flex; flex-direction: column; gap: 0.5em; justify-content: center;">
              <h4>
                {{ $filters.capitalize($t('health.export_data.card_export_content', {
                  entityName:
                  getEntityName
                })) }}
              </h4>
              <!-- If the difference between the current date and the date of last is > 30 and there's an error while generating the PDF -> buttons doesn't show -->
              <div class="share-btn" v-if="diffDate <= 30 && !fileError">
                <button class="export-data-card" @click="healthReportAction" :disabled="reportGenerated">
                  <span v-if="!reportGenerated">{{ $filters.capitalize($t('health.export_data.card_export_link')) }}</span>
                  <span v-else>{{ $filters.capitalize($t('health.export_data.card_export_done')) }}</span>
                </button>
                <button class="export-data-card share-icon" @click="shareFile" v-if="reportGenerated">
                  <icon-download-android v-if="userOs == 'Android'" :color="'#685AF4'"/>
                  <icon-download-ios v-else :color="'#685AF4'"/>
                </button>
              </div>
              <p v-else>
                {{ $filters.capitalize($t($route.name == "Health Summary" ? 
                'health.summary.waiting_data' :
                'activity.summary.waiting_data')) }}
              </p>
            </div>
          </div>
        </template>
      </card-content>
    </base-card>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { shallowRef } from 'vue';

import moment from 'moment';

import LayoutSummary from "@/layouts/LayoutSummary.vue";
import GraphHeartSignature from "@/components/graphics/GraphHeartSignature.vue";
import BaseCard from '@/components/ui/BaseCard.vue';
import CardContent from '@/components/ui/CardContent.vue';
import IconDownloadIos from '@/components/svg/IconDownloadIOS.vue';
import IconDownloadAndroid from '@/components/svg/IconDownloadAndroid.vue';
import ThreeDots from '@/components/svg/loader/ThreeDots.vue';
import TransitionExpand from '@/components/ui/TransitionExpand.vue';
import HealthRateFormater from '@/components/ui/HealthRateFormater.vue'

export default {
  name: "HealthSummary",
  
  components: {
    BaseCard,
    GraphHeartSignature,
    CardContent,
    IconDownloadIos,
    IconDownloadAndroid,
    ThreeDots,
    TransitionExpand,
    HealthRateFormater,
  },
  
  data() {
    return {
      heartSignature: null,
      yesterdaySummary: false,
      reportGenerated: false,
      reportFile: null,
      loading: false,
      userOs: null,
      fileError: false,
      diffDate: null,
    };
  },
  
  methods: {
    ...mapActions("general", ["setCurrentDate"]),
    ...mapActions("user", ["setToken", "setEntityID", "setUserDemo", "removeUserDemo", "fetchImageProfil", "fetchEntity", "fetchDevice", "setFlavor"]),
    ...mapActions("health", ["setHealthRangeType", "setHealthDemo", "removeHealthDemo", "fetchTodayHR", "fetchTodayRR",
      "fetchLastWeekPoincare", "fetchHealthReportUrl"]),
    
    async healthReportAction() {
      try {
        this.loading = true;

        console.log("Generating file...")
        await this.fetchHealthReportUrl({
          entity_id: localStorage.getItem('entityId')
        })

        let url = this.getHealthReportUrl;
        const content = await fetch(url);
        const blob = await content.blob();
        this.reportFile = new File([blob], 'health-report.pdf', {type: 'application/pdf'});
        this.reportGenerated = true;
        console.log('File Generated');

        this.loading = false;
      } catch (err) {
        console.log(err)
        this.loading = false;
        this.fileError = true;
      }
    },
    
    async shareFile() {
      console.log('Sharing ...')
      
      if (this.userOs == 'Android') {
        // eslint-disable-next-line
        if (NvxAndroid.shareDataUrl != null) {
          
          try {
            const stringFile = await this.base64File(this.reportFile)
            new Promise((resolve) => {
              // eslint-disable-next-line
              NvxAndroid.shareDataUrl(this.reportFile.name, "fileDataAsURL", stringFile)
              resolve()
            });
          } catch (err) {
            console.log(err)
            this.loading = false;
            this.fileError = true;
          }
        }
      }
      else {
        
        await navigator.share({
          files: [this.reportFile]
        }).then(() => {
          this.loading = false;
        })
        .catch(console.error)
      }
      
    },
    
    async base64File(file) {
      
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    
    getMobileOs(){
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        return "Android";
      } else {
        return 'IOS';
      }
    },

    isEntityCat() {
      return this.getEntityType == "cat"
    },
  },
  
  computed: {
    ...mapGetters("user", ["getToken", "getEntityId", "getEntityName", "getLastHealthDataDate", "getEntityType", "getDeviceBoardname", "isDeviceLWT6", "getProductNameKey"]),
    ...mapGetters("health", ["getTodayHR", "getLoadingTodayHR", "getTodayRR", "getLoadingTodayRR",
      "getLastWeekPoincare", "getLastWeekAfibRisk", "getLoadingLastWeekPoincare", "getLoadingLastWeekPoincare", "getHealthReportUrl"]),
    isRespiratoryRate() {
      if (this.getTodayRR) {
        return true;
      }
      return false;
    },
    isHeartRate() {
      if (this.getTodayHR) {
        return true;
      }
      return false;
    },
    isHeartSignature() {
      if (this.getLastWeekPoincare.length) {
        return true
      }
      return false;
    },
    isTodayData() {
      return this.getTodayHR && this.getTodayRR
    },
  },
  
  async mounted() {
    await this.fetchEntity();
    await this.fetchDevice();
    this.userOs = this.getMobileOs();
    if (this.getLastHealthDataDate) {
      let today = moment().format('YYYY-MM-DD');
      let yesterday = moment().add(-1, 'day').format('YYYY-MM-DD');
      let one_week_ago = moment().add(-6, 'day').format('YYYY-MM-DD');
      this.diffDate = moment(today).diff(this.getLastHealthDataDate, 'days')
      await this.fetchTodayHR({
        start: yesterday,
        end: today,
        type: 'HR',
        limit: 1,
        entity_id: localStorage.getItem('entityId')
      });
      await this.fetchTodayRR({
        start: yesterday,
        end: today,
        type: 'RR',
        limit: 1,
        entity_id: localStorage.getItem('entityId')
      });
      await this.fetchLastWeekPoincare({
        start: one_week_ago,
        end: today,
        entity_id: localStorage.getItem('entityId')
      });
      
    }
    
  },
  
  beforeCreate() {
    let parameters = this.$route.query;
    if (parameters["token"]) {
      let token = parameters["token"];
      localStorage.setItem('token', token);
    }
    
    if (parameters["entity_id"]) {
      let entity_id = parameters["entity_id"];
      localStorage.setItem('entityId', entity_id);
    }
  },
  
  async created() {
    let parameters = this.$route.query;
    if (parameters["token"]) {
      let token = parameters["token"];
      await this.setToken(token);
    }
    
    if (parameters["entity_id"]) {
      let entity_id = parameters["entity_id"];
      await this.setEntityID(entity_id);
    }

    if (parameters["flavor"]) {
      let flavor = parameters["flavor"];
      document.documentElement.setAttribute("flavor", flavor);
      await this.setFlavor(flavor);
    } else {
      await this.setFlavor(null);
    }

    await this.setCurrentDate(moment());
    await this.setHealthRangeType('week');
    
    const layout = shallowRef(LayoutSummary);
    this.$emit("update:layout", layout);
  },
  
};
</script>

<style lang="scss" scoped>
.data-rate {
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: #8C8C8C;
  font-family: var(--default-font);
  
  strong {
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    color: #3B3B3B;
  }
}

.loading-heart-signature {
  position: absolute;
  top: calc(50% - 14.5px);
  left: calc(50% - 30px);
}

.export-data-card {
  color: #685AF4;
  font-weight: 700;
  font-size: var(--default-font-size-small);
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 1em;
  background-color: white;
  &:disabled {
    opacity: .4;
  }
}


.loading-report {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #01021382;
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-container {
  background-color: #E0E0FA;
  width: fit-content;
  padding: 2rem;
  border-radius: 1rem;
}

.loader {
  --dim: 4rem;
  width: var(--dim);
  height: var(--dim);
  border: 7px solid #685AF4;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: spin_51 1.5s infinite linear;
}

.share-btn {
  display: flex;
  justify-content: space-between;
}

@keyframes spin_51 {
  from {
    transform: rotate(0)
  }
  
  to {
    transform: rotate(360deg)
  }
}

@media (prefers-color-scheme: dark) {
  .data-rate {
    color: #8C8C8C;
    
    strong {
      color: #F0F0F0;
    }
  }
  
  .spinner-container {
    background-color: #010213;
  }
}
</style>