<template>
  <div class="layout" :class="{ 'frozen-page': getPageFrozenState }">
    <nav-bar />
    <div class="layout-content">
      <div style="display: flex; gap: 0.5em">
        <base-card v-if="getDayPicker" @click="setRangeType('day')" class="card-date-switcher"
          :class="{ 'active': getRangeType == 'day' }" :cardSize="1">
          {{ $filters.capitalize($t('global.day')) }}
        </base-card>
        <base-card @click="setRangeType('week')" class="card-date-switcher"
          :class="{ 'active': getRangeType == 'week' }" :cardSize="1">
          {{ $filters.capitalize($t('global.week')) }}
        </base-card>
        <base-card @click="setRangeType('month')" class="card-date-switcher"
          :class="{ 'active': getRangeType == 'month' }" :cardSize="1">
          {{ $filters.capitalize($t('global.month')) }}
        </base-card>
      </div>
      <base-card v-if="getRangeType == 'day'" class="interval-picker">
        <icon-chevron @click="previousDay()" :iconColor="'black'" :iconDirection="'left'" />
        {{ $filters.formatDay(getCurrentDate) }}
        <icon-chevron @click="nextDay()" :iconColor="'black'" :iconDirection="'right'" />
      </base-card>
      <base-card v-if="getRangeType == 'week'" class="interval-picker">
        <icon-chevron @click="previousWeek()" :iconColor="'black'" :iconDirection="'left'" />
        {{ $filters.formatWeek(getCurrentDate) }}
        <icon-chevron @click="nextWeek()" :iconColor="'black'" :iconDirection="'right'" />
      </base-card>
      <base-card v-else-if="getRangeType == 'month'" class="interval-picker">
        <icon-chevron @click="previousMonth()" :iconColor="'black'" :iconDirection="'left'" />
        {{ $filters.formatMonthYear(getCurrentDate) }}
        <icon-chevron @click="nextMonth()" :iconColor="'black'" :iconDirection="'right'" />
      </base-card>
      <slot />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import moment from "moment";

import NavBar from "../components/layout/TheNavBar.vue";
import BaseCard from '../components/ui/BaseCard.vue';
import IconChevron from '../components/svg/IconChevron.vue';

export default {
  name: "LayoutDefault",

  components: {
    NavBar,
    BaseCard,
    IconChevron,
  },

  data() {
    return {
    };
  },

  methods: {
    ...mapActions("general", ["setCurrentDate"]),
    ...mapActions("health", ["setHealthRangeType"]),
    ...mapActions("activity", ["setActivityRangeType"]),

    nextDay() {
      if (moment().startOf('day') >= moment(this.getCurrentDate)) {
        this.setCurrentDate(moment(this.getCurrentDate).clone().add(1, 'day'));
      }
    },

    previousDay() {
      this.setCurrentDate(moment(this.getCurrentDate).clone().add(-1, 'day'));
    },

    nextWeek() {
      if (moment().startOf('week') >= moment(this.getCurrentDate)) {
        this.setCurrentDate(moment(this.getCurrentDate).clone().add(7, 'day'));
      }
    },

    previousWeek() {
      this.setCurrentDate(moment(this.getCurrentDate).clone().add(-7, 'day'));
    },

    nextMonth() {
      if (moment().startOf('month') >= moment(this.getCurrentDate)) {
        this.setCurrentDate(moment(this.getCurrentDate).clone().add(1, 'month'));
      }
    },

    previousMonth() {
      this.setCurrentDate(moment(this.getCurrentDate).clone().add(-1, 'month'));
    },

    setRangeType(rangeType) {
      let route = this.$route.name;
      if (route && ['activity', 'exercise'].some(element => route.toLowerCase().includes(element))) {
        this.setActivityRangeType(rangeType);
      } else {
        this.setHealthRangeType(rangeType);
      }
    }
  },

  computed: {
    ...mapGetters("general", ["getPageFrozenState", "getCurrentDate", "getDayPicker"]),
    ...mapGetters("health", ["getHealthRangeType"]),
    ...mapGetters("activity", ["getActivityRangeType"]),

    getRangeType() {
      let route = this.$route.name;
      if (route && ['activity', 'exercise'].some(element => route.toLowerCase().includes(element))) {
        return this.getActivityRangeType;
      } else {
        return this.getHealthRangeType;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.layout {
  // display: flex;
  // flex-direction: column;
  overflow: scroll;
  height: 100vh;

  &::-webkit-scrollbar {
    display: none;
  }
}

.layout-content {
  margin-top: 4em;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

/* .layout-content {
  padding: 1.5em;
  margin-top: 4em;
  background-color: #F2F8FD;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  user-select: none;
} */

.card-date-switcher {
  font-size: var(--default-font-size-small);
  line-height: 20px;
  font-weight: 500;
  padding: 0.75em 1em;
  color: #1B1C1E;
  font-family: var(--default-font);
  justify-content: center;
  align-items: center;
  width: stretch;
}

.interval-picker {
  align-items: center;
  font-size: var(--default-font-size-small);
  line-height: 20px;
  font-weight: 500;
  color: #1B1C1E;
  font-family: var(--default-font);
  display: flex;
  gap: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .chevron:first-child {
    margin-left: 1em;
  }

  .chevron:last-child {
    margin-right: 1em;
  }
}

.active {
  font-weight: 700;
}

.frozen-page {
  overflow-y: hidden !important;
}

@media (prefers-color-scheme: dark) {
  /* .layout-content {
    background-color: #010213
  } */

  .card-date-switcher {
    color: #F5F5F5;
  }

  .interval-picker {
    color: #F5F5F5;
  }
}
</style>