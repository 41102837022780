<template>
    <div class="graph-container">
        <template v-if="this.series[0].data.length">
            <apexchart type="bar" :options="chartOptions" :series="series" :height="'100%'"></apexchart>
        </template>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useCssVar } from '@vueuse/core'
import { ref } from 'vue'

import moment from "moment";

export default {
    name: "GraphActivity",

    data() {
        const defaultFont = useCssVar('--default-font', ref(null));
        return {
            xaxisLabel: [],
            series: [{
                data: []
            }],
            chartOptions: {
                theme: {
                    monochrome: {
                        enabled: true,
                        color: this.themeColor(),
                    }
                },
                plotOptions: {
                    bar: {
                        borderRadius: 3,
                        borderRadiusApplication: 'end',
                        borderRadiusWhenStacked: 'last',
                    }
                },
                chart: {
                    type: 'bar',
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    },
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 600,
                        animateGradually: {
                            enabled: true,
                            delay: 150
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350
                        }
                    }
                },
                dataLabels: {
                    enabled: false,
                },
              tooltip: {
                enabled: true,
                x: {show: false,},
                y: {
                  title: {
                    formatter: function () {return ''},
                  },
                  formatter: function (val) {
                    if (val < 60) {
                      return val + (val ? 'min' : '')
                    } else {
                      let valFormat = val / 60;
                      return valFormat + (Number.isInteger(valFormat) && valFormat ? 'h' : '');
                    }
                  }
                },
                marker: {show: false,}
              },
                grid: {
                    show: true,
                    borderColor: '#D9D9D9',
                    strokeDashArray: 3,
                    position: 'back',
                    xaxis: {
                        lines: {
                            show: true
                        }
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                    row: {
                        colors: undefined,
                        opacity: 0.5
                    },
                    column: {
                        colors: undefined,
                        opacity: 0.5
                    },
                    padding: {
                        top: 0,
                        right: -5,
                        bottom: -10,
                        left: -15
                    },
                },
                xaxis: {
                    type: 'numeric',
                    tickAmount: 4,
                    min: moment(this.getCurrentDate).clone().startOf('day').valueOf(),
                    categories: this.enumerateInterval(moment(this.getCurrentDate).clone().startOf('day').valueOf(), moment(this.getCurrentDate).clone().add('1', 'days').startOf('day').valueOf(), 30),
                    labels: {
                        style: {
                            colors: '#8C8C8C',
                            fontSize: '10px',
                            fontFamily: defaultFont,
                            fontWeight: 400,
                            cssClass: 'apexcharts-xaxis-activity-label',
                        },
                        formatter: function (val) {
                            let locale = navigator.language.substring(0, 2);
                            let _str = new Date(val).toLocaleString(locale, {  hour: '2-digit' }).split(' ').join('');
                            return _str.toLowerCase();
                        },
                        offsetY: -5,
                    },
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: false,
                    },
                },
                yaxis: {
                    type: 'numeric',
                    tickAmount: 2,
                    opposite: true,
                    min: 0,
                    labels: {
                        style: {
                            colors: '#434343',
                            fontSize: '10px',
                            fontFamily: defaultFont,
                            fontWeight: 400,
                        },
                        formatter: function (val) {
                            let valFormat = val / 60;
                            return valFormat + (Number.isInteger(valFormat) && valFormat ? 'h' : '');
                        },
                        offsetY: -5,
                    },
                }
            },
        };
    },

    methods: {
        ...mapActions("activity", ["fetchActivity"]),

        themeColor() {
            if (this.$route.params.activityType == 'resting' || this.$route.params.activityType == 'walking' || this.$route.params.activityType == 'running') {
                return '#1D60E0';
            } else if (this.$route.params.activityType == 'barking' || this.$route.params.activityType == 'eating' || this.$route.params.activityType == 'drinking' || this.$route.params.activityType == 'playing' || this.$route.params.activityType == 'petting' ) {
                return '#F79D00'
            } else {
                return '#d3d3d3';
            }
        },

        enumerateInterval(startHour, endHour, interval) {
            var intervals = [];

            startHour = moment(startHour).clone().add(interval, 'minutes').toDate().valueOf();

            while (startHour <= endHour) {
                intervals.push(startHour);
                startHour = moment(startHour).clone().add(interval, 'minutes').toDate().valueOf();
            }

            return intervals;
        },

        enumerateDaysBetweenDates(startDate, endDate) {
            var dates = [];

            while (startDate.diff(endDate) <= 0) {
                dates.push(startDate.clone().toDate().valueOf());
                startDate = startDate.clone().add(1, 'days');
            }

            return dates;
        },

        randomIntFromInterval(min, max) { // min and max included 
            return Math.floor(Math.random() * (max - min + 1) + min)
        },

        async changevalue(startDate, endDate) {
            await this.fetchActivity({ start: startDate, end: endDate, type: this.$filters.lowerCase(this.$route.params.activityType), entity_id: localStorage.getItem('entityId') })
            if (this.getActivityRangeType == 'day') {
                let currentDay = [];
                let index = 0;
                let hours = this.enumerateInterval(moment(this.getCurrentDate).clone().startOf('day').valueOf(), moment(this.getCurrentDate).clone().add('1', 'days').startOf('day').valueOf(), 30);
                hours.forEach(hour => {
                    currentDay.push([moment(hour).clone().add(-30, 'minutes').toDate().valueOf(), this.getCurrentActivity[index]])
                    index += 1;
                });
                this.series = [{
                    data: currentDay
                }];
            } else if (this.getActivityRangeType == 'month') {
                let currentMonth = [];
                let index = 0;
                let days = this.enumerateDaysBetweenDates(moment(this.getCurrentDate).clone().startOf('month'), moment(this.getCurrentDate).clone().endOf('month'));
                days.forEach(day => {
                    currentMonth.push([moment(day).clone().startOf('day').toDate().valueOf(), this.getCurrentActivity[index]])
                    index += 1;
                });
                this.series = [{
                    data: currentMonth
                }];
            } else {
                this.series = [{
                    data: this.getCurrentActivity
                }];
            }
        },

        async updateTheme() {
            const defaultFont = useCssVar('--default-font', ref(null));
            if (this.getCurrentDate) {
                if (this.getActivityRangeType == 'day') {
                    await this.changevalue(moment(this.getCurrentDate).clone().startOf('day').format('YYYY-MM-DD'), moment(this.getCurrentDate).clone().startOf('day').format('YYYY-MM-DD'))
                    this.chartOptions = {
                        annotations: {
                            position: 'back',
                            borderWidth: 0,
                            yaxis: [
                            ]
                        },
                        grid: {
                            show: true,
                            borderColor: '#D9D9D9',
                            strokeDashArray: 3,
                            position: 'back',
                            xaxis: {
                                lines: {
                                    show: true
                                }
                            },
                            yaxis: {
                                lines: {
                                    show: false
                                }
                            },
                            row: {
                                colors: undefined,
                                opacity: 0.5
                            },
                            column: {
                                colors: undefined,
                                opacity: 0.5
                            },
                            padding: {
                                top: 0,
                                right: 0,
                                bottom: -10,
                                left: -10
                            },
                        },
                        xaxis: {
                            type: 'numeric',
                            tickAmount: 4,
                            min: moment(this.getCurrentDate).clone().startOf('day').valueOf(),
                            max: moment(this.getCurrentDate).clone().add('1', 'days').startOf('day').valueOf(),
                            categories: this.enumerateInterval(moment(this.getCurrentDate).clone().startOf('day').valueOf(), moment(this.getCurrentDate).clone().add(1, 'days').startOf('day').valueOf(), 30),
                            labels: {
                                style: {
                                    colors: '#8C8C8C',
                                    fontSize: '10px',
                                    fontFamily: defaultFont,
                                    fontWeight: 400,
                                    cssClass: 'apexcharts-xaxis-activity-label',
                                },
                                formatter: function (val) {
                                  let locale = navigator.language.substring(0, 2);
                                  let _str = new Date(val).toLocaleString(locale, {hour: '2-digit'}).split(' ').join('');
                                  return _str.toLowerCase();
                                },
                                offsetY: -5,
                            },
                            axisTicks: {
                                show: true,
                            },
                            axisBorder: {
                                show: false,
                            },
                        },
                        yaxis: {
                            type: 'numeric',
                            tickAmount: 2,
                            opposite: true,
                            min: 0,
                            max: this.currentActivityMax,
                            labels: {
                                style: {
                                    colors: '#434343',
                                    fontSize: '10px',
                                    fontFamily: defaultFont,
                                    fontWeight: 400,
                                },
                                formatter: function (val) {
                                    if (val < 60) {
                                        return val + (val ? 'm' : '')
                                    } else {
                                        let valFormat = val / 60;
                                        return valFormat + (Number.isInteger(valFormat) && valFormat ? 'h' : '');
                                    }
                                },
                                offsetY: -5,
                                offsetX: 0,
                            },
                        }
                    }
                } else if (this.getActivityRangeType == 'week') {
                    await this.changevalue(moment(this.getCurrentDate).clone().startOf('isoWeek').format('YYYY-MM-DD'), moment(this.getCurrentDate).clone().endOf('isoWeek').format('YYYY-MM-DD'))
                    this.chartOptions = {
                        annotations: {
                            position: 'back',
                            yaxis: [
                                {
                                    y: this.activityAverage,
                                    borderColor: "#8C8C8C",
                                    borderWidth: this.activityAverage ? 2 : 0,
                                    opacity: 0.5,
                                    strokeDashArray: 0,
                                    // label: {
                                    //     borderColor: "transparent",
                                    //     textAnchor: 'start',
                                    //     offsetY: 5,
                                    //     offsetX: 5,
                                    //     style: {
                                    //         color: "#8C8C8C",
                                    //         fontSize: '10px',
                                    //         fontWeight: 600,
                                    //         fontFamily: defaultFont,
                                    //         background: "transparent"
                                    //     },
                                    //     text: this.activityAverageFormat
                                    // }
                                }
                            ],
                        },
                        grid: {
                            show: true,
                            borderColor: '#D9D9D9',
                            strokeDashArray: 3,
                            position: 'back',
                            xaxis: {
                                lines: {
                                    show: true
                                }
                            },
                            yaxis: {
                                lines: {
                                    show: false
                                }
                            },
                            row: {
                                colors: undefined,
                                opacity: 0.5
                            },
                            column: {
                                colors: undefined,
                                opacity: 0.5
                            },
                            padding: {
                                top: 0,
                                right: 5,
                                bottom: -10,
                                left: 0
                            },
                        },
                        xaxis: {
                            type: 'category',
                            tickAmount: 6,
                            min: moment(this.getCurrentDate).clone().startOf('isoWeek').toDate().valueOf(),
                            max: moment(this.getCurrentDate).clone().endOf('isoWeek').toDate().valueOf(),
                            categories: this.enumerateDaysBetweenDates(moment(this.getCurrentDate).clone().startOf('isoWeek'), moment(this.getCurrentDate).clone().endOf('isoWeek')),
                            labels: {
                                style: {
                                    colors: '#8C8C8C',
                                    fontSize: '10px',
                                    fontFamily: defaultFont,
                                    fontWeight: 400,
                                    cssClass: 'apexcharts-xaxis-label',
                                },
                                formatter: function (value) {
                                    return moment(value).clone().format('ddd')
                                },
                            },
                            axisBorder: {
                                show: false,
                            },
                        },
                        yaxis: {
                            type: 'numeric',
                            tickAmount: 2,
                            opposite: true,
                            min: 0,
                            max: this.currentActivityMax,
                            labels: {
                                style: {
                                    colors: '#434343',
                                    fontSize: '10px',
                                    fontFamily: defaultFont,
                                    fontWeight: 400,
                                },
                                formatter: function (val) {
                                    if (val < 60) {
                                        return val + (val ? 'm' : '')
                                    } else {
                                        let valFormatRest = val % 60;
                                        let valFormat = (val - valFormatRest) / 60;
                                        
                                        if (valFormatRest) {
                                            valFormatRest = (valFormatRest < 10 ? '0' + valFormatRest : valFormatRest)
                                        } else {
                                            valFormatRest = ''
                                        }

                                        return valFormat + 'h' + valFormatRest
                                    }
                                },
                                offsetX: 0,
                            },
                        }
                    }
                } else if (this.getActivityRangeType == 'month') {
                    await this.changevalue(moment(this.getCurrentDate).clone().startOf('month').format('YYYY-MM-DD'), moment(this.getCurrentDate).clone().endOf('month').format('YYYY-MM-DD'))
                    this.chartOptions = {
                        annotations: {
                            position: 'back',
                            yaxis: [
                                {
                                    y: this.activityAverage,
                                    borderColor: "#8C8C8C",
                                    borderWidth: this.activityAverage ? 2 : 0,
                                    opacity: 0.5,
                                    strokeDashArray: 0,
                                    // label: {
                                    //     borderColor: "transparent",
                                    //     textAnchor: 'start',
                                    //     offsetY: 5,
                                    //     offsetX: 5,
                                    //     style: {
                                    //         color: "#8C8C8C",
                                    //         fontSize: '10px',
                                    //         fontWeight: 600,
                                    //         fontFamily: defaultFont,
                                    //         background: "transparent"
                                    //     },
                                    //     text: this.activityAverageFormat
                                    // }
                                }
                            ],
                        },
                        grid: {
                            show: true,
                            borderColor: '#D9D9D9',
                            strokeDashArray: 3,
                            position: 'back',
                            xaxis: {
                                lines: {
                                    show: true
                                }
                            },
                            yaxis: {
                                lines: {
                                    show: false
                                }
                            },
                            row: {
                                colors: undefined,
                                opacity: 0.5
                            },
                            column: {
                                colors: undefined,
                                opacity: 0.5
                            },
                            padding: {
                                top: 0,
                                right: 0,
                                bottom: -10,
                                left: -15
                            },
                        },
                        xaxis: {
                            type: 'numeric',
                            tickAmount: 5,
                            min: moment(this.getCurrentDate).clone().startOf('month').toDate().valueOf(),
                            max: moment(this.getCurrentDate).clone().endOf('month').startOf('day').toDate().valueOf(),
                            categories: this.enumerateDaysBetweenDates(moment(this.getCurrentDate).clone().startOf('month'), moment(this.getCurrentDate).clone().endOf('month')),
                            labels: {
                                style: {
                                    colors: '#8C8C8C',
                                    fontSize: '10px',
                                    fontFamily: defaultFont,
                                    fontWeight: 400,
                                    cssClass: 'apexcharts-xaxis-activity-label',
                                },
                                formatter: function (value) {
                                    return moment(value).clone().format('D')
                                },
                                offsetY: -5,
                            },
                            axisBorder: {
                                show: false,
                            },
                        },
                        yaxis: {
                            type: 'numeric',
                            tickAmount: 2,
                            opposite: true,
                            min: 0,
                            max: this.currentActivityMax,
                            labels: {
                                style: {
                                    colors: '#434343',
                                    fontSize: '10px',
                                    fontFamily: defaultFont,
                                    fontWeight: 400,
                                },
                                formatter: function (val) {
                                    if (val < 60) {
                                        return val + (val ? 'm' : '')
                                    } else {
                                        let valFormatRest = val % 60;
                                        let valFormat = (val - valFormatRest) / 60;
                                        
                                        if (valFormatRest) {
                                            valFormatRest = (valFormatRest < 10 ? '0' + valFormatRest : valFormatRest)
                                        } else {
                                            valFormatRest = ''
                                        }

                                        return valFormat + 'h' + valFormatRest
                                    }
                                },
                                offsetY: -5,
                            },
                        }
                    }
                }
            }
        }
    },

    computed: {
        ...mapGetters("general", ["getCurrentDate"]),
        ...mapGetters("activity", ["getActivityRangeType", "getCurrentActivity"]),

        activityAverage() {
            let sum = this.getCurrentActivity.reduce((a, b) => a + b, 0);
            let avg = Math.round((sum / this.getCurrentActivity.filter(v => v != null).length) || 0);
            return avg;
        },

        activityAverageFormat() {
            let valFormat = moment.utc().startOf('day').add(this.activityAverage, 'minutes').format('H[h]mm');
            return valFormat
        },

        currentActivityMax() {
            let max = Math.max(...this.getCurrentActivity.map(o => o))

            if (this.getActivityRangeType == 'day') {
                return max != 0 ? (Math.ceil(max / 10) * 10) : 10;
            } else {
                return max != 0 ? (Math.ceil(max / 10) * 10) : 15;
            }
        }
    },

    watch: {
        getActivityRangeType() {
            this.updateTheme()

        },
        getCurrentDate() {
            this.updateTheme()
        }
    },

    mounted() {
        this.updateTheme();
        this.currentActivityMax;
    }
}
</script>

<style lang="scss" scoped>
.graph-container {
    width: 100%;
    aspect-ratio: 16/10;
}
</style>