<template>
  <div class="base-card">
    <slot />
    <icon-chevron class="card-chevron" :iconDirection="iconDirection" :iconColor="'black'"/>
  </div>
</template>

<script>
import IconChevron from '../svg/IconChevron.vue';

export default {
  name: "CollapseCard",

  components: {
    IconChevron,
  },

  props: {
    iconDirection: {
      type: String,
      default: "down",
    },

  },

  data() {
    return {};
  },
};
</script>

<style lang="scss">
.base-card {
  overflow: hidden;
  background-color: #ffffff;
  position: relative;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1em;
  padding: 1em;
  border-radius: 1em;
  box-shadow: 0px 0px 7px rgba(154, 154, 154, 0.08);
}

.background-purple {
  background-color: #E0E0FA;
}

.big-card {
  grid-column: span 2;
}

.card-chevron {
  position: absolute;
  right: 1em;
}

.card-chevron-hs {
  top: 1.75em;
}

@media (prefers-color-scheme: dark) {
  .base-card {
    background-color: #1F2038;
    box-shadow: 0px 0px 7px rgba(154, 154, 154, 0.08);
  }

  .background-purple {
    background-color: #8977FE;
  }
}</style>