<template>
  <div class="card-content">
    <header v-if="iconWrapper" class="card-header">
      <icon-wrapper :wrapperColor="iconWrapper.wrapperColor"
        :iconType="iconWrapper.iconType == 'human_walking' ? 'human_walking' : iconWrapper.iconType"
        :iconColor="iconWrapper.iconColor" />
      <h4>
        <slot name="header"></slot>
      </h4>
      <div class="beta-badge" v-if="isBeta">
        BETA
      </div>
    </header>
    <header v-else-if="isBeta" class="card-header">
      <h4>
        <slot name="header"></slot>
      </h4>
      <div class="beta-badge" v-if="isBeta">
        BETA
      </div>
    </header>
    <div>
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import IconWrapper from '../ui/IconWrapper.vue';

export default {
  name: "CardContent",

  components: {
    IconWrapper,
  },

  props: {
    iconWrapper: {
      type: Object,
      required: false,
    },
    isBeta: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      openModal: false,
    };
  },
};
</script>

<style lang="scss">
.card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  gap: 0.5em;

  &-title {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    font-weight: 600;
    font-size: var(--default-font-size-normal);
    line-height: 21px;
    color: #000000;
  }

  &-more {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #8b8b8b;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5em;
    text-decoration: none;
  }
}

.beta-badge {
  background: #E0F0FF;
  padding: 0 0.25em;
  /* Color-primary/400 */

  border: 1px solid #B5D7F8;
  border-radius: 4px;
  font-family: var(--default-font);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  color: #1D60E0;
}

@media (prefers-color-scheme: dark) {
  .beta-badge {
    color: #418FDE;
    background: #17306B;
    border: 1px solid #17306B;
  }
}
</style>