<template>
    <div v-if="loading" style="height:45px; display:flex; align-items:center">
        <three-dots :iconColor="type" />
    </div>
    <p :class="'data-rate-' + size" v-else>
        <template v-if="durationType">
            <template v-if="duration >= 60">
                <strong>
                    {{ durationHour }}
                </strong>
                <template v-if="format == 'time'">
                    :
                </template>
                <template v-else>
                    hr
                </template>
            </template>
            <template v-if="durationMinute">
                <strong>
                    {{ durationMinute }}
                </strong>
                <template v-if="format == 'time'">
                    {{ durationAmPm }}
                </template>
                <template v-else>
                    min
                </template>
            </template>
        </template>
        <template v-else-if="episodeType && type">
            <strong>
                {{ episodes }}
            </strong>
            {{ $filters.capitalize($t('global.unit_' + type, episodes)) }}
        </template>
        <template v-else-if="episodeType || episodeType == 0">
            <strong>
                {{ episodes }}
            </strong>
        </template>
    </p>
    <!-- <h2 :class="['no-data-rate-' + size, type]" v-else>
        {{ $filters.capitalize($t('global.no_data')) }}
    </h2> -->
</template>

<script>
import moment from 'moment';
import ThreeDots from '../../components/svg/loader/ThreeDots.vue';

export default {
    name: 'DataFormater',

    components: {
        ThreeDots,
    },

    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            required: false
        },
        duration: {
            type: Number,
            required: true,
        },
        episodes: {
            type: Number,
            required: true,
        },
        size: {
            type: Number,
            default: 2,
        },
        format: {
            type: String,
            default: null,
        }
    },

    computed: {
        durationType() {
            return (
                this.$props.duration || 
                this.$props.type == 'playing' || 
                this.$props.type == 'petting' || 
                this.$props.type == 'drinking' || 
                this.$props.type == 'barking' || 
                this.$props.type == 'eating' || 
                this.$props.type == 'resting' || 
                this.$props.type == 'walking' || 
                this.$props.type == 'running' || 
                this.$props.type == 'exercise' ||
                this.$props.type == 'trotting' ||
                this.$props.type == 'driving'
            );
        },
        episodeType() {
            return (this.$props.episodes);
        },
        durationMinute() {
            return this.$props.format == 'time' ? moment(this.$props.duration).format("mm") : moment.utc(moment.duration(this.$props.duration, "minutes").asMilliseconds()).format("m");
        },
        durationHour() {
            return this.$props.format == 'time' ? moment.duration(this.$props.duration, 'minutes').format("HH") : moment.utc(moment.duration(this.$props.duration, "minutes").asMilliseconds()).format("H");
        },
        durationAmPm() {
            return this.$props.format == 'time' ? moment(this.$props.duration).format("A") : '';
        }
    }
}
</script>

<style lang="scss" scoped>
.no-data-rate-1 {
    font-weight: 700;
    font-size: 22px;
    line-height: 45px;
    color: #434343;
}

.no-data-rate-2 {
    font-weight: 700;
    font-size: 22px;
    line-height: 45px;
    color: #434343;
}

.no-data-rate-3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #434343;
}

.data-rate-1 {
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    color: #8C8C8C;
    font-family: var(--default-font);

    strong {
        font-weight: 700;
        font-size: 32px;
        line-height: 45px;
        color: #3B3B3B;
    }
}

.data-rate-2 {
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    color: #8C8C8C;
    font-family: var(--default-font);

    strong {
        font-weight: 700;
        font-size: 24px;
        line-height: 34px;
        color: #3B3B3B;
    }
}

.data-rate-3 {
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    color: #8C8C8C;
    font-family: var(--default-font);

    strong {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: #3B3B3B;
    }
}

.data-rate-4 {
    font-family: var(--default-font);
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #3B3B3B;

    strong {
        font-family: var(--default-font);
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 17px;
        color: #3B3B3B;
    }
}

@media (prefers-color-scheme: dark) {
    .no-data-rate-1 {
        color: #F0F0F0;
    }

    .no-data-rate-2 {
        color: #F0F0F0;
    }

    .no-data-rate-3 {
        color: #F0F0F0;
    }

    .data-rate-1 {
        color: #8C8C8C;

        strong {
            color: #F0F0F0;
        }
    }

    .data-rate-2 {
        color: #8C8C8C;

        strong {
            color: #F0F0F0;
        }
    }

    .data-rate-3 {
        color: #8C8C8C;

        strong {
            color: #F0F0F0;
        }
    }

    .data-rate-4 {
        color: #3B3B3B;

        strong {
            color: #3B3B3B;
        }
    }
}
</style>