import { createStore } from 'vuex'

import generalModule from './modules/general.module';
import userModule from './modules/user.module';
import healthModule from './modules/health.module';
import activityModule from './modules/activity.module';

export default createStore({
  modules: {
    general: generalModule,
    user: userModule,
    health: healthModule,
    activity: activityModule,
  },
})