import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';

import App from './App.vue';
import router from './router';
import store from './store/store';

import VueApexCharts from "vue3-apexcharts";
import moment from 'moment';

const app = createApp({
    extends: App,
    beforeCreate() {
        this.$store.commit('general/INITIALISE_GENERAL_STORE'),
        this.$store.commit('user/INITIALISE_USER_STORE'),
        this.$store.commit('health/INITIALISE_HEALTH_STORE'),
        this.$store.commit('activity/INITIALISE_ACTIVITY_STORE')
    }
});

let locale = navigator.language.substring(0, 2);
moment.locale(locale);

function loadLocaleMessages() {
    const locales = require.context('./lang', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    })
    return messages
}

const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: locale,
    fallbackLocale: 'en',
    allowComposition: true,
    messages: loadLocaleMessages()
})

app.use(i18n);
app.use(store);
app.use(router);
app.use(VueApexCharts);

app.config.globalProperties.$filters = {
    roundOneDecimal(value) {
        return Math.round(value * 10) / 10
    },
    roundInt(value) {
        return Math.round(value);
    },
    capitalize(value) {
        return value.charAt(0).toUpperCase() + value.slice(1);
    },
    lowerCase(value) {
        return value.toLowerCase();
    },
    upperCase(value) {
        return value.toUpperCase();
    },
    formatTime(value) {
        if (value) {
            const date = new Date(value);
            const timeDiff = date.getTimezoneOffset() * 60000;
            const adjustedDate = new Date(date.valueOf() + timeDiff);
            let locale = navigator.language.substring(0, 2);
            const _str = adjustedDate.toLocaleString(locale, {
                hour: '2-digit',
                minute: '2-digit',
            }).split(' ').join('');
            
            return _str;
        }
    },
    formatDay(value) {
        if (value) {
            return moment(value).format("LL");
        }
    },
    formatWeek(value) {
        if (value) {
            var weekStart = moment(value).clone().startOf('isoWeek');
            var weekEnd = moment(value).clone().endOf('isoWeek');
            return weekStart.format("ll") + " - " + weekEnd.format("ll");
        }
    },
    formatMonthYear(value) {
        if (value) {
            return moment(value).format('MMMM YYYY');
        }
    }
}

app.mount('#app')
