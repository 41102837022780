<template>
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.5 11.6151C0.5 12.5487 0.743414 13.2617 1.23024 13.7543C1.7228 14.2469 2.43299 14.4931 3.36082 14.4931H6.00687V17.1392C6.00687 18.067 6.25315 18.7743 6.7457 19.2612C7.23826 19.7537 7.95132 20 8.88488 20H12.1237C13.0573 20 13.7703 19.7537 14.2629 19.2612C14.7554 18.7743 15.0017 18.067 15.0017 17.1392V14.4931H17.6392C18.567 14.4931 19.2743 14.2469 19.7612 13.7543C20.2537 13.2617 20.5 12.5487 20.5 11.6151V8.37629C20.5 7.44273 20.2537 6.72967 19.7612 6.23711C19.2743 5.74456 18.567 5.49828 17.6392 5.49828H15.0017V2.86082C15.0017 1.93299 14.7554 1.22566 14.2629 0.738832C13.7703 0.246277 13.0573 0 12.1237 0H8.88488C7.95132 0 7.23826 0.246277 6.7457 0.738832C6.25315 1.22566 6.00687 1.93299 6.00687 2.86082V5.49828H3.36082C2.43299 5.49828 1.7228 5.74456 1.23024 6.23711C0.743414 6.72967 0.5 7.44273 0.5 8.37629V11.6151ZM2.85395 11.1942V8.80584C2.85395 8.50229 2.92554 8.27606 3.06873 8.12715C3.21764 7.97251 3.45246 7.89519 3.7732 7.89519H8.40378V3.2646C8.40378 2.9496 8.47824 2.7205 8.62715 2.57732C8.77606 2.42841 9.00229 2.35395 9.30584 2.35395H11.6942C11.9977 2.35395 12.2239 2.42841 12.3729 2.57732C12.5275 2.7205 12.6048 2.9496 12.6048 3.2646V7.89519H17.2268C17.5475 7.89519 17.7795 7.97251 17.9227 8.12715C18.0716 8.27606 18.146 8.50229 18.146 8.80584V11.1942C18.146 11.4977 18.0716 11.7239 17.9227 11.8729C17.7795 12.0218 17.5475 12.0962 17.2268 12.0962H12.6048V16.7354C12.6048 17.0504 12.5275 17.2795 12.3729 17.4227C12.2239 17.5716 11.9977 17.646 11.6942 17.646H9.30584C9.00229 17.646 8.77606 17.5716 8.62715 17.4227C8.47824 17.2795 8.40378 17.0504 8.40378 16.7354V12.0962H3.7732C3.45246 12.0962 3.21764 12.0218 3.06873 11.8729C2.92554 11.7239 2.85395 11.4977 2.85395 11.1942Z"
            :fill="color" />
    </svg>
</template>

<script>
export default {
    name: "IconHealthCross",

    props: {
        color: {
            type: String,
            requied: true,
        },
    },

    data() {
        return {};
    },
};
</script>
  
<style lang="scss">

</style>