<template>
    <svg width="24px" height="24px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M409.798 551.245c-4.196-18.934-19.682-34.42-38.619-38.617-38.375-8.511-71.514 24.626-63.01 63.005 4.196 18.939 19.677 34.417 38.629 38.615 38.373 8.511 71.505-24.624 63-63.004zm39.99-8.862c14.987 67.63-44.232 126.854-111.854 111.856-34.491-7.641-62.115-35.258-69.755-69.744-14.987-67.629 44.242-126.853 111.866-111.855 34.477 7.641 62.102 35.266 69.743 69.743zM621.693 756.37a10.233 10.233 0 003.747-13.988l-39.116-67.757c-2.825-4.892-9.094-6.572-13.987-3.748l-67.744 39.114c-4.904 2.833-6.585 9.101-3.762 13.991l39.114 67.753c2.831 4.901 9.1 6.579 14 3.752l67.748-39.117zm-47.272 74.592c-24.489 14.13-55.798 5.746-69.946-18.743l-39.116-67.757c-14.136-24.481-5.739-55.794 18.749-69.94l67.753-39.12c24.487-14.129 55.8-5.738 69.935 18.743l39.114 67.753c14.148 24.49 5.755 55.81-18.739 69.945l-67.751 39.118zm173.24-237.158c-1.252 20.821-22.113 32.036-40.165 21.607L600.36 553.558c-18.058-10.433-18.757-34.1-1.357-45.586l117.423-77.528c18.778-12.396 41.013.422 39.662 22.898l-8.428 140.462zM713.363 481.55l-72.685 47.99 67.468 38.951 5.216-86.941zM456.458 285.736l-80.404-158.218c-3.461-6.812 1.489-14.878 9.134-14.878h251.628c7.645 0 12.59 8.061 9.127 14.873l-80.397 158.224c-5.124 10.084-1.103 22.412 8.981 27.536s22.412 1.103 27.536-8.981l80.394-158.218c17.319-34.058-7.427-74.394-45.64-74.394H385.189c-38.208 0-62.956 40.328-45.651 74.392l80.406 158.221c5.124 10.083 17.453 14.104 27.536 8.979s14.104-17.453 8.979-27.536z" :fill="color"/>
        <path
            d="M725.04 909.844c101.8 0 184.32-82.52 184.32-184.32v-43.151c0-197.073-161.327-358.4-358.4-358.4h-79.923c-197.073 0-358.4 161.327-358.4 358.4v43.151c0 101.797 82.526 184.32 184.32 184.32H725.04zm0 40.96H296.957c-124.415 0-225.28-100.862-225.28-225.28v-43.151c0-219.695 179.665-399.36 399.36-399.36h79.923c219.695 0 399.36 179.665 399.36 399.36v43.151c0 124.422-100.858 225.28-225.28 225.28z" :fill="color"/>
    </svg>
</template>

<script>
export default {
    name: "IconOther",

    props: {
        color: {
            type: String,
            requied: true,
        },
    },

    data() {
        return {};
    },
};
</script>
  
<style lang="scss">

</style>