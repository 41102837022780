<template>
  <svg fill="#000000" height="24px" width="24px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" enable-background="new 0 0 64 64"
       xml:space="preserve">
        <path d="M63.0437965,20.4169903c-1.0986023-1.9267006-3.0116997-3.2119007-5.1366997-3.5371017
	c0.7841988-2.0214987,0.6426086-4.3368988-0.4561005-6.2616987c-0.9589005-1.6787004-2.5088005-2.8779001-4.3652-3.3750005
	c-1.8437004-0.4941998-3.7695007-0.2363-5.419899,0.7284999c-3.4131012,1.9921999-4.5879021,6.4189005-2.6191902,9.8672009
	c0.1660881,0.2909985,0.3495903,0.5674,0.5517883,0.8300991l-31.809597,18.575201
	c-0.1220007-0.3017998-0.2656002-0.5956993-0.4287004-0.8809013c-0.9580002-1.6786995-2.5087996-2.8778992-4.3652-3.375
	c-1.8437996-0.4960976-3.7695994-0.2362976-5.4198995,0.7275009c-3.4122,1.9921989-4.5869999,6.4189987-2.6182001,9.8681984
	c1.0996001,1.9267998,3.0127001,3.2100029,5.1366997,3.5361023c-0.7841997,2.0224991-0.6426001,4.3388977,0.4570003,6.2617989
	c0.9581075,1.6786995,2.5078998,2.8768997,4.3642993,3.375c0.6122999,0.1640015,1.2334003,0.2451019,1.8495998,0.2451019
	c1.2422085,0,2.4688005-0.3291016,3.5712996-0.9737015c3.4120998-1.9921989,4.5869083-6.4188995,2.6182003-9.8690987
	c-0.1651001-0.2871017-0.3477001-0.5615005-0.5468998-0.8213005l31.808609-18.5742016
	c0.1210899,0.2987995,0.2626915,0.5897999,0.42379,0.8730011c0.9580002,1.6786995,2.5088005,2.8780003,4.3662109,3.375
	c1.8437881,0.4931984,3.7675896,0.2363987,5.4189987-0.7275009C63.8367958,28.2919903,65.0115967,23.8652897,63.0437965,20.4169903z
  M59.4168968,28.5575886c-1.1856003,0.6905003-2.5674019,0.8789005-3.8936005,0.522501
	c-1.3378983-0.3584003-2.455101-1.2236004-3.1464996-2.4335995c-0.2831993-0.4971008-0.482399-1.0293007-0.5928001-1.580101
	c-0.0634003-0.3164005-0.2762909-0.5830002-0.5702896-0.7157993c-0.2949104-0.1328011-0.6347122-0.1152-0.9140015,0.0487995
	L16.325098,44.2382889c-0.2773933,0.1611023-0.4590006,0.4453011-0.4913006,0.7637024
	c-0.0312004,0.3192978,0.0909081,0.6337967,0.3300991,0.8465996c0.4160004,0.3701973,0.7705002,0.8085976,1.0537014,1.3037987
	c1.4267998,2.5,0.5791073,5.7070007-1.8896008,7.1493988c-1.1864996,0.6924019-2.5712919,0.8759995-3.8955002,0.5233994
	c-1.3368998-0.3583984-2.4540997-1.2235985-3.1454992-2.4354973c-1.0059004-1.7598-0.9062924-3.9746017,0.2538996-5.6416016
	c0.2216997-0.3194008,0.2383003-0.7383003,0.0429001-1.0742989c-0.1806002-0.3095016-0.5107002-0.4970016-0.8642001-0.4970016
	c-0.0303001,0-0.0615001,0.0009995-0.0917997,0.0039024c-1.9872999,0.1893997-3.9307001-0.8320999-4.9345999-2.5889015
	c-1.4266999-2.5-0.5790999-5.7070007,1.8897002-7.1493988c1.1875-0.6934013,2.5712996-0.8789024,3.8944993-0.5225029
	c1.3369007,0.3584023,2.4541006,1.222702,3.1465006,2.4366035c0.2841997,0.4940987,0.4833994,1.027298,0.5928078,1.5838966
	c0.0633917,0.3174019,0.2753916,0.5850029,0.5702915,0.7178001c0.2939005,0.1338005,0.6357088,0.115303,0.915-0.0477982
	l33.9804993-19.8428001c0.2773018-0.1611004,0.4598999-0.4453011,0.4912109-0.7646999
	c0.031189-0.3183002-0.0918083-0.6336994-0.3311081-0.8465996c-0.4198914-0.3731003-0.7763023-0.8125-1.0586014-1.3086014
	c-1.4277-2.499999-0.5800018-5.707099,1.8897095-7.1493988c1.1854897-0.6924,2.5673904-0.8770008,3.8944893-0.5235004
	c1.3369026,0.3584003,2.4541016,1.2236996,3.1465111,2.4356003c1.0048904,1.7616997,0.9052887,3.9755993-0.2549095,5.6415997
	c-0.222599,0.3192997-0.2392006,0.7383003-0.0438995,1.0741997c0.1963005,0.3359013,0.5780983,0.5321999,0.9559975,0.4932003
	c1.9922028-0.1914997,3.9327011,0.8320007,4.9346008,2.5888004C62.7332954,23.9081898,61.8855972,27.1152897,59.4168968,28.5575886z
	" :fill="color"/>
    </svg>
</template>

<script>
export default {
  name: "IconGnawing",

  props: {
    color: {
      type: String,
      requied: true,
    },
  },

  data() {
    return {};
  },
};
</script>

<style lang="scss">

</style>