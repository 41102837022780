<template>
    <div class="graph-container graph-heart-rate">
        <template v-if="this.series[0].data.length && this.getHealthRangeType != 'day'">
            <apexchart type="line" :options="chartOptions" :series="series" :height="'100%'"></apexchart>
        </template>
        <template v-else-if="this.daily_series[0].data.length && this.getHealthRangeType == 'day'">
            <apexchart type="line" :options="chartDailyOptions" :series="daily_series" :height="'100%'"></apexchart>
        </template>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { useCssVar } from '@vueuse/core'
import { ref } from 'vue'

import moment from "moment";

const basicGrid = {
    show: true,
    borderColor: '#D9D9D9',
    strokeDashArray: 3,
    position: 'back',
    xaxis: {
        lines: {
            show: true
        }
    },
    yaxis: {
        lines: {
            show: false
        }
    },
    row: {
        colors: undefined,
        opacity: 0.5
    },
    column: {
        colors: undefined,
        opacity: 0.5
    },
    padding: {
        top: 0,
        right: 0,
        bottom: -5,
        left: 0
    },
};

export default {
    name: "GraphHeartRate",

    data() {
        const defaultFont = useCssVar('--default-font', ref(null));
        let self = this;
        return {
            series: [{
                data: []
            }],
            daily_series: [{
                data: []
            }],
            basicOptions: {
                chart: {
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    },
                    animations: {
                        enabled: false,
                    }
                },
                dataLabels: {
                    enabled: false,
                },
                theme: {
                    monochrome: {
                        enabled: true,
                        color: '#FF858C',
                    }
                },
                markers: {
                    size: 3,
                    colors: '#FEF1F1',
                    strokeColors: '#FF1449',
                    strokeWidth: 2,
                    strokeOpacity: 1,
                    shape: "circle",
                    showNullDataPoints: false,
                },
                xaxis: {
                    type: 'datetime',
                    tickAmount: 5,
                    forceNiceScale: true,
                    tickPlacement: 'between',
                    labels: {
                        style: {
                            colors: '#8C8C8C',
                            fontSize: '10px',
                            fontFamily: defaultFont,
                            fontWeight: 400,
                            cssClass: 'apexcharts-xaxis-label',
                        },
                    },
                    axisBorder: {
                        show: false,
                        offsetY: -15,
                    },
                },
                yaxis: {
                    tickAmount: 3,
                    min: 0,
                    max: 80,
                    formatter: function (val) {
                        return val.toFixed(0);
                    },
                },
                tooltip: {
                    enabled: true,
                    x: {
                        show: false,
                    },
                    y: {
                        title: {
                            formatter: function () {
                                return ''
                            },
                        },
                        formatter: function (val) {
                            if (val == null) {
                                return val
                            }
                            return val.toFixed(0) + ' ' + self.$t('health.heart_rate.unit').toUpperCase()
                        }
                    },
                    marker: {
                        show: false,
                    }
                },
                stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    width: 3,
                },
                grid: { ...basicGrid },
            },
            chartOptions: {
                theme: {
                    monochrome: {
                        enabled: true,
                        color: '#FF858C',
                    }
                },
                chart: {
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    },
                    animations: {
                        enabled: false,
                    }
                },
                dataLabels: {
                    enabled: false,
                },
                markers: {
                    size: 3,
                    colors: '#FEF1F1',
                    strokeColors: '#FF1449',
                    strokeWidth: 2,
                    strokeOpacity: 1,
                    shape: "circle",
                    showNullDataPoints: false,
                },
                stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    width: 3,
                },
                annotations: {
                    position: 'back',
                    yaxis: [
                        {
                            y: 0,
                            borderColor: "#BFBFBF",
                            borderWidth: 0,
                            opacity: 0.5,
                            strokeDashArray: 0,
                        }
                    ],
                },
                xaxis: {
                    type: 'category',
                    tickAmount: 10,
                    tickPlacement: 'between',
                    forceNiceScale: false,
                    labels: {
                        style: {
                            colors: '#8C8C8C',
                            fontSize: '10px',
                            fontFamily: defaultFont,
                            fontWeight: 400,
                            cssClass: 'apexcharts-xaxis-label',
                        },
                        formatter: function (value) {
                            return moment(value).format('ddd')
                        },
                    },
                    categories: this.enumerateDaysBetweenDates(moment().clone().startOf('isoWeek'), moment().clone().endOf('isoWeek')),
                    axisBorder: {
                        show: false,
                        offsetY: -15,
                    },
                },
                yaxis: {
                    tickAmount: 3,
                    min: 0,
                    max: 80,
                    forceNiceScale: true,
                    opposite: true,
                    labels: {
                        style: {
                            colors: '#1B1C1E',
                            fontSize: '10px',
                            fontFamily: defaultFont,
                            fontWeight: 400,
                            cssClass: 'apexcharts-yaxis-label',
                        },
                        formatter: function (val) {
                            return val.toFixed(0);
                        },
                        offsetX: -5,
                    },
                }
            },
            chartDailyOptions: {
            }
        };
    },

    methods: {
        ...mapActions("health", ["fetchHealth"]),
        arrayMaximum(array) {
            if (array.length == 0) {
                return 80;
            }
            var flatArray = []
            let index = 0;
            array[0].data.forEach(elem => {
                if (this.getHealthRangeType == 'day') {
                    let sum = (elem[1] + array[1].data[index][1]) ? elem[1] + array[1].data[index][1] : 0
                    flatArray.push(sum)
                    index += 1
                } else {
                    let sum = (elem + array[1].data[index]) ? elem + array[1].data[index] : 0
                    flatArray.push(sum)
                    index += 1
                }
            });
            if (Array.isArray(array[0].data) && array[0].data.length) {
                return Math.max(...flatArray) ? Math.ceil(Math.max(...flatArray) / 10) * 10 : 30
            } else {
                return 30;
            }
        },
        enumerateHours(startHour, endHour) {
            var hours = [];

            startHour = moment(startHour).clone().add(1, 'hours').toDate().valueOf();

            while (startHour <= endHour) {
                hours.push(startHour);
                startHour = moment(startHour).clone().add(1, 'hours').toDate().valueOf();
            }
            return hours;
        },
        enumerateDaysBetweenDates(startDate, endDate) {
            var dates = [];
            while (startDate.diff(endDate) <= 0) {
                dates.push(startDate.clone().toDate().valueOf());
                startDate = startDate.clone().add(1, 'days');
            }
            return dates;
        },

        async changevalue(startDate, endDate) {
            let data = { start: startDate, end: endDate, type: 'HR', entity_id: localStorage.getItem('entityId') };
            if (this.getHealthRangeType == 'day') {
                data.history = true;
            }
            await this.fetchHealth(data)
            if (this.getHealthRangeType == 'day') {
                this.daily_series = [{
                    data: [...this.getCurrentHealth]
                }];
            } else {
                this.series = [{
                    data: [...this.getCurrentHealth]
                }];
            }
        },

        async updateTheme() {
            const defaultFont = useCssVar('--default-font', ref(null));
            if (this.getCurrentDate) {
                if (this.getHealthRangeType == 'day') {

                    let dateStart = moment(this.getCurrentDate).clone().startOf('day');
                    let today = dateStart.format('YYYY-MM-DD');

                    await this.changevalue(today, today);

                    this.chartDailyOptions = {
                        ...this.basicOptions,
                        annotations: {
                            position: 'back',
                            yaxis: [
                                {
                                    y: this.heartRateAverage,
                                    borderColor: "#BFBFBF",
                                    borderWidth: this.heartRateAverage ? 2 : 0,
                                    opacity: 0.5,
                                    strokeDashArray: 0,
                                }
                            ],
                        },
                        xaxis: {
                            type: 'datetime',
                            tickAmount: 6,
                            forceNiceScale: true,
                            min: dateStart.toDate().valueOf(),
                            max: moment(this.getCurrentDate).clone().endOf('day').toDate().valueOf(),
                            labels: {
                                style: {
                                    colors: '#8C8C8C',
                                    fontSize: '10px',
                                    fontFamily: defaultFont,
                                    fontWeight: 400,
                                    cssClass: 'apexcharts-xaxis-label',
                                },
                                formatter: function (value) {
                                    let _val = Math.round(value / 10) * 10;
                                    return moment(_val).format("HH:mm");
                                },
                                offsetX: 10,
                            },
                            axisBorder: {
                                show: false,
                                offsetY: -15
                            },
                        },
                        yaxis: {
                            tickAmount: 2,
                            opposite: true,
                            min: this.heartRateMin && this.heartRateMin > 30 ? 20 : 0,
                            max: this.heartRateMax ? (Math.round(this.heartRateMax / 10) * 10) + 10 : 80,
                            labels: {
                                style: {
                                    colors: '#1B1C1E',
                                    fontSize: '10px',
                                    fontFamily: defaultFont,
                                    fontWeight: 400,
                                    cssClass: 'apexcharts-yaxis-label',
                                },
                                formatter: function (val) {
                                    return val.toFixed(0);
                                },
                                offsetX: -5,
                            },
                        }
                    };
                } else if (this.getHealthRangeType == 'week') {
                    await this.changevalue(moment(this.getCurrentDate).clone().startOf('isoWeek').format('YYYY-MM-DD'), moment(this.getCurrentDate).clone().endOf('isoWeek').format('YYYY-MM-DD'));

                    this.chartOptions = {
                        ...this.basicOptions,
                        annotations: {
                            position: 'back',
                            yaxis: [
                                {
                                    y: this.heartRateAverage,
                                    borderColor: "#BFBFBF",
                                    borderWidth: this.heartRateAverage ? 2 : 0,
                                    opacity: 0.5,
                                    strokeDashArray: 0,
                                }
                            ],
                        },
                        xaxis: {
                            type: 'category',
                            tickAmount: 5,
                            tickPlacement: 'between',
                            forceNiceScale: true,
                            labels: {
                                style: {
                                    colors: '#434343',
                                    fontSize: '10px',
                                    fontFamily: defaultFont,
                                    fontWeight: 400,
                                },
                                formatter: function (value) {
                                    return moment(value).format('ddd')
                                },
                            },
                            categories: this.enumerateDaysBetweenDates(moment(this.getCurrentDate).clone().startOf('isoWeek'), moment(this.getCurrentDate).clone().endOf('isoWeek')),
                            axisBorder: {
                                show: false,
                                offsetY: -15,
                            },
                        },
                        yaxis: {
                            tickAmount: 3,
                            min: this.heartRateMin && this.heartRateMin > 30 ? 20 : 0,
                            max: this.heartRateMax ? (Math.round(this.heartRateMax / 10) * 10) + 10 : 80,
                            forceNiceScale: true,
                            opposite: true,
                            labels: {
                                style: {
                                    colors: '#1B1C1E',
                                    fontSize: '10px',
                                    fontFamily: defaultFont,
                                    fontWeight: 400,
                                    cssClass: 'apexcharts-yaxis-label',
                                },
                                formatter: function (val) {
                                    return val.toFixed(0);
                                },
                                offsetX: -5,
                            },
                        },
                    }
                } else if (this.getHealthRangeType == 'month') {
                    await this.changevalue(moment(this.getCurrentDate).clone().startOf('month').format('YYYY-MM-DD'), moment(this.getCurrentDate).clone().endOf('month').format('YYYY-MM-DD'))

                    this.chartOptions = {
                        ...this.basicOptions,
                        annotations: {
                            position: 'back',
                            yaxis: [
                                {
                                    y: this.heartRateAverage,
                                    borderColor: "#BFBFBF",
                                    borderWidth: this.heartRateAverage ? 2 : 0,
                                    opacity: 0.5,
                                    strokeDashArray: 0,
                                }
                            ],
                        },
                        xaxis: {
                            type: 'datetime',
                            tickAmount: 5,
                            tickPlacement: 'between',
                            forceNiceScale: true,
                            labels: {
                                style: {
                                    colors: '#434343',
                                    fontSize: '10px',
                                    fontFamily: defaultFont,
                                    fontWeight: 400,
                                },
                                formatter: function (value) {
                                    return moment(value).format('D')
                                },
                            },
                            categories: this.enumerateDaysBetweenDates(moment(this.getCurrentDate).clone().startOf('month'), moment(this.getCurrentDate).clone().endOf('month')),
                            axisBorder: {
                                show: false,
                                offsetY: -15,
                            },
                        },
                        yaxis: {
                            tickAmount: 3,
                            min: this.heartRateMin && this.heartRateMin > 30 ? 20 : 0,
                            max: this.heartRateMax ? (Math.round(this.heartRateMax / 10) * 10) + 10 : 80,
                            forceNiceScale: true,
                            opposite: true,
                            labels: {
                                style: {
                                    colors: '#1B1C1E',
                                    fontSize: '10px',
                                    fontFamily: defaultFont,
                                    fontWeight: 400,
                                    cssClass: 'apexcharts-yaxis-label',
                                },
                                formatter: function (val) {
                                    return val.toFixed(0);
                                },
                                offsetX: -5,
                            },
                        },
                        grid: {
                            ...basicGrid,
                            padding: {
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0
                            },
                        }
                    }
                }
            }
        }
    },

    computed: {
        ...mapGetters("general", ["getCurrentDate"]),
        ...mapGetters("health", ["getHealthRangeType", "getCurrentHealth", "getCurrentHealthAverage"]),

        dataHearthRate() {
            if (this.getHealthRangeType == "day") {
                return this.getCurrentHealth.filter(v => v != null).map(v => v[1]);
            }
            return this.getCurrentHealth.filter(v => v != null)
        },
        heartRateAverage() {
            if (this.getHealthRangeType == "day" && this.getCurrentHealthAverage) {
                return Math.round(this.getCurrentHealthAverage,0);
            }

            let sum = this.dataHearthRate.reduce((a, b) => a + b, 0);
            let avg = Math.round((sum / this.dataHearthRate.filter(v => v != null).length) || 0);
            return avg;
        },
        heartRateMin() {
            return Math.round(this.dataHearthRate.length ? Math.min.apply(Math, this.dataHearthRate) : 0);
        },
        heartRateMax() {
            return this.dataHearthRate.filter(v => v != null).length ? Math.max.apply(Math, this.dataHearthRate.filter(v => v != null)) : 0;
        }
    },

    watch: {
        getHealthRangeType() {
            this.updateTheme()
        },
        getCurrentDate() {
            this.updateTheme()
        }
    },
    created() {
        this.chartDailyOptions = { ...this.basicOptions };
    },
    mounted() {
        this.updateTheme();
    }
}
</script>

<style lang="scss" scoped>
.graph-container {
    margin: -1em -0em -1em 0.5em;
    width: 100%;
    aspect-ratio: 16/10;
}
</style>