<template>
  <span v-if="valueLevel == 'normal' && !isAfib" class="normal-tag">
    <img src="@/assets/images/collar/Check-circle-24@3x.png " class="badge-tag"/>
    {{ $filters.capitalize($t('global.normal')) }}
  </span>
  <span v-if="valueLevel == 'high' && !isAfib" class="high-tag">
    <img src="@/assets/images/collar/Warning-Round-Full-16@3x.png" class="badge-tag"/>
    {{ $filters.capitalize($t('global.high')) }}
  </span>
  <span v-if="valueLevel == 'low' && !isAfib" class="low-tag">
    <img src="@/assets/images/collar/Warning-Round-Full-16@3x.png" class="badge-tag"/>
    {{ $filters.capitalize($t('global.low')) }}
  </span>
  <span v-if="isAfib" class="afib-tag">
    <div v-if="valueLevel=='low'" class="normal-tag">
      <img src="@/assets/images/collar/Check-circle-24@3x.png " class="badge-tag"/>
      {{ $filters.capitalize($t('health.summary.afib_not_detected')) }}
    </div>
    <div v-else-if="valueLevel=='medium'" class="low-tag">
      <img src="@/assets/images/collar/Warning-Round-Full-16@3x.png" class="badge-tag"/>
      {{ $filters.capitalize($t('health.summary.afib_low_risk')) }}
    </div>
    <div v-else-if="valueLevel=='high'" class="low-tag">
      <img src="@/assets/images/collar/Warning-Round-Full-16@3x.png" class="badge-tag"/>
      {{ $filters.capitalize($t('health.summary.afib_high_risk')) }}
    </div>
    <div v-else class="low-tag">
    </div>
  </span>
</template>

<script>

export default {
  name: 'HealthRateFormater',
  
  components: {
  },
  
  props: {
    
    type: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: false,
    },
    afibValue: {
      type: String,
      required: false
    }
  },
  
  computed: {
    heartRateType() {
      return (this.$props.type == 'heart_rate' );
    },
    respiratoryRateType() {
      return (this.$props.type == 'respiratory_rate' );
    },
    isAfib() {
      return (this.$props.type == 'is_afib')
    },
    valueLevel() {
      let value = this.$props.value;
      let rr_type = this.respiratoryRateType;
      if (rr_type) {
        if (value > 30) {
          return 'high'
        } else if (value <= 7) {
          return 'low'
        } else {
          return 'normal'
        }
      }
      if (this.heartRateType) {
        if (value > 100) {
          return 'high'
        } else if (value <= 30) {
          return 'low'
        } else {
          return 'normal'
        }
      }

      let afibValue = this.$props.afibValue
      if (this.isAfib) {
        return afibValue;
      }
      return 'normal';
    }
  }
}
</script>

<style lang="scss" scoped>
.badge-tag {
  height: 12px;
  width: 12px;
}

.normal-tag {
  font-family: var(--default-font);
  font-weight: 700;
  color: #34C759;
}

.low-tag, .high-tag {
  font-family: var(--default-font);
  font-weight: 700;
  color: #F79D00;
}

.afib-tag {
  font-size: 12px;
}
</style>