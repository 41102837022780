<template>
    <div class="heartRate-layout">
        <section class="health-section">
            <h3>
                {{ $filters.capitalize($t('activity.' + $route.params.activityType + '.information_title')) }}
            </h3>
            <base-card>
                <GraphDateSwitcher>
                    <card-content :iconWrapper="{ iconType: 'icon-' + iconSuffix($route.params.activityType) }">
                        <template v-slot:header>
                            {{ $filters.capitalize($t('activity.' + $route.params.activityType + '.title')) }}
                        </template>
                        <template v-slot:content>
                            <h5>
                                {{
                                    $filters.upperCase($t(getActivityRangeType == 'day' ? 'global.total' :
                                        'global.average'))
                                }}
                            </h5>
                            <data-formater :loading="getLoadingActivity" :type="$route.params.activityType" :size="1"
                                :duration="getActivityRangeType == 'day' ? activityDurationTotal : activityDurationAverage"
                                :episodes="0" />
                            <graph-activity />
                        </template>
                    </card-content>
                </GraphDateSwitcher>
            </base-card>
        </section>
        <section class="health-section">
            <!-- <h3>
                {{ $filters.capitalize($t('activity.' + $route.params.activityType + '.about_title')) }}
            </h3> -->
            <base-card v-if="isBeta()">
                <i18n-t v-if="isEntityCat()" tag="p" keypath="global.about_card_beta_cat">
                    <template #br>
                        <br />
                    </template>
                </i18n-t>
                <i18n-t v-else tag="p" keypath="global.about_card_beta">
                    <template #br>
                        <br />
                    </template>
                </i18n-t>
                <!-- <p v-html="$filters.capitalize($t('global.about_card_beta'))">
                </p> -->
            </base-card>
        </section>
        <!-- <section class="health-section">
            <h3>
                {{ $filters.capitalize($t('activity.' + $route.params.activityType + '.blog_links_title')) }}
            </h3>
            <blog-card :blogImageSrc="'dog-icecream.png'"
                :blogName="'activity.' + $route.params.activityType + '.blog_name_mistake'" />
            <blog-card :blogImageSrc="'dog-prank.png'"
                :blogName="'activity.' + $route.params.activityType + '.blog_name_balanced'" />
        </section> -->
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { isBetaActivity } from '@/views/activity/SharedActivity';

import LayoutDefault from "@/layouts/LayoutDefault.vue";
import BaseCard from '@/components/ui/BaseCard.vue';
import CardContent from '@/components/ui/CardContent.vue';
import GraphDateSwitcher from "@/components/graphics/GraphDateSwitcher.vue";
import GraphActivity from '@/components/graphics/GraphActivity.vue';
import DataFormater from '@/components/ui/DataFormater.vue';
// import BlogCard from '@/components/ui/BlogCard.vue';

export default {
    name: "ActivityDetail",

    components: {
        BaseCard,
        CardContent,
        GraphDateSwitcher,
        GraphActivity,
        DataFormater,
        // BlogCard
    },

    methods: {
        ...mapActions("general", ["setDayPicker"]),

        arrayTotal(array) {
            if (Array.isArray(array)) {
                return array.reduce((a, b) => a + b, 0);
            } else {
                return 0;
            }
        },

        arrayAverage(array) {
            if (Array.isArray(array) && array.length) {
                return Math.ceil(this.arrayTotal(array) / (array.filter(v => v != null).length || 1));
            } else {
                return 0;
            }
        },

        isEntityCat() {
            return this.getEntityType == "cat"
        },

        iconSuffix(type) {
            if (this.isEntityCat()) {
                if (type == "walking") {
                    return "walking-cat"
                }
                if (type == "running") {
                    return "running-cat"
                }
                if (type == "trotting") {
                    return "trotting-cat"
                }
                if (type == "resting") {
                    return "resting-cat"
                }
                if (type == "petting") {
                    return "petting-cat"
                }
            }
            return type
        },

        isBeta() {
            return isBetaActivity(this.$route.params.activityType)
        }
    },

    computed: {
        ...mapGetters("activity", ["getActivityRangeType", "getLoadingActivity", "getCurrentActivity", "getCurrentEpisodes"]),
        ...mapGetters("user", ["getEntityType"]),

        activityDurationTotal() {
            return this.arrayTotal(this.getCurrentActivity);
        },

        activityDurationAverage() {
            return this.arrayAverage(this.getCurrentActivity);
        },

        activityEpisodesAverage() {
            return this.arrayAverage(this.getCurrentEpisodes);
        }
    },

    created() {
        this.setDayPicker(true);
        this.$emit("update:layout", LayoutDefault);
    },
};
</script>
  
<style lang="scss" scoped>
.heartRate-layout {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.extrem-card {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    &-title {
        font-family: var(--default-font);
        font-weight: 700;
        font-size: var(--default-font-size-small);
        line-height: 20px;
        color: #3B3B3B;
    }

    &-value {
        font-family: var(--default-font);
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: #3B3B3B;
    }
}

.activity-informations {
    display: flex;
    flex-direction: column;
    gap: 1em;
}
</style>