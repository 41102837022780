<template>
  <component :is="cardLink ? 'router-link' : 'div'" :to="cardLink"
    :class="{ 'big-card': cardSize == 2, 'base-card': !noCard, 'background-purple': backgroundColor == 'purple' }">
    <slot />
    <icon-chevron v-if="cardLink" class="card-chevron"
      :class="{ 'card-chevron-hs': (cardLink == '/heart-signature' || cardLink == '/exercise-level') }"
      :iconColor="'black'" />
  </component>
</template>

<script>
import IconChevron from '../svg/IconChevron.vue';

export default {
  name: "BaseCard",

  components: {
    IconChevron,
  },

  props: {
    cardSize: {
      type: Number,
      required: false,
      default: 2,
    },
    noCard: {
      type: Boolean,
      required: false,
      default: false,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: 'white',
    },
    cardLink: {
      type: String,
      required: false,
    }
  },

  data() {
    return {};
  },
};
</script>

<style lang="scss">
.base-card {
  overflow: hidden;
  background-color: #ffffff;
  position: relative;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1em;
  padding: 1em;
  border-radius: 1em;
  box-shadow: 0px 0px 7px rgba(154, 154, 154, 0.08);
  -webkit-user-drag: none;
}

.background-purple {
  background-color: #E0E0FA;
}

.big-card {
  grid-column: span 2;
}

.card-chevron {
  position: absolute;
  right: 1em;
}

.card-chevron-hs {
  top: 1.75em;
}

@media (prefers-color-scheme: dark) {
  .base-card {
    background-color: #1F2038;
    box-shadow: 0px 0px 7px rgba(154, 154, 154, 0.08);
  }

  .background-purple {
    background-color: #8977FE;
  }
}</style>