<template>
  <div class="layout-dashboard2" :class="{ 'frozen-container': getPageFrozenState }">
    <div class="layout-content">
      <slot />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LayoutSummaryNoHeader",

  components: {
  },

  computed: {
    ...mapGetters("general", ["getPageFrozenState"]),
  }
};
</script>

<style lang="scss" scoped>
.layout-dashboard2 {
  /* display: flex;
  flex-direction: column; */
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

/* .layout-content {
  padding: 1.5em;
  background-color: #F2F8FD;
  user-select: none;
} */

@media (prefers-color-scheme: dark) {
  /* .layout-content {
    background-color: #010213
  } */
}
</style>