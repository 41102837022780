<template>
    <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.84412 18.6051H14.1559V11.9723C14.1559 11.7567 14.0882 11.5843 13.9527 11.455C13.8233 11.3195 13.6509 11.2517 13.4353 11.2517H9.57391C9.35836 11.2517 9.18284 11.3195 9.04735 11.455C8.91186 11.5843 8.84412 11.7567 8.84412 11.9723V18.6051ZM5.23211 20H17.731C18.4638 20 19.0335 19.7968 19.44 19.3903C19.8526 18.99 20.0589 18.4357 20.0589 17.7275V7.47344L18.0081 6.06005V17.0439C18.0081 17.3333 17.9311 17.555 17.7771 17.709C17.6293 17.863 17.4138 17.94 17.1305 17.94H5.83257C5.54927 17.94 5.33064 17.863 5.17668 17.709C5.02887 17.555 4.95497 17.3333 4.95497 17.0439V6.06928L2.89492 7.47344V17.7275C2.89492 18.4357 3.10124 18.99 3.51386 19.3903C3.92649 19.7968 4.49924 20 5.23211 20ZM0.0404205 9.45035C0.0404205 9.72748 0.135879 9.96459 0.326795 10.1617C0.517711 10.3526 0.773292 10.448 1.09354 10.448C1.25366 10.448 1.40455 10.4142 1.54619 10.3464C1.68784 10.2725 1.82025 10.1863 1.94342 10.0878L11.0704 2.42956C11.2121 2.30639 11.3599 2.2448 11.5139 2.2448C11.6678 2.2448 11.8156 2.30639 11.9573 2.42956L21.0658 10.0878C21.189 10.1863 21.3214 10.2725 21.4631 10.3464C21.6047 10.4142 21.7556 10.448 21.9157 10.448C22.2113 10.448 22.4577 10.3618 22.6547 10.1894C22.858 10.0108 22.9596 9.77059 22.9596 9.46882C22.9596 9.1301 22.8241 8.84988 22.5531 8.62818L12.9457 0.545035C12.5085 0.181678 12.0312 0 11.5139 0C10.9965 0 10.5193 0.181678 10.082 0.545035L0.456125 8.62818C0.178989 8.85604 0.0404205 9.1301 0.0404205 9.45035ZM17.2321 4.74827L20.0589 7.1224V2.52194C20.0589 2.30639 19.9942 2.13703 19.8649 2.01386C19.7417 1.89069 19.5754 1.8291 19.3661 1.8291H17.9249C17.7156 1.8291 17.5462 1.89069 17.4169 2.01386C17.2937 2.13703 17.2321 2.30639 17.2321 2.52194V4.74827Z"
            :fill="color" />
    </svg>
</template>

<script>
export default {
    name: "IconHome",

    props: {
        color: {
            type: String,
            requied: true,
        },
    },

    data() {
        return {};
    },
};
</script>
  
<style lang="scss">

</style>