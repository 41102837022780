<template>
    <div class="heartRate-layout">
        <section class="health-section">
            <h3>
                {{ $filters.capitalize($t('activity.exercise_level.information_title')) }}
            </h3>
            <base-card>
                <GraphDateSwitcher>
                    <card-content :iconWrapper="{ iconType: 'icon-lightning' }">
                        <template v-slot:header>
                            {{ $filters.capitalize($t('activity.exercise_level.title')) }}
                        </template>
                        <template v-slot:content>
                            <h5>
                                {{
                                    $filters.upperCase($t(getActivityRangeType == 'day' ? 'global.total' :
                                        'global.average'))
                                }}
                            </h5>
                            <data-formater :loading="getLoadingActivity" :type="'exercise'" :size="1"
                                :duration="getActivityRangeType == 'day' ? (exerciseModerate + exerciseHeavy) / 2 : exerciseAverage"
                                :episodes="0" />
                            <graph-exercise-level />
                        </template>
                    </card-content>
                </GraphDateSwitcher>
            </base-card>
            <transition-expand>
                <div v-if="getActivityRangeType != 'day'" style="display: grid; grid-template-columns: 1fr 1fr; gap: 0.5em">
                    <base-card class="extrem-card" :cardSize="1">
                        <div class="extrem-card-title">
                            {{ $filters.capitalize($t('global.moderate')) }}
                        </div>
                        <div v-if="exerciseModerate" class="extrem-card-content">
                            <div class="extrem-card-value">
                                <data-formater :type="'exercise'" :size="3" :duration="exerciseModerate" :episodes="0" />
                            </div>
                        </div>
                        <div v-else class="extrem-card-value">
                            {{ $filters.capitalize($t('global.no_data')) }}
                        </div>
                    </base-card>
                    <base-card class="extrem-card" :cardSize="1">
                        <div class="extrem-card-title">
                            {{ $filters.capitalize($t('global.heavy')) }}
                        </div>
                        <div v-if="exerciseHeavy" class="extrem-card-content">
                            <div class="extrem-card-value">
                                <data-formater :type="'exercise'" :size="3" :duration="exerciseHeavy" :episodes="0" />
                            </div>
                        </div>
                        <div v-else class="extrem-card-value">
                            {{ $filters.capitalize($t('global.no_data')) }}
                        </div>
                    </base-card>
                </div>
            </transition-expand>
        </section>
        <section class="health-section">
            <h3>
                {{ $filters.capitalize($t('activity.exercise_level.about_title')) }}
            </h3>
            <base-card>
                <i18n-t v-if="isEntityCat()" tag="p" keypath="activity.exercise_level.about_card_content_cat">
                    <template #br>
                        <br />
                    </template>
                </i18n-t>
                <i18n-t v-else tag="p" keypath="activity.exercise_level.about_card_content">
                    <template #br>
                        <br />
                    </template>
                </i18n-t>
                <!-- <p v-html="$filters.capitalize($t('activity.exercise_level.about_card_content'))"></p> -->
            </base-card>
        </section>
        <!-- <section class="health-section">
            <h3>
                {{ $filters.capitalize($t('activity.exercise_level.blog_links_title')) }}
            </h3>
            <blog-card :blogImageSrc="'dog-swim.png'" :blogName="'activity.exercise_level.blog_name_adapted'" />
            <blog-card :blogImageSrc="'dog-ball.png'" :blogName="'activity.exercise_level.blog_name_risk'" />
        </section> -->
    </div>
</template>
  
<script>
import { mapGetters, mapActions } from "vuex";

import LayoutDefault from "@/layouts/LayoutDefault.vue";
import TransitionExpand from "@/components/ui/TransitionExpand.vue";
import DataFormater from "@/components/ui/DataFormater.vue";
import BaseCard from '@/components/ui/BaseCard.vue';
import CardContent from '@/components/ui/CardContent.vue';
import GraphDateSwitcher from "@/components/graphics/GraphDateSwitcher.vue";
import GraphExerciseLevel from '@/components/graphics/GraphExerciseLevel.vue';
// import BlogCard from "@/components/ui/BlogCard.vue";

export default {
    name: "ExerciseLevel",

    components: {
        TransitionExpand,
        DataFormater,
        BaseCard,
        CardContent,
        GraphDateSwitcher,
        GraphExerciseLevel,
        // BlogCard
    },

    methods: {
        ...mapActions("general", ["setDayPicker"]),

        arrayTotal(array) {
            let sum = 0;
            if (Array.isArray(array)) {
                if (this.getActivityRangeType == 'day') {
                    array.forEach(elem => {
                        sum += elem[1]
                    })
                    return sum
                } else {
                    array.forEach(elem => {
                        sum += elem
                    })
                    return sum
                }
            } else {
                return 0;
            }
        },

        arrayAverage(array) {
            if (Array.isArray(array) && array.length) {
                return this.arrayTotal(array) / (array.filter(v => (v != null && v != 0)).length || 1);
            } else {
                return 0;
            }
        },

        isEntityCat() {
            return this.getEntityType == "cat"
        },
    },

    computed: {
        ...mapGetters("activity", ["getActivityRangeType", "getLoadingActivity", "getCurrentExerciseLevel"]),
        ...mapGetters("user", ["getEntityType"]),

        exerciseModerate() {
            return this.arrayTotal(this.getCurrentExerciseLevel[0].data)
        },

        exerciseHeavy() {
            return this.arrayTotal(this.getCurrentExerciseLevel[1].data)
        },

        exerciseAverage() {
            return this.arrayAverage(this.getCurrentExerciseLevel[0].data) + this.arrayAverage(this.getCurrentExerciseLevel[1].data)
        }
    },

    created() {
        this.setDayPicker(true);
        this.$emit("update:layout", LayoutDefault);
    },
};
</script>
  
<style lang="scss" scoped>
.heartRate-layout {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.no-data-rate .heart {
    color: #6DDFC0;
}

.data-rate {
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    color: #8C8C8C;
    font-family: var(--default-font);

    strong {
        font-weight: 700;
        font-size: 32px;
        line-height: 45px;
    }

    strong,
    .heart {
        color: #1BB692;
    }
}

.extrem-card {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    &-title {
        font-family: var(--default-font);
        font-weight: 700;
        font-size: var(--default-font-size-small);
        line-height: 20px;
        color: #3B3B3B;
    }

    &-content {
        display: flex;
        gap: 0.5em;
        align-items: baseline;
        font-family: var(--default-font);
        font-weight: 600;
        font-size: 12px;
        line-height: 17px;
        color: #8C8C8C;
    }

    &-value {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: #3B3B3B;
    }
}

@media (prefers-color-scheme: dark) {
    .data-rate {
        color: #8C8C8C;

        strong {
            color: #F0F0F0;
        }
    }

    .extrem-card {

        &-title {
            color: #F0F0F0;
        }

        &-content {
            color: #8C8C8C;
        }

        &-value {
            color: #F0F0F0;
        }
    }
}
</style>