<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M19.452,12.837C19.452,12.837 19.917,12.81 20.149,12.814C20.446,12.819 20.643,12.882 20.891,12.871C21.581,12.838 21.86,12.173 21.86,12.173C21.86,12.173 22.24,12.013 22.25,11.759C22.26,11.483 22.056,11.297 22.007,11C21.954,10.675 22.134,10.13 22.129,9.692C22.121,8.949 21.654,8.689 21.56,8.045C21.486,7.54 22.193,7.131 21.907,6.964C21.355,6.642 20.827,7.499 20.091,7.439C18.634,7.32 16.953,8.517 15.531,8.646C14.189,8.767 13.411,7.079 10.872,7.083C7.994,7.088 3.646,9.12 2.018,12.128C1.672,12.769 1.732,14.169 1.81,14.387C2.047,15.05 3.541,16.216 4.675,16.668C5.814,17.121 8.251,17.092 8.643,17.107C9.31,17.132 11.572,16.8 11.977,16.721C12.689,16.581 18.603,15.608 18.91,15.3C19.277,14.932 19.187,14.039 19.24,13.654C19.259,13.52 19.452,12.837 19.452,12.837Z"
            :fill="color" />
    </svg>
</template>
  
<script>
export default {
    name: "IconRestingCat",

    props: {
        color: {
            type: String,
            requied: true,
        },
    },

    data() {
        return {};
    },
};
</script>
  
<style lang="scss"></style>