<template>
  <div class="main_error">
    <h1 class="h1_error">{{$t('error')}}</h1>
    <li class="link-back">
      <router-link class="nav-link" to="/">{{$t('go_back')}}</router-link>
    </li>
    <!-- <img class="error-invoxia" src="../assets/invoxia.jpg" alt="invoxia" /> -->
  </div>
</template>

<script>
import "@/assets/scss/style.scss";

export default {
  name: "ErrorPage"
};
</script>