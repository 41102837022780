<template>
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.6411 10.8537C12.4005 10.1624 11.4914 9.03543 11.1031 7.67173C10.8285 6.72471 10.8379 5.75875 11.0747 4.85908C9.80568 4.90643 8.75449 5.38941 8.07263 6.24173C7.3529 5.33259 6.25436 4.84961 4.90012 4.84961C2.17271 4.84961 0.117676 7.01828 0.117676 9.88775C0.117676 12.9466 2.63675 14.9827 4.66337 16.6211L4.81489 16.7442C5.02323 16.9146 5.22211 17.0756 5.41151 17.2366C5.9229 17.6628 6.47217 18.1268 7.04985 18.6193C7.26767 18.7992 7.52336 18.9318 7.80747 18.9791C7.8927 18.9981 7.9874 19.0075 8.06316 19.0075H8.0821C8.25257 19.0075 8.35674 18.9886 8.49879 18.9507C8.73555 18.8844 8.95336 18.7708 9.12383 18.6193C9.69204 18.1268 10.2413 17.6628 10.7527 17.2366C10.9895 17.0377 11.2451 16.8294 11.5008 16.6211C13.0445 15.3615 14.7965 13.9315 15.6109 11.9807C15.6204 11.9617 15.6298 11.9428 15.6393 11.9144C15.6961 11.7723 15.7245 11.6303 15.7624 11.4882C15.0238 11.4219 14.2945 11.2136 13.6411 10.8537Z"
            :fill="color" />
        <path
            d="M20.063 8.75028C19.9778 8.57035 19.7884 8.45671 19.5895 8.48512C17.9417 8.65558 16.417 7.62333 15.9625 6.03234C15.4984 4.43188 16.256 2.73672 17.7523 2.00751C17.9323 1.92228 18.0364 1.73288 18.0175 1.52453C17.9985 1.32566 17.847 1.16467 17.6576 1.11731C16.8621 0.927911 16.0193 0.956321 15.2238 1.18361C12.6574 1.92228 11.1705 4.61181 11.9092 7.1877C12.2691 8.4283 13.093 9.46055 14.2199 10.0856C14.9491 10.4928 15.7541 10.6917 16.5591 10.6917C17.0136 10.6917 17.4587 10.6254 17.9038 10.5023C18.6804 10.275 19.4001 9.8583 19.9778 9.29009C20.1199 9.14803 20.1577 8.93022 20.0725 8.75028H20.063Z"
            :fill="color" />
    </svg>
</template>
  
<script>
export default {
    name: "IconHeartSleeping",

    props: {
        color: {
            type: String,
            requied: true,
        },
    },

    data() {
        return {};
    },
};
</script>
  
<style lang="scss">

</style>