<template>
    <div class="activitiesList-layout">
        <section v-if="currentProductionActivities.length" class="summary-section">
            <h3>
                {{ $filters.capitalize($t('activity.summary.details_title')) }}
            </h3>
            <template v-for="currentActivity in currentProductionActivities" :key="currentActivity.type">
                <base-card v-show="!isEntityCat() || (isEntityCat() && currentActivity.type != 'barking')"
                    :cardLink="'/activity/' + currentActivity.type">
                    <card-content :iconWrapper="{ iconType: 'icon-' + iconSuffix(currentActivity.type) }">
                        <template v-slot:header>
                            {{ $filters.capitalize($t('activity.' + currentActivity.type + '.title')) }}
                        </template>
                        <template v-slot:content>
                            <data-formater :type="currentActivity.type" :duration="currentActivity.duration"
                                :episodes="currentActivity.episodes" />
                        </template>
                    </card-content>
                </base-card>
            </template>
        </section>
        <section v-if="currentBetaActivities.length" class="summary-section">
            <collapse-card :iconDirection="collapseIcon" @click="toggleBetaExpanded()" class="modal-buttongroup">
                <card-content :isBeta="true" style="gap: 0;">
                    <template v-slot:header>
                        {{ $filters.capitalize($t('activity.summary.beta_title')) }}
                    </template>
                </card-content>
            </collapse-card>
            <collapse :when="isBetaExpanded">
                <section class="summary-section">
                    <p id="expandedbeta">
                        {{ $filters.capitalize($t('activity.summary.beta_subtitle')) }}
                    </p>
                    <template v-for="currentActivity in currentBetaActivities" :key="currentActivity.type">
                        <base-card v-show="!isEntityCat() || (isEntityCat() && currentActivity.type != 'barking')"
                            :cardLink="'/activity/' + currentActivity.type">
                            <card-content :iconWrapper="{ iconType: 'icon-' + iconSuffix(currentActivity.type) }"
                                :isBeta="isBeta(currentActivity)">
                                <template v-slot:header>
                                    {{ $filters.capitalize($t('activity.' + currentActivity.type + '.title')) }}
                                </template>
                                <template v-slot:content>
                                    <data-formater :type="currentActivity.type" :duration="currentActivity.duration"
                                        :episodes="currentActivity.episodes" />
                                </template>
                            </card-content>
                        </base-card>
                    </template>
                </section>
            </collapse>
        </section>
    </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import { Collapse } from 'vue-collapsed'
import { isBetaActivity } from '@/views/activity/SharedActivity';

import moment from 'moment';

import LayoutDefaultOnlyDay from "@/layouts/LayoutDefaultOnlyDay.vue";
import BaseCard from '@/components/ui/BaseCard.vue';
import CardContent from '@/components/ui/CardContent.vue';
import DataFormater from '@/components/ui/DataFormater.vue';
import CollapseCard from '@/components/ui/CollapseCard.vue';

export default {
    name: "ActivitiesList",

    components: {
        BaseCard,
        CardContent,
        DataFormater,
        Collapse,
        CollapseCard,
    },

    data() {
        return {
            isBetaExpanded: false,
        };
    },

    methods: {

        ...mapActions("general", ["setCurrentDate", "setDayPicker"]),
        ...mapActions("user", ["fetchEntity", "fetchDevice"]),
        ...mapActions("activity", ["setActivityBeta", "fetchCurrentActivities"]),

        iconSuffix(type) {

            if (this.isEntityCat()) {
                if (type == "walking") {
                    return "walking-cat"
                }
                if (type == "running") {
                    return "running-cat"
                }
                if (type == "trotting") {
                    return "trotting-cat"
                }
                if (type == "resting") {
                    return "resting-cat"
                }
                if (type == "petting") {
                    return "petting-cat"
                }
            }

            return type
        },

        isEntityCat() {
            return this.getEntityType == "cat"
        },

        isBeta(activity) {
            return isBetaActivity(activity.type)
        },

        isProduction(activity) {
            return !isBetaActivity(activity.type)
        },

        toggleBetaExpanded() {
            this.isBetaExpanded = !this.isBetaExpanded
            this.setActivityBeta(this.isBetaExpanded)
            if (this.isBetaExpanded) {
                // Autoscroll seems to work on chrome. But not on Safari, not on iOS...
                // Not in webview in android app. We activate for every one.
                setTimeout(function () {
                    let elem = document.getElementById('expandedbeta');
                    elem.scrollIntoView({ behavior: 'smooth', block: 'center' })
                }, 100)
            }
        },

        async updateCurrentActivities() {
            await this.fetchCurrentActivities({ 
                start: moment(this.getCurrentDate).clone().startOf('day').format('YYYY-MM-DD'), 
                end: moment(this.getCurrentDate).clone().startOf('day').format('YYYY-MM-DD'), 
                entity_id: localStorage.getItem('entityId') 
            });
        },
    },

    computed: {
        ...mapGetters("general", ["getCurrentDate"]),
        ...mapGetters("user", ["getEntityType"]),
        ...mapGetters("activity", ["getCurrentActivities", "getActivityBeta"]),

        currentProductionActivities() {
            return this.getCurrentActivities.filter(this.isProduction)
        },

        currentBetaActivities() {
            return this.getCurrentActivities.filter(this.isBeta)
        },

        collapseIcon() {
            if (this.isBetaExpanded) {
                return 'down'
            }
            else {
                return 'right'
            }
        },
    },

    watch: {
        getCurrentDate() {
            this.updateCurrentActivities();
        }
    },

    async mounted() {
        await this.fetchEntity();
        await this.fetchDevice();
        this.updateCurrentActivities();
    },

    created() {
        this.isBetaExpanded = this.getActivityBeta;
        this.setDayPicker(true);
        this.$emit("update:layout", LayoutDefaultOnlyDay);
    },
}

</script>

<style lang="scss" scoped>
    .activitiesList-layout {
        margin-top: 1em;
        display: flex;
        flex-direction: column;
        gap: 2em;
    }
</style>
