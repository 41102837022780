import { getImageProfilLink, getEntityInfos } from "../../api/collar.api";
import { getDevice } from "../../api/device.api";
import { postUserData } from "../../api/user.api";
import router from '@/router';
import moment from "moment";
import axios from 'axios';

const state = {
    userDemo: false,
    token: null,
    entityId: null,
    deviceId: null,
    falvor: null,

    imageProfil: null,
    entityName: null,
    entityType: null,
    deviceBoardName: null,
    lastHealthDataDate: null,
    lastActivityDataDate: null,
}

const localKeyDeviceId = "deviceId"
const localKeyDeviceBoardName = "deviceBoardName"

const getters = {
    getUserDemo(state) {
        return state.userDemo;
    },
    getToken(state) {
        return state.token;
    },
    getEntityId(state) {
        return state.entityId;
    },
    getFlavor(state) {
        return state.flavor;
    },
    getImageProfil(state) {
        return state.imageProfil;
    },
    getEntityName(state) {
        return state.entityName;
    },
    getEntityType(state) {
        return state.entityType;
    },
    getDeviceBoardname(state) {
        return state.deviceBoardName;
    },
    getLastHealthDataDate(state) {
        return state.lastHealthDataDate;
    },
    getLastActivityDataDate(state) {
        return state.lastActivityDataDate;
    },

    // Computed property with Property-Style Access
    isDeviceLWT6(state) {        
        // cat can only wear LWT6
        if (state.entityType == "cat") {
            return true;
        }
        if (state.deviceBoardName && state.deviceBoardName.includes("LWT4")) {
            return false;
        }
        // if no device is installed in entity, we will promote LWT6.
        return true;
    },

    getProductNameKey(state) {
        var isLWT6 = true;
        // TODO: find way to call isDeviceLWT6 function above
        if (state.deviceBoardName && state.deviceBoardName.includes("LWT4") && state.entityType != "cat") {
            isLWT6 = false;
        }
        if (state.flavor == "docupet") {
            return "global.product_name_homesafe";
        } else if (isLWT6) {
            return "global.product_name_lwt6";
        }
        return "global.product_name_lwt4";
    }
};

function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

const actions = {
    async setUserDemo({ commit }, data) {
        commit('SET_USERDEMO', data);
    },
    async removeUserDemo({ commit }) {
        commit('REMOVE_USERDEMO');
    },

    async fetchUser({ commit }, user_data) {
        try {
            const response = await postUserData(user_data);
            commit('SET_TOKEN', response.data.access);
            router.push('/health-summary');
        } catch (error) {
            return console.log(error);
        }
    },

    async setToken({ commit }, data) {
        commit('SET_TOKEN', data);
    },

    async setEntityID({ commit }, data) {
        commit('SET_ENTITYID', data);
    },

    async setFlavor({ commit }, data) {
        commit('SET_FLAVOR', data)
    },

    async fetchImageProfil({ commit }) {
        try {
            const response = await getImageProfilLink({entity_id: localStorage.getItem('entityId')});

            if (response.data.includes("static")) {
                return  commit('SET_IMAGEPROFIL', response.data);
            }

         //   console.log("response.data", response.data);
            const image = await axios.get(response.data, {responseType: 'blob'});
            //console.log("image", image.data);
            const base64String = await blobToBase64(image.data);
            //console.log("base64String", base64String);
            commit('SET_IMAGEPROFIL', base64String);
        } catch (error) {
            console.log(error);
        }
    },
    async fetchEntity({ commit, state }) {
        try {
            const response = await getEntityInfos({entity_id: localStorage.getItem('entityId')});
            commit('SET_ENTITYNAME', response.data.name);
            commit('SET_ENTITYTYPE', response.data.type);
            commit('SET_DEVICEID', response.data.device);
            if (response.data.last_health_data_datetime) {
                commit('SET_LASTHEALTHDATADATE', response.data.last_health_data_datetime);
            } else if (response.data.last_health_data_date || state.userDemo) {
                commit('SET_LASTHEALTHDATADATE', state.userDemo ? moment().format('YYYY-MM-DD') : response.data.last_health_data_date);
            } else {
                commit('REMOVE_LASTHEALTHDATADATE');
            }

            if (response.data.last_activity_data_datetime) {
                commit('SET_LASTACTIVITYDATADATE', response.data.last_activity_data_datetime);
            } else if (response.data.last_activity_data_date || state.userDemo) {
                commit('SET_LASTACTIVITYDATADATE', state.userDemo ? moment().format('YYYY-MM-DD') : response.data.last_activity_data_date);
            } else {
                commit('REMOVE_LASTACTIVITYDATADATE');
            }
        } catch (error) {
            console.log(error);
        }
    },

    async fetchDevice({ commit }) {
        try {
            let deviceId = localStorage.getItem(localKeyDeviceId)
            // when backend set null in json response, it is printed in a string ...
            if (deviceId && !deviceId.includes("null")) {
                const response = await getDevice({deviceId: localStorage.getItem(localKeyDeviceId)});
                let boardName = response.data.tracker_config.board_name;
                if (boardName) {
                    commit('SET_DEVICEBOARDNAME', boardName);
                }
            }
        } catch (error) {
            console.log(error);
        }
    },
}

const mutations = {
    SET_USERDEMO(state, data) {
        if (state.userDemo !== data) {
            state.userDemo = data;
            localStorage.setItem('userDemo', data);
        }
    },
    REMOVE_USERDEMO(state) {
        state.userDemo = false;
        localStorage.removeItem('userDemo');
    },
    SET_TOKEN(state, data) {
        if (state.token !== data) {
            state.token = data;
            localStorage.setItem('token', data);
        }
    },
    REMOVE_TOKEN(state) {
        state.token = null;
        localStorage.removeItem('token');
    },

    SET_ENTITYID(state, data) {
        if (state.entityId !== data) {
            state.entityId = data;
            localStorage.setItem('entityId', data);
        }
    },
    REMOVE_ENTITYID(state) {
        state.entityId = null;
        localStorage.removeItem('entityId');
    },

    SET_FLAVOR(state, data) {
        if (state.flavor !== data) {
            state.flavor = data;
            localStorage.setItem('flavor', data)
        }
    },
    REMOVE_FLAVOR(state) {
        state.flavor = null;
        localStorage.removeItem('flavor')
    },

    SET_IMAGEPROFIL(state, data) {
        if (state.imageProfil !== data) {
            state.imageProfil = data;
            localStorage.setItem('imageProfile', data);
        }
    },
    REMOVE_IMAGEPROFIL(state) {
        state.imageProfil = null;
        localStorage.removeItem('imageProfile');
    },

    SET_ENTITYNAME(state, data) {
        if (state.entityName !== data) {
            state.entityName = data;
            localStorage.setItem('entityName', data);
        }
    },
    REMOVE_ENTITYNAME(state) {
        state.entityName = null;
        localStorage.removeItem('entityName');
    },

    SET_ENTITYTYPE(state, data) {
        if (state.entityType !== data) {
            state.entityType = data;
            localStorage.setItem('entityType', data);
        }
    },
    REMOVE_ENTITYTYPE(state) {
        state.entityType = null;
        localStorage.removeItem('entityType')
    },

    SET_DEVICEID(state, data) {
        if (state.deviceId !== data) {
            state.deviceId = data;
            localStorage.setItem(localKeyDeviceId, data);
        }
    },
    REMOVE_DEVICEID(state) {
        state.deviceId = null;
        localStorage.removeItem(localKeyDeviceId);
    },

    SET_DEVICEBOARDNAME(state, data) {
        if (state.deviceBoardName !== data) {
            state.deviceBoardName = data;
            localStorage.setItem(localKeyDeviceBoardName, data);
        }
    },
    REMOVE_DEVICEBOARDNAME(state) {
        state.deviceBoardName = null;
        localStorage.removeItem(localKeyDeviceBoardName);
    },

    SET_LASTHEALTHDATADATE(state, data) {
        if (state.lastHealthDataDate !== data) {
            state.lastHealthDataDate = data;
            localStorage.setItem('lastHealthDataDate', data);
        }
    },
    REMOVE_LASTHEALTHDATADATE(state) {
        state.lastHealthDataDate = null;
        localStorage.removeItem('lastHealthDataDate');
    },

    SET_LASTACTIVITYDATADATE(state, data) {
        if (state.lastActivityDataDate !== data) {
            state.lastActivityDataDate = data;
            localStorage.setItem('lastActivityDataDate', data);
        }
    },
    REMOVE_LASTACTIVITYDATADATE(state) {
        state.lastActivityDataDate = null;
        localStorage.removeItem('lastActivityDataDate');
    },

    INITIALISE_USER_STORE(state) {
        if (localStorage.getItem('token')) {
            state.token = localStorage.getItem('token');
        }
        if (localStorage.getItem('entityId')) {
            state.entityId = localStorage.getItem('entityId');
        }
        if (localStorage.getItem('imageProfile')) {
            state.imageProfil = localStorage.getItem('imageProfile');
        }
        if (localStorage.getItem('entityName')) {
            state.entityName = localStorage.getItem('entityName');
        }
        if (localStorage.getItem('entityType')) {
            state.entityType = localStorage.getItem('entityType');
        }
        let deviceId = localStorage.getItem(localKeyDeviceId)
        if (deviceId) {
            state.deviceId = deviceId
        }
        let deviceBoardName = localStorage.getItem(localKeyDeviceBoardName)
        if (deviceBoardName) {
            state.deviceBoardName = deviceBoardName
        }
        if (localStorage.getItem('lastHealthDataDate')) {
            state.lastHealthDataDate = localStorage.getItem('lastHealthDataDate');
        }
        if (localStorage.getItem('lastActivityDataDate')) {
            state.lastActivityDataDate = localStorage.getItem('lastActivityDataDate');
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}