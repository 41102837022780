<template>
    <div class="graph-container">
        <template v-if="this.series[0].data.length && this.getCurrentSleep.series">
            <!--JSON.parse(JSON.stringify(series)) is needed so "Maximum recursive updates exceeded" doesn't happen -->
            <apexchart type="rangeBar" height="30" :options="chartOptions" :series="JSON.parse(JSON.stringify(series))" style="border-radius: 3em;"></apexchart>
            <div class="sleep-layout">
                <h5 v-if="this.getCurrentSleep.startTime">
                    {{ $filters.formatTime(this.getCurrentSleep.startTime) }}
                </h5>
                <h5 v-if="this.getCurrentSleep.endTime">
                    {{ $filters.formatTime(this.getCurrentSleep.endTime) }}
                </h5>
            </div>
        </template>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "SleepGraph",

    data() {
        return {
            series: [{
                data: []
            }],
            chartOptions: {
                chart: {
                    type: 'rangeBar',
                },
            },
        };
    },

    methods: {
        async updateGraph() {
            if (this.getCurrentSleep && this.getCurrentSleep.series) {
                this.series = this.getCurrentSleep.series;
                this.chartOptions = {
                    colors: ["#D9D9D9", '#399D92'],
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            barHeight: '100%',
                            rangeBarGroupRows: true,
                            borderRadius: 3,
                            borderRadiusApplication: 'around',
                            borderRadiusWhenStacked: 'last',
                        }
                    },
                    chart: {
                        type: 'rangeBar',
                        sparkline: {
                            enabled: true
                        },
                        zoom: {
                            enabled: false
                        },
                        toolbar: {
                            show: false
                        },
                        animations: {
                            enabled: false,
                            easing: 'easeinout',
                            speed: 600,
                            animateGradually: {
                                enabled: true,
                                delay: 150
                            },
                            dynamicAnimation: {
                                enabled: true,
                                speed: 350
                            }
                        }
                    },
                    tooltip: {
                        enabled: false,
                    },
                    legend: {
                        show: false,
                    },
                    states: {
                        hover: {
                            filter: {
                                type: 'none',
                            }
                        },
                        active: {
                            filter: {
                                type: 'none'
                            }
                        }
                    },
                    xaxis: {
                        type: 'numeric',
                        min: this.getCurrentSleep.startTime,
                        max: this.getCurrentSleep.endTime,
                        labels: {
                            show: false
                        },
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false,
                        },
                    },
                    yaxis: {
                        labels: {
                            show: false,
                        },
                        axisBorder: {
                            show: false
                        },

                    },
                };
            }
        }
    },

    computed: {
        ...mapGetters("activity", ["getCurrentSleep"]),
    },

    watch: {
        getCurrentSleep() {
            this.updateGraph();
        }
    },

    async mounted() {
        this.updateGraph();
    },
}
</script>

<style lang="scss" scoped>
.sleep-layout {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    align-items: center;
    justify-content: space-between;
}

.graph-container {
    // Needed so duplicate graphs bug doesn't happen
    width: 100%;
}
</style>