import demoData from "../../../public/json/demo-data.json";
import moment from "moment";
import { getActivities, getActivityData, getSleep } from "../../api/collar.api";

const state = {
  activityDemo: false,
  activityBeta: false,
  showSleep: false,
  activityRangeType: "day",
  loadingActivity: false,
  currentActivities: [],
  currentExerciseLevel: [],
  currentActivity: [],
  currentSleep: {},
};

const getters = {
  getActivityDemo(state) {
    return state.activityDemo;
  },
  getActivityBeta(state) {
    return state.activityBeta;
  },
  getShowSleep(state) {
    return state.showSleep;
  },
  getActivityRangeType(state) {
    return state.activityRangeType;
  },
  getLoadingActivity(state) {
    return state.loadingActivity;
  },
  getCurrentActivities(state) {
    return state.currentActivities;
  },
  getCurrentExerciseLevel(state) {
    return state.currentExerciseLevel;
  },
  getCurrentActivity(state) {
    return state.currentActivity;
  },
  getCurrentSleep(state) {
    return state.currentSleep;
  },
};

const actions = {
  async setActivityDemo({ commit }, data) {
    commit("SET_ACTIVITYDEMO", data);
  },
  async setActivityBeta({ commit }, data) {
    commit("SET_ACTIVITYBETA", data);
  },
  async setShowSleep({ commit }, data) {
    commit("SET_SHOWSLEEP", data);
  },
  async removeActivityDemo({ commit }) {
    commit("REMOVE_ACTIVITYDEMO");
  },
  async setActivityRangeType({ commit }, data) {
    commit("SET_ACTIVITYRANGETYPE", data);
  },

  async fetchCurrentActivities({ commit, state }, data) {
    try {
      const response = state.activityDemo
        ? demoData["default"].activity
        : await getActivities(data);

      if (state.activityDemo) {
        let dataFormat = [
          { type: "resting", duration: 0, episodes: 0 },
          { type: "walking", duration: 0, episodes: 0 },
          { type: "running", duration: 0, episodes: 0 },
          { type: "barking", duration: 0, episodes: 0 },
          { type: "eating", duration: 0, episodes: 0 },
          { type: "trotting", duration: 0, episodes: 0 },
          { type: "driving", duration: 0, episodes: 0 },
        ];
        let index = 0;
        dataFormat.forEach((dataForm) => {
          index = 0;

          while (response[dataForm.type].data.stats[index].date != data.start) {
            index += 1;
          }
          if (
            dataForm.type == "resting" ||
            dataForm.type == "walking" ||
            dataForm.type == "running" ||
            dataForm.type == "trotting" ||
            dataForm.type == "driving"
          ) {
            dataForm.duration =
              response[dataForm.type].data.stats[index].duration;
          } else {
            dataForm.episodes =
              response[dataForm.type].data.stats[index].episode;
          }
        });
        commit("SET_CURRENTACTIVITIES", dataFormat);
      } else {

        commit("SET_CURRENTACTIVITIES", response.data.activities);
      }
    } catch (error) {
      console.log(error);
    }
  },

  async fetchExerciseLevel({ commit }, data) {
    const response = state.activityDemo
      ? demoData["default"].exerciseLevel
      : await getActivities(data);

    let dataFormat = [];
    let index = 0;

    var currDate = moment(data.start).clone().startOf("day");
    var lastDate = moment(data.end).clone().startOf("day");
    if (currDate.diff(lastDate) == 0) {
      let startDay = moment(currDate)
        .clone()
        .startOf("day")
        .add(0, "minutes")
        .valueOf();
      let endDay = moment(lastDate)
        .clone()
        .add(1, "days")
        .startOf("day")
        .valueOf();

      dataFormat = [{ data: [] }, { data: [] }];

      while (
        state.activityDemo &&
        response.data.timeline[index] &&
        startDay > response.data.timeline[index][0]
      ) {
        index += 1;
      }
      while (startDay < endDay && startDay <= moment().valueOf()) {
        //  Quarter
        if (response.data.timeline && response.data.timeline[index]) {
          if (
            moment(currDate)
              .clone()
              .startOf("day")
              .add(response.data.timeline[index][0], "minutes")
              .valueOf() == startDay
          ) {
            dataFormat[0].data.unshift([
              startDay,
              response.data.timeline[index][1],
            ]);
            dataFormat[1].data.unshift([
              startDay,
              response.data.timeline[index][2],
            ]);
            dataFormat[0].data.unshift([
              startDay + 900000,
              response.data.timeline[index][1],
            ]);
            dataFormat[1].data.unshift([
              startDay + 900000,
              response.data.timeline[index][2],
            ]);
            index += 1;
            startDay += 1800000;
          } else {
            dataFormat[0].data.unshift([startDay, 0]);
            dataFormat[1].data.unshift([startDay, 0]);
            dataFormat[0].data.unshift([startDay + 900000, 0]);
            dataFormat[1].data.unshift([startDay + 900000, 0]);
            startDay += 1800000;
          }
        } else {
          dataFormat[0].data.unshift([startDay, 0]);
          dataFormat[1].data.unshift([startDay, 0]);
          startDay += 3600000;
        }

        // HOUR

        // if (response.data.timeline && response.data.timeline[index]) {
        //   if (
        //     moment(currDate)
        //       .clone()
        //       .startOf("day")
        //       .add(response.data.timeline[index][0], "minutes")
        //       .valueOf() == startDay
        //   ) {
        //     if (
        //       response.data.timeline[index + 1] &&
        //       moment(currDate)
        //         .clone()
        //         .startOf("day")
        //         .add(response.data.timeline[index + 1][0], "minutes")
        //         .valueOf() ==
        //         startDay + 1800000
        //     ) {
        //       dataFormat[0].data.unshift([
        //         startDay,
        //         response.data.timeline[index][1] +
        //           response.data.timeline[index + 1][1],
        //       ]);
        //       dataFormat[1].data.unshift([
        //         startDay,
        //         response.data.timeline[index][2] +
        //           response.data.timeline[index + 1][2],
        //       ]);
        //       index += 2;
        //       startDay += 3600000;
        //     } else {
        //       dataFormat[0].data.unshift([
        //         startDay,
        //         response.data.timeline[index][1],
        //       ]);
        //       dataFormat[1].data.unshift([
        //         startDay,
        //         response.data.timeline[index][2],
        //       ]);
        //       index += 1;
        //       startDay += 3600000;
        //     }
        //   } else if (
        //     response.data.timeline[index] &&
        //     moment(currDate)
        //       .clone()
        //       .startOf("day")
        //       .add(response.data.timeline[index][0], "minutes")
        //       .valueOf() ==
        //       startDay + 1800000
        //   ) {
        //     dataFormat[0].data.unshift([
        //       startDay,
        //       response.data.timeline[index][1],
        //     ]);
        //     dataFormat[1].data.unshift([
        //       startDay,
        //       response.data.timeline[index][2],
        //     ]);
        //     index += 1;
        //     startDay += 3600000;
        //   } else {
        //     dataFormat[0].data.unshift([startDay, 0]);
        //     dataFormat[1].data.unshift([startDay, 0]);
        //     startDay += 3600000;
        //   }
        // } else {
        //   dataFormat[0].data.unshift([startDay, 0]);
        //   dataFormat[1].data.unshift([startDay, 0]);
        //   startDay += 3600000;
        // }

        // HALF HOUR

        // if (response.data.timeline && response.data.timeline[index] && response.data.timeline[index + 1] && ((startDay + 1800000 ==
        //     moment(currDate)
        //       .clone()
        //       .startOf("day")
        //       .add(response.data.timeline[index + 1][0], "minutes")
        //       .valueOf()) || (startDay ==
        //         moment(currDate)
        //           .clone()
        //           .startOf("day")
        //           .add(response.data.timeline[index + 1][0], "minutes")
        //           .valueOf()))) {
        //         if (
        //             response.data.timeline &&
        //             response.data.timeline[index] &&
        //             startDay ==
        //               moment(currDate)
        //                 .clone()
        //                 .startOf("day")
        //                 .add(response.data.timeline[index][0], "minutes")
        //                 .valueOf()
        //           ) {
        //             dataFormat[0].data.unshift([
        //               startDay,
        //               response.data.timeline[index][1] + response.data.timeline[index + 1][1],
        //             ]);
        //             dataFormat[1].data.unshift([
        //               startDay,
        //               response.data.timeline[index][2] + response.data.timeline[index + 1][2],
        //             ]);
        //             index += 2;
        //           } else {
        //             dataFormat[0].data.unshift([startDay, 0 + response.data.timeline[index + 1][1]]);
        //             dataFormat[1].data.unshift([startDay, 0 + response.data.timeline[index + 1][2]]);
        //             index += 1;
        //           }
        //           startDay += 3600000;
        //       } else {
        //         if (
        //             response.data.timeline &&
        //             response.data.timeline[index] &&
        //             startDay ==
        //               moment(currDate)
        //                 .clone()
        //                 .startOf("day")
        //                 .add(response.data.timeline[index][0], "minutes")
        //                 .valueOf()
        //           ) {
        //             dataFormat[0].data.unshift([
        //               startDay,
        //               response.data.timeline[index][1],
        //             ]);
        //             dataFormat[1].data.unshift([
        //               startDay,
        //               response.data.timeline[index][2],
        //             ]);
        //             index += 1;
        //           } else {
        //             dataFormat[0].data.unshift([startDay, 0]);
        //             dataFormat[1].data.unshift([startDay, 0]);
        //           }
        //           startDay += 3600000;
        //       }
      }
      commit("SET_CURRENTEXERCISELEVEL", dataFormat);
    } else {
      currDate = moment(data.start).clone().startOf("day");
      lastDate = moment(data.end).clone().startOf("day");
      dataFormat = [{ data: [] }, { data: [] }];

      if (
        state.activityDemo &&
        lastDate.diff(moment(response.data.durations[index].date), "days") < 0
      ) {
        index = Math.abs(
          lastDate.diff(moment(response.data.durations[index].date), "days")
        );
      }

      while (lastDate.diff(currDate) >= 0) {
        if (response.data.durations && response.data.durations.length) {
          if (
            response.data.durations.length > index &&
            lastDate.diff(
              moment(response.data.durations[index].date).startOf("day")
            ) == 0
          ) {
            dataFormat[0].data.unshift(
              response.data.durations[index].values.length
                ? response.data.durations[index].values[0]
                : 0
            );
            dataFormat[1].data.unshift(
              response.data.durations[index].values.length
                ? response.data.durations[index].values[1]
                : 0
            );
            index += 1;
          } else {
            dataFormat[0].data.unshift(0);
            dataFormat[1].data.unshift(0);
          }
        } else {
          dataFormat[0].data.unshift(0);
          dataFormat[1].data.unshift(0);
        }
        lastDate.add(-1, "days");
      }
      commit("SET_CURRENTEXERCISELEVEL", dataFormat);
    }
  },

  async fetchActivity({ commit, state }, data) {
    try {
      const response = state.activityDemo
        ? demoData["default"].activity[data.type]
        : await getActivityData(data);

      let dataFormat = [];
      let index = 0;

      var currDate = moment(data.start).clone().startOf("day");
      var lastDate = moment(data.end).clone().startOf("day");

      if (currDate.diff(lastDate) == 0) {
        let startDay = moment(currDate).clone().startOf("day").valueOf();
        let endDay = moment(lastDate)
          .clone()
          .add(1, "days")
          .startOf("day")
          .valueOf();
        while (
          state.activityDemo &&
          response.data.timeline[index] &&
          startDay > response.data.timeline[index][0]
        ) {
          index += 1;
        }
        while (startDay < endDay) {
          if (
            response.data.timeline &&
            response.data.timeline[index] &&
            startDay ==
            moment(currDate)
              .clone()
              .startOf("day")
              .add(response.data.timeline[index][0], "minutes")
              .valueOf()
          ) {
            dataFormat.push(response.data.timeline[index][1]);
            index += 1;
          } else {
            dataFormat.push(0);
          }
          startDay += 1800000;
        }
        commit("SET_CURRENTACTIVITY", dataFormat);
      } else {
        currDate = moment(data.start).clone().startOf("day");
        lastDate = moment(data.end).clone().startOf("day");

        if (
          state.activityDemo &&
          lastDate.diff(moment(response.data.stats[index].date), "days") < 0
        ) {
          index = Math.abs(
            lastDate.diff(moment(response.data.stats[index].date), "days")
          );
        }

        while (lastDate.diff(currDate) >= 0) {
          if (response.data.stats.length) {
            if (
              response.data.stats.length > index &&
              lastDate.diff(
                moment(response.data.stats[index].date).startOf("day")
              ) == 0
            ) {
              dataFormat.unshift(response.data.stats[index].duration);
              index += 1;
            } else {
              dataFormat.unshift(null);
            }
          } else {
            dataFormat.unshift(null);
          }
          lastDate.add(-1, "days");
        }
        commit("SET_CURRENTACTIVITY", dataFormat);
      }
    } catch (error) {
      console.log(error);
    }
  },

  async fetchCurrentSleep({ commit }, data) {
    try {
      var isDemo = false;
      const response = await getSleep(data);
      if (isDemo) {
        const series = [{
          name: "wake",
          data: [
            {
              x: 'night',
              y: [
                new Date('2019-03-03T21:23').getTime(),
                new Date('2019-03-04T07:37').getTime()
              ],
            },
          ],
        },
        {
          name: "sleep",
          data: [
            {
              x: 'night',
              y: [
                new Date('2019-03-03T21:23').getTime(),
                new Date('2019-03-03T22:57').getTime()
              ],
            },
            {
              x: 'night',
              y: [
                new Date('2019-03-03T23:26').getTime(),
                new Date('2019-03-04T03:42').getTime()
              ],
            },
            {
              x: 'night',
              y: [
                new Date('2019-03-04T04:59').getTime(),
                new Date('2019-03-04T07:37').getTime()
              ],
            },
          ],
        }];
        const dataFormat = {
          score: 95,
          totalDuration: 435,
          interruptions: 26,
          series: series,
          startTime: new Date('2019-03-03T21:23').getTime(),
          endTime: new Date('2019-03-04T07:37').getTime()
        };
        commit("SET_CURRENTSLEEP", dataFormat);

      } else if (response.data.length) {
        const night = response.data[0];

        var interruptions = 0;
        var startTime; 
        var endTime;
        var series;

        if (night.episodes.length) {
          interruptions = night.episodes.length - 1;
          startTime = night.episodes[0]['start'] * 1000;
          endTime = night.episodes[night.episodes.length - 1]['end'] * 1000;

          var sleepEpisodes = []
          for (const episode of night.episodes) {
            /* console.log(episode);
            let start = new Date(episode.start * 1000).toUTCString();
            let end = new Date(episode.end * 1000).toUTCString();
            console.log(start, ' - ', end); */
            sleepEpisodes.push({
              x: 'night',
              y: [ episode['start'] * 1000, episode['end'] * 1000 ]
            })
          }
          series = [
            {
              data: [{
                x: 'night',
                y: [ startTime, endTime ]
              }]
            },
            {
              data : sleepEpisodes
            }
          ];
      }
        
        var dataFormat = {
          score: night.score,
          totalDuration: night.duration,
          interruptions: interruptions,
          series: series,
          startTime: startTime,
          endTime: endTime
        };
        commit("SET_CURRENTSLEEP", dataFormat);

      } else {
        commit("REMOVE_CURRENTSLEEP");
      }
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  SET_ACTIVITYDEMO(state, data) {
    if (state.activityDemo !== data) {
      state.activityDemo = data;
      localStorage.setItem("activityDemo", data);
    }
  },
  REMOVE_ACTIVITYDEMO(state) {
    state.activityDemo = false;
    localStorage.removeItem("activityDemo");
  },

  SET_ACTIVITYBETA(state, data) {
    if (state.activityBeta !== data) {
      state.activityBeta = data;
      localStorage.setItem("isBetaExpanded", data);
    }
  },

  SET_SHOWSLEEP(state, data) {
    if (state.showSleep !== data) {
      state.showSleep = data;
      localStorage.setItem("isShowingSleep", data);
    }
  },

  SET_CURRENTACTIVITIES(state, data) {
    if (state.currentActivities !== data) {
      state.currentActivities = data;
      localStorage.setItem("currentActivities", JSON.stringify(data));
    }
  },
  REMOVE_CURRENTACTIVITIES(state) {
    state.currentActivities = null;
    localStorage.removeItem("currentActivities");
  },

  SET_CURRENTEXERCISELEVEL(state, data) {
    if (JSON.stringify(state.currentExerciseLevel) !== JSON.stringify(data)) {
      state.currentExerciseLevel = data;
      localStorage.setItem("currentExerciseLevel", JSON.stringify(data));

      setTimeout(() => {
        state.loadingActivity = false;
      }, 1000);
    }
  },
  REMOVE_CURRENTEXERCISELEVEL() {
    state.currentExerciseLevel = [];
    localStorage.removeItem("currentExerciseLevel");
  },

  SET_CURRENTACTIVITY(state, data) {
    if (state.currentActivity !== data) {
      state.currentActivity = data;
      localStorage.setItem("currentActivity", JSON.stringify(data));

      setTimeout(() => {
        state.loadingActivity = false;
      }, 1000);
    }
  },
  REMOVE_CURRENTACTIVITY(state) {
    state.currentActivity = [];
    localStorage.removeItem("currentActivity");
  },

  SET_CURRENTSLEEP(state, data) {
    if (state.currentSleep !== data) {
      state.currentSleep = data;
      localStorage.setItem("currentSleep", JSON.stringify(data));

      setTimeout(() => {
        state.loadingActivity = false;
      }, 1000);
    }
  },
  REMOVE_CURRENTSLEEP(state) {
    state.currentSleep = null;
    localStorage.removeItem("currentSleep");
  },

  SET_ACTIVITYRANGETYPE(state, data) {
    if (state.activityRangeType !== data) {
      state.loadingActivity = true;
      state.activityRangeType = data;
      localStorage.setItem("activityRangeType", data);
    }
  },
  REMOVE_ACTIVITYRANGETYPE(state) {
    state.activityRangeType = null;
    localStorage.removeItem("activityRangeType");
  },

  INITIALISE_ACTIVITY_STORE(state) {
    if (localStorage.getItem("activityRangeType")) {
      state.activityRangeType = localStorage.getItem("activityRangeType");
    }
    if (localStorage.getItem("currentActivities")) {
      state.currentActivities = JSON.parse(
        localStorage.getItem("currentActivities")
      );
    }
    if (localStorage.getItem("currentAcitvity")) {
      state.currentActivity = JSON.parse(
        localStorage.getItem("currentActivity")
      );
    }
    state.activityBeta = toBoolean(localStorage.getItem('isBetaExpanded'));
    state.showSleep = toBoolean(localStorage.getItem('isShowingActivity'));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  toBoolean,
};

function toBoolean(str) {
  if (typeof str === 'undefined' || str === null) {
    return false
  } else if (typeof str === 'string') {
    switch (str.toLocaleLowerCase()) {
      case 'true':
      case 'yes':
      case 'y':
      case 't':
      case '1':
        return true;
      default:
        return false;
    }
  }
  else if (typeof str === 'number') {
    return str !== 0
  }
  else {
    return false;
  }
}
