<template>
    <div class="summary-layout">
        <section class="summary-section">
            <h3>
                {{ $filters.capitalize($t('dashboard.last_data_title')) }}
            </h3>
            <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; gap: 0.5em">
                <base-card :cardSize="1">
                    <div class="data-resume">
                        <icon-home :color="'#7154E3'" />
                        <p class="data-resume-text"> {{ $filters.capitalize($t('dashboard.location_status.home')) }}
                        </p>
                    </div>
                </base-card>
                <base-card :cardSize="1">
                    <div class="data-resume">
                        <icon-health-cross :color="'#FF1449'" />
                        <p class="data-resume-text"> {{ $filters.capitalize($t('dashboard.health_status.stable')) }}
                        </p>
                    </div>
                </base-card>
                <base-card :cardSize="1">
                    <div class="data-resume">
                        <icon-resting-line :color="'#1D60E0'" />
                        <p class="data-resume-text"> {{ $filters.capitalize($t('dashboard.activity_status.resting')) }}
                        </p>
                    </div>
                </base-card>
            </div>
        </section>
        <section class="summary-section">
            <h3>
                {{ $filters.capitalize($t('dashboard.last_event_title')) }}
            </h3>
            <base-card>
                <div class="event-resume">
                    <img src="@/assets/images/collar/poincarre.png" class="event-resume-image" />
                    <div class="event-resume-content">
                        <div class="event-resume-title">
                            {{ $filters.capitalize($t('dashboard.last_event_card', { entityName: getEntityName })) }}
                        </div>
                        <div class="event-resume-datagroup">
                            <div class="event-resume-data">
                                <icon-running :color="'#3B3B3B'" />: 6km
                            </div>
                            <div class="event-resume-data">
                                <icon-clock :color="'#3B3B3B'" />: 1h42
                            </div>
                        </div>
                    </div>
                </div>
            </base-card>
        </section>
        <section class="summary-section">
            <h3>
                {{ $filters.capitalize($t('dashboard.dog_research_title')) }}
            </h3>
            <base-card :backgroundColor="'purple'">
                <card-content>
                    <template v-slot:content>
                        <div style="display:flex; flex-direction: row;">
                            <div>
                                <img style="display:flex; margin: 0 0 -1em -1em; width:125px;"
                                    src="@/assets/images/collar/veterinary-dog.png" />
                            </div>
                            <div style="display: flex; flex-direction: column; gap: 0.5em; justify-content: center;">
                                <h4>
                                    {{ $filters.capitalize($t('dashboard.dog_research_card')) }}
                                </h4>
                                <div style="color: #5239B8; font-weight: 700; font-size: 14px;">
                                    <icon-files :color="'#5239B8'" />
                                    {{ $filters.capitalize($t('dashboard.participate')) }}
                                </div>
                            </div>
                        </div>
                    </template>
                </card-content>
            </base-card>
            <div class="research-link">
                {{ $filters.capitalize($t('dashboard.studies_link')) }}
            </div>
        </section>
        <section class="summary-section">
            <h3>
                {{ $filters.capitalize($t('dashboard.blog_link_title')) }}
            </h3>
            <blog-card :blogImageSrc="'dog.png'" :blogName="'dashboard.blog_name_tips'" />
            <blog-card :blogImageSrc="'dog-cute.png'" :blogName="'dashboard.blog_name_health'" />
        </section>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

// import moment from 'moment';

import LayoutSummary from "@/layouts/LayoutSummary.vue";
import BaseCard from "@/components/ui/BaseCard.vue";
import CardContent from "@/components/ui/CardContent.vue";

import IconHome from "@/components/svg/IconHome.vue";
import IconHealthCross from "@/components/svg/IconHealthCross.vue";
import IconRestingLine from "@/components/svg/IconRestingLine.vue";
import IconRunning from "@/components/svg/IconRunning.vue";
import IconClock from "@/components/svg/IconClock.vue";
import IconFiles from "@/components/svg/IconFiles.vue";
import BlogCard from "@/components/ui/BlogCard.vue";

export default {
    name: "DashBoard",
    components: {
        BaseCard,
        CardContent,
        IconHome,
        IconHealthCross,
        IconRestingLine,
        IconRunning,
        IconClock,
        IconFiles,
        BlogCard
    },

    computed: {
        ...mapGetters("user", ["getEntityName"])
    },

    methods: {
        ...mapActions("user", ["setToken", "setEntityID", "setUserDemo"]),

        generateDataHealth(min, max, delta) {
            if (delta != 0) {
                return Math.floor(Math.random() * ((max + this.generateDataHealth(1, delta, 0)) - (min + this.generateDataHealth(1, delta, 0)) + 1) + (min + this.generateDataHealth(1, delta, 0)))
            } else {
                return 0
            }
        },
        // generateDogData(size, dateStart, dateEnd) {
        //     let minHR = 0; let maxHR = 0; let minRR = 0; let maxRR = 0;
        //     if (size == "small") {
        //         minHR = 110;
        //         maxHR = 120;
        //         minRR = 20;
        //         maxRR = 25;
        //     } else if (size == "medium") {
        //         minHR = 70;
        //         maxHR = 100;
        //         minRR = 16;
        //         maxRR = 20;
        //     } else if (size == "large") {
        //         minHR = 60;
        //         maxHR = 70;
        //         minRR = 14;
        //         maxRR = 18;
        //     }

        //     let currentDate = dateStart
        //     let generateDataDemo = { HR: { data: [] }, RR: { data: [] }, exerciseLevel: { data: { timeline: [], stats: [] } }, activity: { resting: { data: { timeline: [], stats: [] } }, walking: { data: { timeline: [], stats: [] } }, running: { data: { timeline: [], stats: [] } }, barking: { data: { episodes: [], stats: [] } }, eating: { data: { episodes: [], stats: [] } } } };

        //     while (currentDate != dateEnd) {
        //         let hourIndex = 1;
        //         let startDay = moment(currentDate).clone().startOf('day').add(30, 'minutes').valueOf();
        //         let endDay = moment(currentDate).clone().add(1, 'days').startOf('day').valueOf();

        //         let eating = 0;
        //         let eatingNumber = 0;

        //         let barking = 0;
        //         let barkingNumber = 0;

        //         let resting = 0;
        //         let walking = 0;
        //         let running = 0;
        //         let randPercentActivity = 0;

        //         let statsOfCurrentDay = { resting: 0, walking: 0, running: 0, barking: 0, eating: 0 };

        //         while (startDay < endDay) {
        //             if (hourIndex <= 6 || hourIndex >= 22) {
        //                 generateDataDemo.activity.resting.data.timeline.push([startDay, 60])
        //                 statsOfCurrentDay.resting += 60;
        //             } else if (hourIndex >= 11 && hourIndex <= 13) {
        //                 randPercentActivity = this.generateDataHealth(65, 85, 1);
        //                 resting = Math.round(60 * randPercentActivity / 100);
        //                 walking = 60 - resting;
        //                 generateDataDemo.activity.resting.data.timeline.push([startDay, resting]);
        //                 generateDataDemo.activity.walking.data.timeline.push([startDay, walking]);
        //                 generateDataDemo.exerciseLevel.data.timeline.push([startDay, Math.round((walking / 60) * 100)])
        //                 statsOfCurrentDay.resting += resting;
        //                 statsOfCurrentDay.walking += walking;

        //                 if (hourIndex == 13) {
        //                     eatingNumber += 1;
        //                     barkingNumber += 1;

        //                     eating = this.generateDataHealth(2, 4, 1);
        //                     barking = this.generateDataHealth(3, 5, 1);

        //                     generateDataDemo.activity.eating.data.episodes.push([startDay + this.generateDataHealth(0, 15, 10), eating]);
        //                     generateDataDemo.activity.barking.data.episodes.push([startDay + this.generateDataHealth(0, 15, 10), barking]);

        //                     statsOfCurrentDay.eating += eating;
        //                     statsOfCurrentDay.barking += barking;
        //                 }
        //             } else if ((hourIndex > 6 && hourIndex <= 9) || (hourIndex > 13 && hourIndex <= 15) || (hourIndex >= 19 && hourIndex < 22)) {
        //                 randPercentActivity = this.generateDataHealth(55, 70, 1);
        //                 resting = Math.round(60 * randPercentActivity / 100);
        //                 walking = 60 - resting;
        //                 generateDataDemo.activity.resting.data.timeline.push([startDay, resting]);
        //                 generateDataDemo.activity.walking.data.timeline.push([startDay, walking]);
        //                 generateDataDemo.exerciseLevel.data.timeline.push([startDay, Math.round((walking / 60) * 100)])
        //                 statsOfCurrentDay.resting += resting;
        //                 statsOfCurrentDay.walking += walking;

        //                 if (hourIndex == 8 || hourIndex == 20) {
        //                     eatingNumber += 1;
        //                     barkingNumber += 1;

        //                     eating = this.generateDataHealth(2, 4, 1);
        //                     barking = this.generateDataHealth(3, 5, 1);

        //                     generateDataDemo.activity.eating.data.episodes.push([startDay + this.generateDataHealth(-15, 15, 15), eating]);
        //                     generateDataDemo.activity.barking.data.episodes.push([startDay + this.generateDataHealth(-15, 15, 15), barking]);
        //                     statsOfCurrentDay.eating += eating;
        //                     statsOfCurrentDay.barking += barking;
        //                 }
        //             } else {
        //                 if ((moment(currentDate).format('dddd').substring(0, 3) != 'Wed' && moment(currentDate).format('dddd').substring(0, 3) != 'Sat' && moment(currentDate).format('dddd').substring(0, 3) != 'Sun') && (hourIndex > 9 && hourIndex <= 15)) {
        //                     randPercentActivity = this.generateDataHealth(3, 28, 1);
        //                     resting = Math.round(60 * randPercentActivity / 100);
        //                     walking = Math.round((60 - resting) * this.generateDataHealth(70, 80, 15) / 100);
        //                     running = 60 - (walking + resting);
        //                     generateDataDemo.activity.resting.data.timeline.push([startDay, resting]);
        //                     generateDataDemo.activity.walking.data.timeline.push([startDay, walking]);
        //                     generateDataDemo.activity.running.data.timeline.push([startDay, running]);
        //                     generateDataDemo.exerciseLevel.data.timeline.push([startDay, Math.round(((walking + running) / 60) * 100)])
        //                     statsOfCurrentDay.resting += resting;
        //                     statsOfCurrentDay.walking += walking;
        //                     statsOfCurrentDay.running += running;
        //                 } else {
        //                     randPercentActivity = this.generateDataHealth(3, 7, 2);
        //                     resting = Math.round(60 * randPercentActivity / 100);
        //                     walking = Math.round((60 - resting) * this.generateDataHealth(20, 30, 15) / 100);
        //                     running = 60 - (walking + resting);
        //                     generateDataDemo.activity.resting.data.timeline.push([startDay, resting]);
        //                     generateDataDemo.activity.walking.data.timeline.push([startDay, walking]);
        //                     generateDataDemo.activity.running.data.timeline.push([startDay, running]);
        //                     generateDataDemo.exerciseLevel.data.timeline.push([startDay, Math.round(((walking + running) / 60) * 100)])
        //                     statsOfCurrentDay.resting += resting;
        //                     statsOfCurrentDay.walking += walking;
        //                     statsOfCurrentDay.running += running;

        //                     if (hourIndex == 16) {
        //                         eatingNumber += 1;
        //                         barkingNumber += 1;

        //                         eating = this.generateDataHealth(2, 4, 1);
        //                         barking = this.generateDataHealth(3, 5, 1);

        //                         generateDataDemo.activity.eating.data.episodes.push([startDay + this.generateDataHealth(0, 15, 10), eating]);
        //                         generateDataDemo.activity.barking.data.episodes.push([startDay + this.generateDataHealth(0, 15, 10), barking]);

        //                         statsOfCurrentDay.eating += eating;
        //                         statsOfCurrentDay.barking += barking;
        //                     }
        //                 }
        //             }
        //             hourIndex += 1;
        //             startDay += 3600000;
        //         }
        //         generateDataDemo.exerciseLevel.data.stats.unshift({ date: moment(startDay).clone().add(-1, 'day').format('YYYY-MM-DD'), duration: [statsOfCurrentDay.walking, statsOfCurrentDay.running] })

        //         generateDataDemo.activity.resting.data.stats.unshift({ date: moment(startDay).clone().add(-1, 'day').format('YYYY-MM-DD'), duration: statsOfCurrentDay.resting });
        //         generateDataDemo.activity.walking.data.stats.unshift({ date: moment(startDay).clone().add(-1, 'day').format('YYYY-MM-DD'), duration: statsOfCurrentDay.walking });
        //         generateDataDemo.activity.running.data.stats.unshift({ date: moment(startDay).clone().add(-1, 'day').format('YYYY-MM-DD'), duration: statsOfCurrentDay.running });

        //         generateDataDemo.activity.eating.data.stats.unshift({ date: moment(startDay).clone().add(-1, 'day').format('YYYY-MM-DD'), duration: statsOfCurrentDay.eating, episode: eatingNumber });
        //         generateDataDemo.activity.barking.data.stats.unshift({ date: moment(startDay).clone().add(-1, 'day').format('YYYY-MM-DD'), duration: statsOfCurrentDay.barking, episode: barkingNumber });

        //         generateDataDemo.HR.data.unshift({ date: currentDate, data: this.generateDataHealth(minHR, maxHR, 5) });
        //         generateDataDemo.RR.data.unshift({ date: currentDate, data: this.generateDataHealth(minRR, maxRR, 2) });

        //         currentDate = moment(currentDate).clone().add(1, 'day').format('YYYY-MM-DD')
        //     }
        //     console.log(JSON.stringify(generateDataDemo))
        // }
    },

    created() {
        this.$emit("update:layout", LayoutSummary);

        let parameters = this.$route.query;
        if (parameters["token"]) {
            let token = parameters["token"];
            this.setToken(token);
        }

        if (parameters["entity_id"]) {
            let entity_id = parameters["entity_id"];
            this.setEntityID(entity_id);
        }

        if (parameters["demo"]) {
            let demo = parameters["demo"];
            this.setUserDemo(demo);
        }

        // this.generateDogData("medium", "2022-11-10", "2023-01-10")
    },
}
</script>

<style lang="scss" scoped>
.data-resume {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    justify-content: center;
    align-items: center;

    &-text {
        font-family: var(--default-font);
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 17px;
        color: #3B3B3B;
    }
}

.event-resume {
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;

    &-image {
        width: 78px;
    }

    &-content {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
    }

    &-title {
        font-family: var(--default-font);
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        color: #3B3B3B;
    }

    &-datagroup {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1em;
    }

    &-data {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: var(--default-font);
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 17px;
        color: #3B3B3B;
    }
}

.research-link {
    display: flex;
    justify-content: center;
    font-family: var(--default-font);
    font-style: normal;
    font-weight: 700;
    font-size: var(--default-font-size-normal);
    line-height: 22px;
    text-decoration: underline;
    text-underline-position: under;
    text-decoration-color: #174396 !important;

    /* Dégradé/Bleu foncé */

    background: radial-gradient(135.75% 139.94% at 0% 50%, #1D55BE 0%, #174396 43.64%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    // text-fill-color: transparent;
}

/* TEST DARK MODE */

@media (prefers-color-scheme: dark) {
    .text-dark-mode {
        color: white;
        background-color: black;
    }
}

@media (prefers-color-scheme: light) {
    .text-dark-mode {
        color: black;
        background-color: white;
    }
}

/* END TEST DARK MODE */
</style>