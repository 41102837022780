<template>
    <button class="app-button">
        <div class="app-button-content">
            <slot />
        </div>
    </button>
</template>
  
<script>
export default {
    name: "AppButton",

    props: {
    },

    data() {
        return {};
    },
};
</script>
  
<style lang="scss">
.app-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 18px;
    gap: 8px;

    width: 100%;

    /* Color-primary/600 */

    background: var(--accent-color);
    border: none;
    border-radius: 18px;
}

.app-button-content {
    font-family: var(--default-font);
    font-style: normal;
    font-weight: 700;
    font-size: var(--default-font-size-normal);
    line-height: 140%;
    /* or 22px */

    display: flex;
    align-items: center;

    /* Color-neutral-shades/0 */

    color: #FFFFFF;
}

@media (prefers-color-scheme: dark) {
    .app-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 18px;
        gap: 8px;

        width: 100%;

        /* Color-primary/600 */

        background: var(--accent-color-dark);
        border: none;
        border-radius: 18px;
    }
}
</style>