import moment from 'moment';
import demoData from '../../../public/json/demo-data.json';
import { getHealth, getHealthValues, getPoincare, getHealthReportUrl } from "../../api/collar.api";

const state = {
    healthDemo: false,
    healthRangeType: 'week',
    todayHR: null,
    loadingTodayHR: false,
    todayRR: null,
    loadingTodayRR: false,
    lastWeekPoincare: [],
    lastWeekAfibRisk: null,
    loadingLastWeekPoincare: false,
    currentHealth: [],
    currentPoincare: [],
    loadingCurrentPoincare: false,
    healthReportUrl: null,
    afibRisk: null,
    currentHealthAverage: null,
}

const getters = {
    getHealthDemo(state) {
        return state.healthDemo;
    },
    getHealthRangeType(state) {
        return state.healthRangeType;
    },
    getTodayHR(state) {
        return state.todayHR;
    },
    getLoadingTodayHR(state) {
        return state.loadingTodayHR;
    },
    getTodayRR(state) {
        return state.todayRR;
    },
    getLoadingTodayRR(state) {
        return state.loadingTodayRR;
    },
    getLastWeekPoincare(state) {
        return state.lastWeekPoincare;
    },
    getLastWeekAfibRisk(state) {
        return state.lastWeekAfibRisk;
    },
    getLoadingLastWeekPoincare(state) {
        return state.loadingLastWeekPoincare;
    },
    getCurrentHealth(state) {
        return state.currentHealth;
    },
    getCurrentHealthAverage(state){
        return state.currentHealthAverage;
    },
    getCurrentPoincare(state) {
        return state.currentPoincare;
    },
    getCurrentAfibRisk(state) {
        console.log("getCurrentAfibRisk", state.afibRisk);
        return state.afibRisk;
    },
    getLoadingCurrentPoincare(state) {
        return state.loadingCurrentPoincare;
    },
    getHealthReportUrl(state) {
        return state.healthReportUrl;
    }
}

const actions = {
    async setHealthDemo({ commit }, data) {
        commit('SET_HEALTHDEMO', data)
    },
    async removeHealthDemo({ commit }) {
        commit('REMOVE_HEALTHDEMO');
    },

    async setHealthRangeType({ commit }, data) {
        commit('SET_HEALTHRANGETYPE', data);
    },

    async fetchTodayHR({ commit }, data) {
        if (state.healthDemo) {
            let entityDemoData = demoData[localStorage.getItem('entityId')] ? demoData[localStorage.getItem('entityId')] : demoData["default"];
            if (entityDemoData["HR"].data.filter((day) => day.date == data.start).length) {
                commit('SET_TODAYHR', entityDemoData["HR"].data.filter((day) => day.date == data.start)[0].data);
            } else {
                commit('REMOVE_TODAYHR');
            }
        } else {
            try {
                const responseHR = await getHealth(data);
                if (responseHR.data.length) {
                    commit('SET_TODAYHR', responseHR.data[0].data);
                } else {
                    commit('REMOVE_TODAYHR');
                }
            } catch (error) {
                console.log(error);
            }
        }
    },

    async fetchTodayRR({ commit, state }, data) {
        if (state.healthDemo) {
            let entityDemoData = demoData[localStorage.getItem('entityId')] ? demoData[localStorage.getItem('entityId')] : demoData["default"];
            if (entityDemoData["RR"].data.filter((day) => day.date == data.start).length) {
                commit('SET_TODAYRR', entityDemoData["RR"].data.filter((day) => day.date == data.start)[0].data);
            } else {
                commit('REMOVE_TODAYRR');
            }
        } else {
            try {
                const responseRR = await getHealth(data);
                if (responseRR.data.length) {
                    commit('SET_TODAYRR', responseRR.data[0].data);
                } else {
                    commit('REMOVE_TODAYRR');
                }
            } catch (error) {
                console.log(error);
            }
        }
    },

    async fetchHealth({ commit }, data) {
        try {
            let response;
            if ('history' in data) {
                response = await getHealthValues(data);
            } else {
                response = await getHealth(data);
            }
            let dataFormat = [];
            let index = 0;

            let currDate = moment(data.start).clone().startOf('day');
            let lastDate = moment(data.end).clone().startOf('day');

            if (lastDate.diff(currDate) == 0 && response.data.length) {
                const average= response.data[0].average;
                commit('SET_CURRENT_HEALTH_AVERAGE', average);

                dataFormat = [...response.data[0].data].map(v => [moment(data.start).clone().startOf('day').add(v[0], 'minutes').toDate().valueOf(), v[1]]);
                dataFormat.sort((a, b) => a[0] - b[0]);
                commit('SET_CURRENTHEALTH', dataFormat);
                return
            }

            while (lastDate.diff(currDate) >= 0) {
                if (response.data.length) {
                    if (response.data.length > index && lastDate.diff(moment(response.data[index].date).startOf('day')) == 0) {
                        dataFormat.unshift(response.data[index].data);
                        index += 1;
                    } else {
                        dataFormat.unshift(null);
                    }
                } else {
                    dataFormat.unshift(null);
                }
                lastDate.add(-1, 'days');
            }
            commit('SET_CURRENTHEALTH', dataFormat);
            commit('SET_CURRENT_HEALTH_AVERAGE', null);
        } catch (error) {
            console.log(error);
        }
    },

    async fetchLastWeekPoincare({ commit }, data) {
        if (state.healthDemo) {
            let poincares = demoData["default"]['poincare'];
            let poincareData = [];
            poincares.forEach(poincare => {
                if (moment(poincare.date).isBetween(data.start, data.end)) {
                    poincareData = poincare.data
                }
            });
            commit('SET_LASTWEEKPOINCARE', poincareData);
        } else {
            try {
                const response = await getPoincare(data);
                commit('SET_LASTWEEKPOINCARE', response.data.data);
                commit('SET_LASTWEEK_AFIB_RISK', response.data.afib_risk);
            } catch (error) {
                console.log(error);
            }
        }
    },

    async fetchPoincare({ commit, state }, data) {
        try {
            const response = state.healthDemo ? demoData["default"]['poincare'] : await getPoincare(data);
            if (state.healthDemo) {
                let poincareData = [];
                response.forEach(poincare => {
                    if (moment(poincare.date).isBetween(data.start, data.end)) {
                        poincareData = poincare.data
                    }
                });
                commit('SET_CURRENTPOINCARE', poincareData);
            } else {
                commit('SET_CURRENTPOINCARE', response.data.data);
                commit('SET_CURRENT_AFIB_RISK', response.data.afib_risk);
            }
        } catch (error) {
            console.log(error);
        }
    },

    async fetchHealthReportUrl({ commit }, data){
        const response = await getHealthReportUrl(data);
        console.log(response.data)
        commit('SET_HEALTH_REPORT_URL', response.data.url);
    }
}

const mutations = {
    SET_HEALTHDEMO(state, data) {
        if (state.healthDemo !== data) {
            state.healthDemo = data;
            localStorage.setItem('healthDemo', data);
        }
    },
    REMOVE_HEALTHDEMO(state) {
        state.healthDemo = null;
        localStorage.removeItem('healthDemo');
    },

    SET_HEALTHRANGETYPE(state, data) {
        if (state.healthRangeType !== data) {
            state.healthRangeType = data;
            localStorage.setItem('healthRangeType', data);
        }
    },
    REMOVE_HEALTHRANGETYPE(state) {
        state.healthRangeType = null;
        localStorage.removeItem('healthRangeType');
    },

    SET_TODAYHR(state, data) {
        if (state.todayHR != data) {
            state.loadingTodayHR = true;
            setTimeout(() => {
                state.loadingTodayHR = false;
                state.todayHR = data;
                localStorage.setItem('todayHR', data);
            }, 2000);
        }
    },
    REMOVE_TODAYHR(state) {
        if (state.todayHR != null) {
            state.loadingTodayHR = true;
            setTimeout(() => {
                state.loadingTodayHR = false;
                state.todayHR = null;
                localStorage.removeItem('todayHR');
            }, 2000);
        }
    },

    SET_TODAYRR(state, data) {
        if (state.todayRR != data) {
            state.loadingTodayRR = true;
            setTimeout(() => {
                state.loadingTodayRR = false;
                state.todayRR = data;
                localStorage.setItem('todayRR', data);
            }, 2000);
        }
    },
    REMOVE_TODAYRR(state) {
        if (state.todayRR != null) {
            state.loadingTodayRR = true;
            setTimeout(() => {
                state.loadingTodayRR = false;
                state.todayRR = null;
                localStorage.removeItem('todayRR');
            }, 2000);
        }
    },

    SET_LASTWEEKPOINCARE(state, data) {
        if (JSON.stringify(state.lastWeekPoincare) != JSON.stringify(data)) {
            state.loadingLastWeekPoincare = true;
            setTimeout(() => {
                state.loadingLastWeekPoincare = false;
                state.lastWeekPoincare = data;
                localStorage.setItem('lastWeekPoincare', JSON.stringify(data));
            }, 2000);
        }
    },
    REMOVE_LASTWEEKPOINCARE(state) {
        state.lastWeekPoincare = [];
        localStorage.removeItem('lastWeekPoincare');
    },

    SET_LASTWEEK_AFIB_RISK(state, value) {
        state.lastWeekAfibRisk = value;
    },

    SET_CURRENTHEALTH(state, data) {
        if (JSON.stringify(state.currentHealth) != JSON.stringify(data)) {
            state.currentHealth = data;
            localStorage.setItem('currentHealth', JSON.stringify(data));
        }
    },
    REMOVE_CURRENTHEALTH(state) {
        state.currentHealth = [];
        localStorage.removeItem('currentHealth');
    },
    SET_CURRENT_HEALTH_AVERAGE(state, value){
        state.currentHealthAverage = value
    },

    SET_CURRENTPOINCARE(state, data) {
        if (JSON.stringify(state.currentPoincare) != JSON.stringify(data)) {
            state.currentPoincare = data;
            localStorage.setItem('currentPoincare', JSON.stringify(data));
        }
    },
    REMOVE_CURRENTPOINCARE(state) {
        state.currentPoincare = [];
        localStorage.removeItem('currentPoincare');
    },
    REMOVE_CURRENT_AFIB_RISK(state) {
        state.afibRisk = null;
    },

    SET_CURRENT_AFIB_RISK(state, value) {
        state.afibRisk = value;
    },
    SET_HEALTH_REPORT_URL(state, data){
        state.healthReportUrl = data;
    },
    REMOVE_HEALTH_REPORT_URL(){
        state.healthReportUrl = null;
    },

    INITIALISE_HEALTH_STORE(state) {
        if (localStorage.getItem('healthRangeType')) {
            state.healthRangeType = localStorage.getItem('healthRangeType');
        }
        if (localStorage.getItem('todayHR')) {
            state.todayHR = localStorage.getItem('todayHR');
        }
        if (localStorage.getItem('todayRR')) {
            state.todayRR = localStorage.getItem('todayRR');
        }
        if (localStorage.getItem('lastWeekPoincare')) {
            state.lastWeekPoincare = JSON.parse(localStorage.getItem('lastWeekPoincare'));
        }
        if (localStorage.getItem('currentHealth')) {
            state.currentHealth = JSON.parse(localStorage.getItem('currentHealth'));
        }
        if (localStorage.getItem('currentPoincare')) {
            state.currentPoincare = JSON.parse(localStorage.getItem('currentPoincare'));
        }
    },
    
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}