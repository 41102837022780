<template>
    <section class="summary-section">
        <h3>
            {{ $filters.capitalize($t('global.sleep')) }}
        </h3>
        <base-card :cardLink="null">
            <card-content :iconWrapper="{ iconType: 'icon-sleeping' }">
                <template v-if="this.isToday" v-slot:header>
                    {{ $filters.capitalize($t('activity.last_night')) }}
                </template>
                <template v-else v-slot:header>
                    {{ $filters.capitalize($t('activity.night_before')) }}
                </template>
                <template v-slot:content>
                    <section v-if="getCurrentSleep">
                        <div class="sleep-layout" v-if="this.getCurrentSleep.score">
                            <h4>
                                {{ $filters.capitalize($t('global.sleep_score')) }}
                            </h4>
                            <div class="sleep-layout">
                                <h3 style="color: var(--sleep-color);"> {{ this.getCurrentSleep.score }}</h3>
                                <h4>/100</h4>
                            </div>
                        </div>
                        <div v-if="this.getCurrentSleep.series">
                            <graph-sleep />
                        </div>
                        <div class="sleep-layout" style="justify-content: space-evenly;">
                            <div v-if="this.getCurrentSleep.totalDuration" style="text-align: center;">
                                <p>
                                    {{ $filters.capitalize($t('global.total')) }}
                                </p>
                                <span>
                                    <data-formater :loading=false :type="'exercise'" :duration="this.getCurrentSleep.totalDuration"
                                        :episodes="0" />
                                </span>
                            </div>
                            <div v-if="this.getCurrentSleep.interruptions || this.getCurrentSleep.interruptions == 0"
                                style="text-align: center;">
                                <p>
                                    {{ $filters.capitalize($t('global.interruptions')) }}
                                </p>
                                <span>
                                    <data-formater :loading=false :type=null :duration=0
                                        :episodes="this.getCurrentSleep.interruptions" />
                                </span>
                            </div>
                        </div>
                    </section>
                    <h2 class="no-data-rate-small" v-else>
                        {{ $filters.capitalize($t('global.no_data_yet')) }}
                    </h2>
                </template>
            </card-content>
        </base-card>
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import moment from 'moment';

import BaseCard from '@/components/ui/BaseCard.vue';
import CardContent from '@/components/ui/CardContent.vue';
import DataFormater from '@/components/ui/DataFormater.vue';
import GraphSleep from '@/components/graphics/GraphSleep.vue';

export default {
    name: "SleepCard",

    components: {
        BaseCard,
        CardContent,
        DataFormater,
        GraphSleep,
    },

    methods: {

        ...mapActions("activity", ["fetchCurrentSleep"]),

        async updateSleep() {
            const startDate = moment(this.getCurrentDate).clone().startOf('day').format('YYYY-MM-DD');
            /* const endDate = moment(this.getCurrentDate).clone().startOf('day').format('YYYY-MM-DD') */
            const entityId = localStorage.getItem('entityId');
            await this.fetchCurrentSleep({
                start: startDate,
                end: startDate,
                entity_id: entityId
            });
        },
    },

    computed: {
        ...mapGetters("general", ["getCurrentDate"]),
        ...mapGetters("activity", ["getCurrentSleep"]),

        isToday() {
            let now = moment();
            return now.isSame(this.getCurrentDate, 'day')
        },
    },

    watch: {
        getCurrentDate() {
            this.updateSleep();
        }
    },

    async mounted() {
        this.updateSleep();
    },
}
</script>

<style lang="scss" scoped>
.sleep-layout {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    align-items: center;
    justify-content: space-between;
}
</style>