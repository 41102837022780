<template>
    <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.8901 8.54931C15.807 8.3844 15.6799 8.24571 15.5228 8.14863C15.3657 8.05155 15.1848 7.99987 15.0001 7.99931H10.0001V1.99931C10.0108 1.77999 9.94909 1.56324 9.82438 1.38251C9.69968 1.20178 9.51895 1.06712 9.31011 0.999311C9.10933 0.933254 8.89279 0.932512 8.69157 0.99719C8.49035 1.06187 8.31479 1.18864 8.19011 1.35931L0.190106 12.3593C0.0898714 12.5042 0.0296843 12.6729 0.0156362 12.8486C0.00158808 13.0242 0.0341775 13.2003 0.110106 13.3593C0.180028 13.5411 0.301494 13.6984 0.459595 13.8121C0.617697 13.9258 0.805553 13.9909 1.00011 13.9993H6.00011V19.9993C6.00026 20.2102 6.06708 20.4156 6.191 20.5862C6.31493 20.7569 6.48962 20.8839 6.6901 20.9493C6.79058 20.9805 6.89494 20.9973 7.00011 20.9993C7.15789 20.9997 7.31353 20.9628 7.45431 20.8915C7.59509 20.8203 7.71701 20.7167 7.81011 20.5893L15.8101 9.58931C15.9178 9.44011 15.9823 9.26406 15.9964 9.08057C16.0106 8.89707 15.9738 8.71324 15.8901 8.54931Z"
            :fill="color" />
    </svg>
</template>
  
<script>
export default {
    name: "IconLightning",

    props: {
        color: {
            type: String,
            requied: true,
        },
    },

    data() {
        return {};
    },
};
</script>
  
<style lang="scss">

</style>