<template>
  <header class="collar-header">
    <div v-if="modalIsOpen" class="layout-modal">
      <div class="modal">
        <img v-if="isEntityCat()" class="image-modal" src="@/assets/images/collar/blue-cat.png" />
        <img v-else class="image-modal" src="@/assets/images/collar/blue-dog.png" />
        <div>
          <h3 class="modal-title">
            {{ $t('health.summary.popup_infos_title') }}
          </h3>
          <p v-if="isEntityCat()" class="modal-description">
            {{ $t('health.summary.popup_infos_description_cat_fmt', { productName: this.$t(this.getProductNameKey) }) }}
          </p>
          <p v-else class="modal-description">
            {{ $t('health.summary.popup_infos_description_dog_fmt', { productName: this.$t(this.getProductNameKey) }) }}
          </p>
        </div>
        <div class="modal-buttongroup">
          <app-button @click.enter="modalIsOpen = false">{{ $t('global.popup_close') }}</app-button>
          <!-- <app-link>{{ $t('global.popup_faq') }}</app-link> -->
        </div>
      </div>
    </div>
    <nav class="collar-header-nav">
      <div class="img-halo">
        <img class="pet-profil" :src="getImageProfil" />
      </div>
      <div class="infos">
        <h1 class="infos-title">{{ getEntityName }}</h1>
        <p v-if="isLastData" class="infos-subtitle"> {{ $filters.capitalize($t($route.name == "Health Summary" ?
      "health.summary.last_data" : "activity.summary.last_data")) }}
          <span class="infos-last-measurement">
            {{ $filters.capitalize(recentMeasurementsDate) }}

            <icon-information @click="modalIsOpen = true" />
          </span>

        </p>
        <p v-else>{{ $filters.capitalize($t($route.name == "Health Summary" ? 'health.summary.waiting_data' :
      'activity.summary.waiting_data')) }}</p>
      </div>
    </nav>
  </header>
</template>

<script>
import moment from 'moment';

import { mapGetters, mapActions } from 'vuex';

import IconInformation from '../svg/IconInformation.vue';
import AppButton from '../ui/AppButton.vue';
// import AppLink from '../ui/AppLink.vue';

export default {
  name: "TheNavBarHeader",

  components: {
    IconInformation,
    AppButton,
    // AppLink,
  },

  data() {
    return {
      modalIsOpen: false
    };
  },

  methods: {
    ...mapActions("user", ["setToken", "setEntityID", "fetchEntity", "fetchImageProfil"]),

    isEntityCat() {
      return this.getEntityType == "cat"
    },
  },

  computed: {
    ...mapGetters("user", ["getImageProfil", "getEntityName", "getLastHealthDataDate", "getLastActivityDataDate", "isDeviceLWT6", "getEntityType", "getProductNameKey"]),

    isLastData() {
      if (this.$route.name == "Health Summary") {
        return this.getLastHealthDataDate ? true : false
      } else {
        return this.getLastActivityDataDate ? true : false
      }
    },

    recentMeasurementsDate() {
      let lastDataDate = "";

      if (this.$route.name == "Health Summary") {
        lastDataDate = this.getLastHealthDataDate;
      } else if (this.$route.name == "Activity Summary" || this.$route.name == "Dashboard") {
        lastDataDate = this.getLastActivityDataDate;
      }

      // if (lastDataDate.length > 11) {
      let now = moment();
      let last = moment(lastDataDate);

      if (now.isSame(last, 'day')) {
        return last.format('LT')
      } else if (moment().add(-1, 'days').isSame(last, 'day')) {
        return last.format('LT') + ' ' + this.$t('global.yesterday')
      } else {
        return this.$t('global.days_ago', { x: now.diff(last, 'days') })
      }
      // }
      // let today = moment().format('DD MMMM');
      // let lastDataDateFormat = moment(lastDataDate).format('DD MMMM');

      // return today == lastDataDateFormat ? this.$t('global.today') : lastDataDateFormat;
    }
  },

  beforeCreate() {
    let parameters = this.$route.query;
    if (parameters["token"]) {
      let token = parameters["token"];
      localStorage.setItem('token', token);
    }

    if (parameters["entity_id"]) {
      let entity_id = parameters["entity_id"];
      localStorage.setItem('entityId', entity_id);
    }
  },

  async mounted() {
    await this.fetchEntity();
    await this.fetchImageProfil();
  }
};
</script>

<style lang="scss" scoped>
.layout-modal {
  position: absolute;
  overflow: scroll;
  display: flex;
  height: 100vh;
  width: 100vw;
  background: rgba(27, 28, 30, 0.8);
  z-index: 100;
}

.modal {
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 24px;
  margin: 1em;
  width: 100vw;
  height: fit-content;
  /* background: #FFFFFF; */
  border-radius: 18px;
  z-index: 1000;
  background: var(--background-color);
}

.image-modal {
  width: 90%;
  align-self: center;
}

.modal-title {
  font-family: var(--default-font);
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  /* or 34px */

  text-align: center;
}

.modal-description {
  font-family: var(--default-font);
  font-style: normal;
  font-weight: 500;
  font-size: var(--default-font-size-normal);
  line-height: 145%;
  /* or 23px */

  text-align: center;
}

.modal-buttongroup {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.collar-header {
  height: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;
  border-radius: 0 0 0.5em 0.5em;
  background-color: #F2F8FD;
  user-select: none;

  &-nav {
    display: flex;
    justify-content: flex-start;
    gap: 0.75em;
    padding: 2em 1.5em 0.75em 1.5em;
    align-items: center;
  }

  .infos {
    display: flex;
    flex-direction: column;

    &-title {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      overflow-wrap: anywhere;
    }

    &-subtitle {
      display: flex;
      flex-direction: column;
      font-style: normal;
      font-weight: 400;
      font-size: var(--default-font-size-small);
    }

    &-last-measurement {
      display: flex;
      gap: 0.5em;
      align-items: center;
      font-family: var(--default-font);
      font-style: normal;
      font-weight: 700;
      font-size: var(--default-font-size-small);

      color: var(--accent-color);
    }
  }

  .img-halo {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pet-profil {
    width: 3.5em;
    height: 3.5em;
    border-radius: 5em;
    border: 4px solid #FFFFFF;
  }
}

@media (prefers-color-scheme: dark) {
  .modal {
    background: var(--background-dark-color);
  }

  .collar-header {
    background-color: var(--background-dark-color);

    .infos-last-measurement {
      color: var(--accent-color-dark);
    }

    .pet-profil {
      border: 4px solid #1F2038;
    }
  }

  .infos-title {
    color: #FAFAFA;
  }
}
</style>