<template>
    <div class="graph-container">
        <template v-if="this.series[0].data.length">
            <apexchart :options="chartOptions" :series="series" :height="'100%'"></apexchart>
        </template>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { useCssVar } from '@vueuse/core'
import { ref } from 'vue'

import moment from "moment";

export default {
    name: "GraphExerciseLevel",

    data() {
        const defaultFont = useCssVar('--default-font', ref(null));
        return {
          series: [{
            data: []
          }],
          tooltip: {
            enabled: true,
            x: {show: false,},
            y: {
              title: {
                formatter: function () {
                  return ''
                },
              },
              formatter: function (val) {
                if (val == null) {
                  return val
                }
                if (val < 60) {
                  return moment().startOf('days').add(val, 'minutes').format('m') + 'min'
                } else {
                  return val % 60 ? (val - val % 60) / 60 + 'h' + val % 60 : val / 60 + 'h'
                }
              }
            },
            marker: {
              show: false,
            }
          },
            chartOptions: {
                // theme: {
                //     mode: 'ligth',
                //     monochrome: {
                //         enabled: true,
                //     },
                // },
                chart: {
                    type: 'bar',
                    stacked: true,
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    },
                    animations: {
                        enabled: false,
                        easing: 'easeinout',
                        speed: 600,
                        animateGradually: {
                            enabled: true,
                            delay: 150
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350
                        }
                    }
                },
                dataLabels: {
                    enabled: false,
                },
                tooltip: {
                    enabled: true,
                },
                legend: {
                    show: false,
                },
                markers: {
                    size: 0,
                    shape: "circle",
                    showNullDataPoints: false,
                },
                fill: {
                    type: 'gradient',
                    colors: ['#E0F0FF', '#B5D7F8'],
                    gradient: {
                        opacityFrom: 0.8,
                        opacityTo: 1,
                    }
                },
                stroke: {
                    show: false,
                    curve: 'smooth',
                    lineCap: 'square',
                    colors: ['#E0F0FF', '#1D60E0'],
                    width: [0, 3]
                },
                grid: {
                    show: true,
                    borderColor: '#D9D9D9',
                    strokeDashArray: 3,
                    position: 'back',
                    xaxis: {
                        lines: {
                            show: true
                        }
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                    row: {
                        colors: undefined,
                        opacity: 1
                    },
                    column: {
                        colors: undefined,
                        opacity: 1
                    },
                    padding: {
                        top: 0,
                        right: -5,
                        bottom: -10,
                        left: -15
                    },
                },
                xaxis: {
                    type: 'numeric',
                    tickAmount: 4,
                    min: moment().startOf('day').valueOf(),
                    max: moment().add('1', 'days').startOf('day').valueOf(),
                    labels: {
                        style: {
                            colors: '#8C8C8C',
                            fontSize: '10px',
                            fontFamily: defaultFont,
                            fontWeight: 400,
                            cssClass: 'apexcharts-xaxis-activity-label',
                        },
                        formatter: function (val) {
                            let locale = navigator.language.substring(0, 2);
                            //return moment(val).format("hha");
                            let _str = new Date(val).toLocaleString(locale, {  hour: '2-digit' }).split(' ').join('');
                            return _str.toLowerCase();
                        },
                        offsetY: -5,
                        offsetX: 1,
                    },
                    axisTicks: {
                        show: false,
                    },
                    axisBorder: {
                        show: true,
                        offsetY: 10
                    },
                },
                yaxis: {
                    show: false,
                    min: 0,
                    max: 100,
                    formatter: function (val) {
                        if (val == 0) {
                            return 0
                        } else if (val < 60) {
                            return moment().startOf('days').add(val, 'minutes').format('mm')
                        } else {
                            return moment().startOf('days').add(val, 'minutes').format(val % 60 ? 'h[h]mm' : 'h[h]')
                        }
                    },
                }
            },
        };
    },

    methods: {
        ...mapActions("activity", ["fetchExerciseLevel"]),

        arrayMaximum(array) {
            var flatArray = []
            let index = 0;
            array[0].data.forEach(elem => {
                if (this.getActivityRangeType == 'day') {
                    let sum = (elem[1] + array[1].data[index][1]) ? elem[1] + array[1].data[index][1] : 0
                    flatArray.push(sum)
                    index += 1
                } else {
                    let sum = (elem + array[1].data[index]) ? elem + array[1].data[index] : 0
                    flatArray.push(sum)
                    index += 1
                }
            });
            if (Array.isArray(array[0].data) && array[0].data.length) {
                return Math.max(...flatArray) ? Math.ceil(Math.max(...flatArray) / 10) * 10 : 30
            } else {
                return 30;
            }
        },

        enumerateHours(startHour, endHour) {
            var hours = [];

            startHour = moment(startHour).clone().add(1, 'hours').toDate().valueOf();

            while (startHour <= endHour) {
                hours.push(startHour);
                startHour = moment(startHour).clone().add(1, 'hours').toDate().valueOf();
            }
            return hours;
        },

        enumerateDaysBetweenDates(startDate, endDate) {
            var dates = [];

            while (startDate.diff(endDate) <= 0) {
                dates.push(startDate.clone().toDate().valueOf());
                startDate = startDate.clone().add(1, 'days');
            }

            return dates;
        },

        async changevalue(startDate, endDate) {
            await this.fetchExerciseLevel({ start: startDate, end: endDate, entity_id: localStorage.getItem('entityId') });

            if (this.getActivityRangeType == 'day') {
                this.series = this.getCurrentExerciseLevel;
            } else {
                this.series = this.getCurrentExerciseLevel;
            }

        },

        async updateTheme() {
            const defaultFont = useCssVar('--default-font', ref(null));
            if (this.getCurrentDate) {
                if (this.getActivityRangeType == 'day') {
                    await this.changevalue(moment(this.getCurrentDate).clone().startOf('day').format('YYYY-MM-DD'), moment(this.getCurrentDate).clone().startOf('day').format('YYYY-MM-DD'));

                    this.chartOptions = {
                        chart: {
                            type: 'area',
                            stacked: true,
                            zoom: {
                                enabled: false
                            },
                            toolbar: {
                                show: false
                            },
                            animations: {
                                enabled: false,
                                easing: 'easeinout',
                                speed: 600,
                                animateGradually: {
                                    enabled: true,
                                    delay: 150
                                },
                                dynamicAnimation: {
                                    enabled: true,
                                    speed: 350
                                }
                            }
                        },
                        fill: {
                            type: 'solid',
                            colors: ['#E0F0FF', '#1D60E0'],
                        },
                        stroke: {
                            show: true,
                            curve: 'smooth',
                            lineCap: 'round',
                            colors: ['#1D60E0', '#1D60E0'],
                            width: [2, 2]
                        },
                        grid: {
                            show: true,
                            borderColor: '#D9D9D9',
                            strokeDashArray: 3,
                            position: 'back',
                            xaxis: {
                                lines: {
                                    show: true
                                }
                            },
                            yaxis: {
                                lines: {
                                    show: false
                                }
                            },
                            row: {
                                colors: undefined,
                                opacity: 1
                            },
                            column: {
                                colors: undefined,
                                opacity: 1
                            },
                            padding: {
                                top: 0,
                                right: 5,
                                bottom: -10,
                                left: 0
                            },
                        },
                        xaxis: {
                            type: 'numeric',
                            tickAmount: 4,
                            min: moment(this.getCurrentDate).clone().startOf('day').valueOf(),
                            max: moment(this.getCurrentDate).clone().add('1', 'days').startOf('day').valueOf(),
                            categories: this.enumerateHours(moment(this.getCurrentDate).clone().startOf('day').valueOf(), moment(this.getCurrentDate).clone().add(1, 'days').startOf('day').valueOf()),
                            labels: {
                                style: {
                                    colors: '#8C8C8C',
                                    fontSize: '10px',
                                    fontFamily: defaultFont,
                                    fontWeight: 400,
                                    cssClass: 'apexcharts-xaxis-activity-label',
                                },
                              formatter: function (val) {

                                let locale = navigator.language.substring(0, 2);
                                //return moment(val).format("hha");
                                let _str = new Date(val).toLocaleString(locale, {
                                  hour: '2-digit'
                                }).split(' ').join('');
                                return _str.toLowerCase();

                              },
                                offsetY: -5,
                                offSetX: 0,
                            },
                            axisTicks: {
                                show: false,
                            },
                            axisBorder: {
                                show: false,
                                offsetY: 0
                            },
                        },
                      annotations: {
                        yaxis: [],
                      },
                      yaxis: {
                        type: 'numeric',
                        tickAmount: 2,
                        opposite: true,
                        min: 0,
                        max: this.arrayMaximum(this.getCurrentExerciseLevel),
                        labels: {
                          style: {
                            colors: '#434343',
                            fontSize: '10px',
                            fontFamily: defaultFont,
                            fontWeight: 400,
                          },
                          formatter: function (val) {
                            if (val == 0) {
                              return 0
                            } else if (val < 60) {
                              return moment().startOf('days').add(val, 'minutes').format('mm')
                            } else {
                              return moment().startOf('days').add(val, 'minutes').format(val % 60 ? 'h[h]mm' : 'h[h]')
                            }
                          },
                          offsetY: -5,
                        },
                      },
                      tooltip: this.tooltip,
                    }
                } else if (this.getActivityRangeType == 'week') {
                    await this.changevalue(moment(this.getCurrentDate).clone().startOf('isoWeek').format('YYYY-MM-DD'), moment(this.getCurrentDate).clone().endOf('isoWeek').format('YYYY-MM-DD'))

                    this.chartOptions = {
                        chart: {
                            type: 'bar',
                            stacked: true,
                            // colors: ["#1D60E0", "#418FDE"],
                            zoom: {
                                enabled: false
                            },
                            toolbar: {
                                show: false
                            },
                            animations: {
                                enabled: false,
                                easing: 'easeinout',
                                speed: 600,
                                animateGradually: {
                                    enabled: true,
                                    delay: 150
                                },
                                dynamicAnimation: {
                                    enabled: true,
                                    speed: 350
                                }
                            }
                        },
                        fill: {
                            type: 'solid',
                            colors: ['#418FDE', '#1D60E0'],
                        },
                        stroke: {
                            show: false,
                            width: 0,
                        },
                        grid: {
                            show: true,
                            borderColor: '#D9D9D9',
                            strokeDashArray: 3,
                            position: 'back',
                            xaxis: {
                                lines: {
                                    show: true
                                }
                            },
                            yaxis: {
                                lines: {
                                    show: false
                                }
                            },
                            row: {
                                colors: undefined,
                                opacity: 1
                            },
                            column: {
                                colors: undefined,
                                opacity: 1
                            },
                            padding: {
                                top: 0,
                                right: 5,
                                bottom: -10,
                                left: 0
                            },
                        },
                        annotations: {
                            yaxis: [],
                        },
                        plotOptions: {
                            bar: {
                                borderRadius: 3,
                                borderRadiusApplication: 'end',
                                borderRadiusWhenStacked: 'last',
                            },
                        },
                        xaxis: {
                            type: 'category',
                            tickAmount: 6,
                            min: moment(this.getCurrentDate).clone().startOf('isoWeek').toDate().valueOf(),
                            max: moment(this.getCurrentDate).clone().endOf('isoWeek').toDate().valueOf(),
                            categories: this.enumerateDaysBetweenDates(moment(this.getCurrentDate).clone().startOf('isoWeek'), moment(this.getCurrentDate).clone().endOf('isoWeek')),
                            labels: {
                                style: {
                                    colors: '#8C8C8C',
                                    fontSize: '10px',
                                    fontFamily: defaultFont,
                                    fontWeight: 400,
                                    cssClass: 'apexcharts-xaxis-label',
                                },
                                formatter: function (value) {
                                    return moment(value).clone().format('ddd')
                                },
                                offsetY: -5,
                                offSetX: 0,
                            },
                            axisTicks: {
                                show: false,
                            },
                            axisBorder: {
                                show: false,
                                offsetY: 0
                            },
                        },
                      yaxis: {
                        type: 'numeric',
                        tickAmount: 2,
                        opposite: true,
                        min: 0,
                        max: this.arrayMaximum(this.getCurrentExerciseLevel),
                        labels: {
                          style: {
                            colors: '#434343',
                            fontSize: '10px',
                            fontFamily: defaultFont,
                            fontWeight: 400,
                          },
                          formatter: function (val) {
                            if (val == 0) {
                              return 0
                            } else if (val < 60) {
                              return moment().startOf('days').add(val, 'minutes').format('mm')
                            } else {
                              return val % 60 ? (val - val % 60) / 60 + 'h' + val % 60 : val / 60 + 'h'
                            }
                          },
                          offsetY: -5,
                        },
                      },
                      tooltip: this.tooltip,
                    }
                } else if (this.getActivityRangeType == 'month') {
                    await this.changevalue(moment(this.getCurrentDate).clone().startOf('month').format('YYYY-MM-DD'), moment(this.getCurrentDate).clone().endOf('month').format('YYYY-MM-DD'))

                    this.chartOptions = {
                        chart: {
                            type: 'bar',
                            stacked: true,
                            zoom: {
                                enabled: false
                            },
                            toolbar: {
                                show: false
                            },
                            animations: {
                                enabled: false,
                                easing: 'easeinout',
                                speed: 600,
                                animateGradually: {
                                    enabled: true,
                                    delay: 150
                                },
                                dynamicAnimation: {
                                    enabled: true,
                                    speed: 350
                                }
                            }
                        },
                        legend: {
                            show: false,
                        },
                        fill: {
                            type: 'solid',
                            colors: ['#418FDE', '#1D60E0'],
                        },
                        stroke: {
                            show: false,
                            width: 0,
                        },
                        grid: {
                            show: true,
                            borderColor: '#D9D9D9',
                            strokeDashArray: 3,
                            position: 'back',
                            xaxis: {
                                lines: {
                                    show: true
                                }
                            },
                            yaxis: {
                                lines: {
                                    show: false
                                }
                            },
                            row: {
                                colors: undefined,
                                opacity: 1
                            },
                            column: {
                                colors: undefined,
                                opacity: 1
                            },
                            padding: {
                                top: 0,
                                right: 0,
                                bottom: -10,
                                left: -15
                            },
                        },
                        annotations: {
                            yaxis: [],
                        },
                        xaxis: {
                            type: 'numeric',
                            tickAmount: 5,
                            min: moment(this.getCurrentDate).clone().startOf('month').toDate().valueOf(),
                            max: moment(this.getCurrentDate).clone().endOf('month').startOf('day').toDate().valueOf(),
                            categories: this.enumerateDaysBetweenDates(moment(this.getCurrentDate).clone().startOf('month'), moment(this.getCurrentDate).clone().endOf('month')),
                            labels: {
                                style: {
                                    colors: '#8C8C8C',
                                    fontSize: '10px',
                                    fontFamily: defaultFont,
                                    fontWeight: 400,
                                    cssClass: 'apexcharts-xaxis-activity-label',
                                },
                                formatter: function (value) {
                                    return moment(value).clone().format('D')
                                },
                                offsetY: -5,
                            },
                            axisTicks: {
                                show: false,
                            },
                            axisBorder: {
                                show: false,
                            },
                        },
                        yaxis: {
                            type: 'numeric',
                            tickAmount: 2,
                            opposite: true,
                            min: 0,
                            max: this.arrayMaximum(this.getCurrentExerciseLevel),
                            labels: {
                                style: {
                                    colors: '#434343',
                                    fontSize: '10px',
                                    fontFamily: defaultFont,
                                    fontWeight: 400,
                                },
                                formatter: function (val) {
                                    if (val == 0) {
                                        return 0
                                    } else if (val < 60) {
                                        return moment().startOf('days').add(val, 'minutes').format('mm')
                                    } else {
                                        return val % 60 ? (val - val % 60) / 60 + 'h' + val % 60 : val / 60 + 'h'
                                    }
                                },
                                offsetY: -5,
                            },
                        },
                      tooltip: this.tooltip,
                    }
                }
            }
        }
    },

    computed: {
        ...mapGetters("general", ["getCurrentDate"]),
        ...mapGetters("activity", ["getActivityRangeType", "getCurrentExerciseLevel"]),
    },

    watch: {
        getActivityRangeType() {
            this.updateTheme()
        },
        getCurrentDate() {
            this.updateTheme()
        }
    },

    mounted() {
        this.updateTheme();
    }
}
</script>

<style lang="scss" scoped>
.graph-container {
    width: 100%;
    aspect-ratio: 16/10;
}
</style>