<template>
    <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.9458 5.8998H19.0256L18.2587 7.61759C17.9519 8.30266 17.2873 8.75256 16.4795 8.83436H16.4284H16.3773C15.5695 8.83436 14.8333 8.34356 14.5061 7.59714L14.2709 7.04499L12.9622 11.5235C12.7065 12.3926 11.8988 12.9959 10.9683 12.9959C10.048 12.9857 9.2505 12.3517 9.01533 11.4622L7.92126 7.07566C7.92126 7.07566 7.74743 6.50307 8.28935 6.39059C8.83128 6.26789 8.9233 6.85072 8.9233 6.85072L10.0071 11.2168C10.1196 11.6667 10.5184 11.9734 10.9785 11.9836C11.4284 11.9836 11.8476 11.6871 11.9703 11.2474L14.0767 4.03886L15.4366 7.18814C15.6002 7.55624 15.9683 7.80164 16.3671 7.80164C16.7658 7.76074 17.1442 7.56646 17.3078 7.18814L18.3405 4.85685H19.7106C19.7106 4.85685 19.7515 4.84663 19.7822 4.84663C20.048 4.82618 20.2832 4.7137 20.457 4.52965C20.4775 4.5092 20.4877 4.48875 20.4979 4.45808C20.5593 4.3865 20.6206 4.3047 20.6513 4.21268C20.7024 4.08998 20.7331 3.96728 20.7331 3.82413C20.7331 3.26176 20.273 2.80164 19.7106 2.80164H17.6759C17.3691 2.80164 17.093 2.93456 16.8988 3.15951C16.8374 3.23108 16.7863 3.32311 16.7454 3.41513L16.3875 4.2229L14.9969 0.99182L14.8333 0.613497C14.7924 0.521472 14.7311 0.429448 14.6697 0.357873C14.639 0.316973 14.5879 0.296524 14.5572 0.255624C14.5163 0.224949 14.4857 0.184049 14.4448 0.163599C14.3936 0.132924 14.3323 0.112474 14.2709 0.0817996C14.2403 0.0715746 14.2096 0.0511247 14.1789 0.0408998C14.1176 0.0204499 14.0665 0.0204499 14.0051 0.0204499C13.954 0.0204499 13.9029 0 13.8517 0C13.4223 0.0306748 13.0542 0.316973 12.9315 0.736196L11.0705 7.10634L10.5082 4.93865C9.9867 2.74029 7.75766 1.79959 6.33639 1.87117C3.228 1.98364 0.978516 4.47853 0.978516 7.81186C0.978516 12.2086 6.91921 15.8896 9.15847 17.2699C9.42432 17.4335 10.4877 18.1902 11.1114 18.1902C11.6943 18.1902 12.7065 17.4744 12.8804 17.3517C14.8844 15.9714 20.9785 11.7894 20.9785 6.93252C20.9785 6.37014 20.5184 5.8998 19.956 5.8998H19.9458Z"
            :fill="color" />
    </svg>
</template>
  
<script>
export default {
    name: "IconHeartSignature",

    props: {
        color: {
            type: String,
            requied: true,
        },
    },

    data() {
        return {};
    },
};
</script>
  
<style lang="scss">

</style>