<template>
  <svg height="24px" width="24px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink"
       viewBox="0 0 94.684 94.684" xml:space="preserve">
<g>
	<path d="M70.62,48.185l-11.01-7.007c-0.707-0.45-1.539-1.601-1.745-2.414l-3.049-12.058c-0.002-0.007-0.005-0.014-0.007-0.021
		c-0.251-1.402-1.473-2.471-2.947-2.471h-14.83c-0.678,0-1.297,0.234-1.799,0.615c-0.104,0.058-0.211,0.114-0.305,0.18
		l-10.552,7.518c-1.205,0.858-1.935,2.67-1.663,4.125l3.268,17.458c0.28,1.499,1.7,2.673,3.233,2.673l1.678-0.09
		c0.808-0.046,1.55-0.422,2.038-1.034c0.487-0.611,0.69-1.419,0.555-2.216l-2.28-13.465c-0.12-0.707,0.306-1.726,0.892-2.138
		l1.936-1.362v19.745c-0.024,0.067-0.059,0.128-0.081,0.196l-9.783,30.638c-0.407,1.276-0.283,2.619,0.35,3.781
		s1.694,1.994,2.987,2.343l0.654,0.177c0.428,0.116,0.872,0.175,1.318,0.175c2.251,0,4.296-1.481,4.974-3.603l9.288-29.087h0.771
		l1.694,8.421c0.269,1.334,0.521,3.612,0.552,4.973l0.382,17.203c0.041,1.85,1.452,3.245,3.282,3.245
		c0.16,0,0.323-0.011,0.488-0.034l3.223-0.447c1.921-0.267,3.402-2.06,3.301-3.997L56.53,72.935
		c-0.075-1.452-0.299-3.81-0.498-5.257l-1.258-9.147c0.053-0.222,0.087-0.451,0.087-0.689v-9.963L66.7,54.695
		c0.456,0.262,0.976,0.401,1.505,0.401c1.068,0,2.07-0.563,2.615-1.469l0.778-1.295C72.442,50.925,72.003,49.065,70.62,48.185z"
        :fill="color"/>
  <path d="M43.448,21.672c5.975,0,10.836-4.861,10.836-10.836S49.423,0,43.448,0c-5.975,0-10.835,4.861-10.835,10.836
		S37.473,21.672,43.448,21.672z" :fill="color"/>
</g>
</svg>
</template>

<script>
export default {
  name: "IconHumanwalking",

  props: {
    color: {
      type: String,
      requied: true,
    },
  },

  data() {
    return {};
  },
};
</script>

<style lang="scss">

</style>