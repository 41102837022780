<template>
  <div class="layout" :class="{ 'frozen-page': getPageFrozenState }">
    <nav-bar />
    <div class="layout-content">
      <base-card class="interval-picker">
        <icon-chevron @click="previousDay()" :iconColor="'black'" :iconDirection="'left'" />
        {{ $filters.formatDay(getCurrentDate) }}
        <icon-chevron @click="nextDay()" :iconColor="'black'" :iconDirection="'right'" />
      </base-card>
      <slot />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import moment from "moment";

import NavBar from "../components/layout/TheNavBar.vue";
import BaseCard from '../components/ui/BaseCard.vue';
import IconChevron from '../components/svg/IconChevron.vue';

export default {
  name: "LayoutDefaultOnlyDay",

  components: {
    NavBar,
    BaseCard,
    IconChevron,
  },

  data() {
    return {
    };
  },

  methods: {
    ...mapActions("general", ["setCurrentDate"]),

    nextDay() {
      if (moment().startOf('day') >= moment(this.getCurrentDate)) {
        this.setCurrentDate(moment(this.getCurrentDate).clone().add(1, 'day'));
      }
    },

    previousDay() {
      this.setCurrentDate(moment(this.getCurrentDate).clone().add(-1, 'day'));
    },
  },

  computed: {
    ...mapGetters("general", ["getPageFrozenState", "getCurrentDate"]),
  },
};
</script>

<style lang="scss" scoped>
.layout {
  // display: flex;
  // flex-direction: column;
  overflow: scroll;
  height: 100vh;

  &::-webkit-scrollbar {
    display: none;
  }
}

.layout-content {
  margin-top: 4em;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

/* .layout-content {
    padding: 1.5em;
    margin-top: 4em;
    background-color: #F2F8FD;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    user-select: none;
  } */

.interval-picker {
    align-items: center;
    font-size: var(--default-font-size-small);
    line-height: 20px;
    font-weight: 500;
    color: #1B1C1E;
    font-family: var(--default-font);
    display: flex;
    gap: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  
    .chevron:first-child {
      margin-left: 1em;
    }
  
    .chevron:last-child {
      margin-right: 1em;
    }
  }

.active {
  font-weight: 700;
}

.frozen-page {
  overflow-y: hidden !important;
}

@media (prefers-color-scheme: dark) {
  /* .layout-content {
      background-color: #010213
    } */

  .interval-picker {
      color: #F5F5F5;
    }
}
</style>