<template>
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path 
                d="M240-40q-33 0-56.5-23.5T160-120v-440q0-33 23.5-56.5T240-640h120v80H240v440h480v-440H600v-80h120q33 0 56.5 23.5T800-560v440q0 33-23.5 56.5T720-40H240Zm200-280v-447l-64 64-56-57 160-160 160 160-56 57-64-64v447h-80Z"
                :fill="color"/>
        </svg>

    
</template>

<script>
export default {
    name: "IconDownloadIOS",

    props: {
        color: {
            type: String,
            requied: true,
        },
    },

    data() {
        return {};
    },
};
</script>

<style lang="scss">
.icon-download {
    fill: #685AF4;
}

@media (prefers-color-scheme: dark) {
    .icon-download {
        fill: #685AF4;
    }
}
</style>