<template>
    <div v-if="this.canDisplay" class="summary-layout-only-day">
        <section class="summary-section">
            <h3 v-if="this.isToday">
                {{ $filters.capitalize($t('activity.summary.today_activities')) }}
            </h3>
            <h3 v-else>
                {{ $filters.capitalize($t('activity.activities_list.title')) }}
            </h3>
            <base-card :cardLink="getLastActivityDataDate ? '/exercise-level' : null">
                <card-content :iconWrapper="{ iconType: 'icon-lightning' }">
                    <template v-slot:header>
                        {{ $filters.capitalize($t('activity.exercise_level.title')) }}
                    </template>
                    <template v-slot:content>
                        <h5>
                            {{ $filters.upperCase($t('global.total')) }}
                        </h5>
                        <span v-if="this.getCurrentExerciseLevel.length">
                            <data-formater :loading="getLoadingActivity" :type="'exercise'" :size="1"
                                :duration="getActivityRangeType == 'day' ? (exerciseModerate + exerciseHeavy) / 2 : exerciseAverage"
                                :episodes="0" />
                        </span>
                        <graph-exercise-level />
                    </template>
                </card-content>
            </base-card>
            <base-card :cardLink="'/activities-list'">
                <card-content>
                    <template v-slot:content>
                        <h4>
                            {{ $filters.capitalize($t('activity.summary.details_title')) }}
                        </h4>
                    </template>
                </card-content>
            </base-card>
        </section>
        <div v-if="isShowingSleep">
            <sleep-card />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { shallowRef } from 'vue';

import moment from 'moment';

import LayoutSummaryOnlyDay from "@/layouts/LayoutSummaryOnlyDay.vue";
import BaseCard from '@/components/ui/BaseCard.vue';
import CardContent from '@/components/ui/CardContent.vue';
import DataFormater from '@/components/ui/DataFormater.vue';
import GraphExerciseLevel from '@/components/graphics/GraphExerciseLevel.vue';
import SleepCard from "@/views/summary/SleepCard.vue"

export default {
    name: "ActivitySummary",

    components: {
        BaseCard,
        CardContent,
        DataFormater,
        GraphExerciseLevel,
        SleepCard
    },

    data() {
        return {
            canDisplay: false,
            isShowingSleep: false,
        };
    },

    methods: {

        ...mapActions("general", ["setCurrentDate"]),
        ...mapActions("user", ["setToken", "setEntityID", "setUserDemo", "removeUserDemo", "fetchImageProfil", "fetchEntity", "fetchDevice", "setFlavor"]),
        ...mapActions("activity", ["setActivityDemo", "removeActivityDemo", "setActivityRangeType", "setShowSleep"]),

        arrayTotal(array) {
            let sum = 0;
            if (Array.isArray(array)) {
                array.forEach(elem => {
                    sum += elem[1]
                })
                return sum

            } else {
                return 0;
            }
        },

        iconSuffix(type) {

            if (this.isEntityCat()) {
                if (type == "walking") {
                    return "walking-cat"
                }
                if (type == "running") {
                    return "running-cat"
                }
                if (type == "trotting") {
                    return "trotting-cat"
                }
                if (type == "resting") {
                    return "resting-cat"
                }
                if (type == "petting") {
                    return "petting-cat"
                }
            }

            return type
        },

        isEntityCat() {
            return this.getEntityType == "cat"
        },
    },

    computed: {
        ...mapGetters("general", ["getCurrentDate"]),
        ...mapGetters("user", ["getToken", "getEntityId", "getEntityName", "getEntityType", "getLastActivityDataDate"]),
        ...mapGetters("activity", ["getActivityRangeType", "getLoadingActivity", "getCurrentExerciseLevel", "getShowSleep"]),

        exerciseModerate() {
            let exerciseLevel = this.getCurrentExerciseLevel;
            return this.arrayTotal(exerciseLevel[0].data)
        },

        exerciseHeavy() {
            let exerciseLevel = this.getCurrentExerciseLevel;
            return this.arrayTotal(exerciseLevel[1].data)
        },
        exerciseAverage() {
            return this.arrayAverage(this.getCurrentExerciseLevel[0].data) + this.arrayAverage(this.getCurrentExerciseLevel[1].data)
        },

        isiOS() {
            return (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream)
        },

        isToday() {
            let now = moment();
            return now.isSame(this.getCurrentDate, 'day')
        },
    },

    async mounted() {
        await this.fetchEntity();
        await this.fetchDevice();
    },

    beforeCreate() {
        let parameters = this.$route.query;
        if (parameters["token"]) {
            let token = parameters["token"];
            localStorage.setItem('token', token);
        }

        if (parameters["entity_id"]) {
            let entity_id = parameters["entity_id"];
            localStorage.setItem('entityId', entity_id);
        }
    },

    async created() {
        let parameters = this.$route.query;
        if (parameters["flavor"]) {
            let flavor = parameters["flavor"];
            document.documentElement.setAttribute("flavor", flavor);
            await this.setFlavor(flavor);
        } else {
            await this.setFlavor(null);
        }

        await this.setCurrentDate(moment());
        await this.setActivityRangeType('day');
        this.isShowingSleep = this.getShowSleep;
        if (this.isShowingSleep == false) {
            this.setShowSleep(true);
        }
        this.canDisplay = true

        const layout = shallowRef(LayoutSummaryOnlyDay);
        this.$emit("update:layout", layout);
    },
};
</script>

<style lang="scss" scoped>
.hide {
    display: none;
}
</style>
