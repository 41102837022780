<template>
    <div class="heartRate-layout">
        <section class="health-section">
            <h3>
                {{ $filters.capitalize($t('health.heart_signature.graph_title', { entityName: getEntityName })) }}
            </h3>
            <base-card>
                <GraphDateSwitcher>
                    <card-content
                        :iconWrapper="{ iconType: 'icon-heart-signature' }">
                        <template v-slot:header>
                            {{ $filters.capitalize($t('health.heart_signature.title')) }}
                        </template>
                        <template v-slot:content>
                            <health-rate-formater v-if="getCurrentPoincare.length != 0" :type="'is_afib'" :afibValue="getCurrentAfibRisk"/>
                            <graph-heart-signature v-if="getCurrentPoincare" />
                            <p v-else-if="isEntityCat()">
                                {{ $filters.capitalize($t('health.heart_signature.help_card_content_cat_fmt', { productName: this.$t(this.getProductNameKey) })) }}
                            </p>
                            <p v-else>
                                {{ $filters.capitalize($t('health.heart_signature.help_card_content_dog_fmt', { productName: this.$t(this.getProductNameKey) })) }}
                            </p>
                        </template>
                    </card-content>
                </GraphDateSwitcher>
            </base-card>
        </section>
        <section class="health-section">
            <h3>
                {{ $filters.capitalize($t('health.heart_signature.about_title')) }}
            </h3>
            <base-card>
                <i18n-t v-if="isEntityCat()" tag="p" keypath="health.heart_signature.about_card_content_cat">
                    <template #br>
                        <br />
                    </template>
                </i18n-t>
                <i18n-t v-else tag="p" keypath="health.heart_signature.about_card_content">
                    <template #br>
                        <br />
                    </template>
                </i18n-t>
                <!-- <p>
                    {{ $filters.capitalize($t('health.heart_signature.about_card_content')) }}
                </p> -->
            </base-card>
        </section>
        <!-- <section class="health-section">
            <h3>
                {{ $filters.capitalize($t('health.heart_signature.blog_links_title')) }}
            </h3>
            <blog-card :blogImageSrc="'dog-happy.png'" :blogName="'health.heart_rate.blog_name_factors'" />
            <blog-card :blogImageSrc="'dog-smile.png'" :blogName="'health.heart_rate.blog_name_problems'" />
        </section> -->
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import LayoutDefault from "@/layouts/LayoutDefault.vue";
import BaseCard from '@/components/ui/BaseCard.vue';
import CardContent from '@/components/ui/CardContent.vue';
import GraphDateSwitcher from "@/components/graphics/GraphDateSwitcher.vue";
import GraphHeartSignature from '@/components/graphics/GraphHeartSignature.vue';
import HealthRateFormater from '@/components/ui/HealthRateFormater.vue'

// import BlogCard from '@/components/ui/BlogCard.vue';

export default {
    name: "HeartSignature",

    components: {
        BaseCard,
        CardContent,
        GraphDateSwitcher,
        GraphHeartSignature,
        HealthRateFormater
        // BlogCard,
    },

    data() {
        return {
        };
    },

    methods: {
        ...mapActions("general", ["setCurrentDate", "setDayPicker"]),

        isEntityCat() {
            return this.getEntityType == "cat"
        },
    },

    computed: {
        ...mapGetters("general", ["getCurrentDate"]),
        ...mapGetters("user", ["getEntityName", "getEntityType", "isDeviceLWT6", "getProductNameKey"]),
        ...mapGetters("health", ["getLoadingCurrentPoincare", "getCurrentPoincare", "getCurrentAfibRisk"]),
    },

    created() {
        this.setDayPicker(false);
        this.$emit("update:layout", LayoutDefault);
    },
};
</script>
  
<style lang="scss" scoped>
.heartRate-layout {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.data-rate {
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    color: #8C8C8C;
    font-family: var(--default-font);

    strong {
        font-weight: 700;
        font-size: 32px;
        line-height: 45px;
        color: #3B3B3B;
    }
}

.extrem-card {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    &-title {
        font-family: var(--default-font);
        font-weight: 700;
        font-size: var(--default-font-size-small);
        line-height: 20px;
        color: #3B3B3B;
    }

    &-content {
        display: flex;
        gap: 0.5em;
        align-items: baseline;
        font-family: var(--default-font);
        font-weight: 600;
        font-size: 12px;
        line-height: 17px;
        color: #8C8C8C;
    }

    &-value {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: #434343;
    }
}

.loading-heart-signature {
    position: absolute;
    top: calc(50% - 14.5px);
    left: calc(50% - 30px);
}

@media (prefers-color-scheme: dark) {
    .data-rate {
        color: #8C8C8C;

        strong {
            color: #F0F0F0;
        }
    }

    .extrem-card {

        &-title {
            color: #F0F0F0;
        }

        &-content {
            color: #8C8C8C;
        }

        &-value {
            color: #F0F0F0;
        }
    }
}
</style>