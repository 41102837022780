const state = {
    isPageFrozen: false,
    dayPicker: false,
    currentDate: null,
}

const getters = {
    getPageFrozenState(state) {
        return state.isPageFrozen;
    },
    getDayPicker(state) {
        return state.dayPicker;  
    },
    getCurrentDate(state) {
        return state.currentDate;
    },
}

const actions = {
    async setIsPageFrozen({ commit }, data) {
        commit('SET_ISPAGEFROZEN', data);
    },
    async setDayPicker({ commit }, data) {
        commit('SET_DAYPICKER', data);
    },
    async setCurrentDate({ commit }, data) {
        commit('SET_CURRENTDATE', data);
    },
}

const mutations = {
    SET_ISPAGEFROZEN(state, data) {
        state.isPageFrozen = data;
    },

    SET_DAYPICKER(state, data) {
        if (state.currentDate !== data) {
            state.dayPicker = data;
            localStorage.setItem('dayPicker', data)
        }
    },
    REMOVE_DAYPICKER(state) {
        state.daypicker = false;
        localStorage.removeItem('dayPicker');
    },

    SET_CURRENTDATE(state, data) {
        if (state.currentDate !== data) {
            state.currentDate = data;
            localStorage.setItem('currentDate', data)
        }
    },
    REMOVE_CURRENTDATE(state) {
        state.currentDate = null;
        localStorage.removeItem('currentDate')
    },

    INITIALISE_GENERAL_STORE(state) {
        if (localStorage.getItem('currentDate')) {
            state.currentDate = localStorage.getItem('currentDate');
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}