<template>
    <svg width="60" height="29" viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg" :class="'icp-fill-' + iconColor">
        <circle cx="15" cy="15" r="15">
            <animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15" calcMode="linear"
                repeatCount="indefinite" />
            <animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1"
                calcMode="linear" repeatCount="indefinite" />
        </circle>
        <circle cx="60" cy="15" r="9" fill-opacity="0.3">
            <animate attributeName="r" from="9" to="9" begin="0s" dur="0.8s" values="9;15;9" calcMode="linear"
                repeatCount="indefinite" />
            <animate attributeName="fill-opacity" from="0.5" to="0.5" begin="0s" dur="0.8s" values=".5;1;.5"
                calcMode="linear" repeatCount="indefinite" />
        </circle>
        <circle cx="105" cy="15" r="15">
            <animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15" calcMode="linear"
                repeatCount="indefinite" />
            <animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1"
                calcMode="linear" repeatCount="indefinite" />
        </circle>
    </svg>
</template>

<script>
export default {
    name: "ThreeDots",

    props: {
        iconColor: {
            type: String,
            default: "black",
        },
        iconSize: {
            type: Number,
            default: 10,
        }
    },
}
</script>

<style scoped>
.icp-fill-black {
  fill: #3B3B3B;
}
.icp-fill-heart {
  fill: #FF1449;
}

.icp-fill-lung {
    fill: #399D92;
}

.icp-fill-exercise, .icp-fill-resting, .icp-fill-walking, .icp-fill-running  {
    fill: #1d60e0;
}

.icp-fill-barking, .icp-fill-eating, .icp-fill-playing, .icp-fill-petting {
    fill: #f79d00;
}
</style>