<template>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M1.2832 11.0008C1.2832 5.63448 5.6335 1.28418 10.9999 1.28418C16.3662 1.28418 20.7165 5.63448 20.7165 11.0008C20.7165 16.3672 16.3662 20.7175 10.9999 20.7175C5.6335 20.7175 1.2832 16.3672 1.2832 11.0008ZM11.2279 7.48899C10.8167 7.41846 10.3938 7.49574 10.0341 7.70713C9.67437 7.91853 9.4011 8.2504 9.26265 8.64398C9.10076 9.10418 8.59645 9.34602 8.13624 9.18413C7.67603 9.02223 7.4342 8.51793 7.59609 8.05772C7.87299 7.27057 8.41954 6.60682 9.13893 6.18403C9.85832 5.76124 10.7041 5.60669 11.5265 5.74776C12.349 5.88882 13.0949 6.3164 13.6323 6.95476C14.1696 7.59299 14.4637 8.40074 14.4625 9.23499C14.4621 10.5868 13.4599 11.4795 12.7442 11.9567C12.3594 12.2132 11.9809 12.4018 11.702 12.5258C11.5613 12.5883 11.4429 12.6357 11.3575 12.6682C11.3148 12.6845 11.2801 12.6971 11.2549 12.7061L11.2242 12.7169L11.2144 12.7202L11.211 12.7214L11.2096 12.7218C11.2096 12.7218 11.2085 12.7222 10.9292 11.8842L11.2085 12.7222C10.7457 12.8765 10.2455 12.6263 10.0912 12.1635C9.93698 11.7009 10.1869 11.2008 10.6494 11.0463L10.663 11.0415C10.6766 11.0367 10.6989 11.0286 10.7286 11.0173C10.7882 10.9946 10.8767 10.9593 10.9845 10.9114C11.2025 10.8144 11.4865 10.6718 11.7642 10.4867C12.3734 10.0806 12.6959 9.64858 12.6959 9.23418L12.6959 9.23286C12.6965 8.81565 12.5494 8.41168 12.2808 8.0925C12.0121 7.77332 11.6391 7.55953 11.2279 7.48899ZM10.1165 15.4175C10.1165 14.9297 10.512 14.5342 10.9999 14.5342H11.0087C11.4966 14.5342 11.892 14.9297 11.892 15.4175C11.892 15.9054 11.4966 16.3008 11.0087 16.3008H10.9999C10.512 16.3008 10.1165 15.9054 10.1165 15.4175Z"
            :fill="color" />
    </svg>
</template>
  
<script>
export default {
    name: "QuestionMark",

    props: {
        color: {
            type: String,
            requied: true,
        },
    },

    data() {
        return {};
    },
};
</script>
  
<style lang="scss">

</style>