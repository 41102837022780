<template>
    <div>
        <section class="summary-section">
            <base-card :cardLink="getLastHealthDataDate ? '/heart-signature' : null">
                <card-content :iconWrapper="{ iconType: 'icon-heart-signature' }">
                    <template v-slot:header>
                        {{ $filters.capitalize($t('health.heart_signature.title')) }}
                    </template>
                    <template v-slot:content>
                        <three-dots class="loading-heart-signature" v-if="getLoadingLastWeekPoincare"
                            :iconColor="'heart'" />
                        <graph-heart-signature :isSummary="true" v-if="isHeartSignature" />
                        <p v-else-if="isEntityCat()" v-html="$t('health.heart_signature.help_card_content_cat_fmt', { productName: this.$t(this.getProductNameKey) })"></p>
                        <p v-else v-html="$t('health.heart_signature.help_card_content_dog_fmt', { productName: this.$t(this.getProductNameKey) })"></p>
                    </template>
                </card-content>
            </base-card>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { shallowRef } from 'vue';

import moment from 'moment';

import LayoutSummaryNoHeader from "@/layouts/LayoutSummaryNoHeader.vue";
import GraphHeartSignature from "@/components/graphics/GraphHeartSignature.vue";
import BaseCard from '@/components/ui/BaseCard.vue';
import CardContent from '@/components/ui/CardContent.vue';
import ThreeDots from '@/components/svg/loader/ThreeDots.vue';

export default {
    name: "HealthSummary",

    components: {
        BaseCard,
        GraphHeartSignature,
        CardContent,
        ThreeDots,
    },

    data() {
        return {
            heartSignature: null,
            yesterdaySummary: false,
        };
    },

    methods: {
        ...mapActions("general", ["setCurrentDate"]),
        ...mapActions("user", ["setToken", "setEntityID", "setUserDemo", "removeUserDemo", "fetchImageProfil"]),
        ...mapActions("health", ["setHealthRangeType", "setHealthDemo", "removeHealthDemo", "fetchTodayHR", "fetchTodayRR", "fetchLastWeekPoincare"]),

        isEntityCat() {
            return this.getEntityType == "cat"
        },
    },

    computed: {
        ...mapGetters("user", ["getToken", "getEntityId", "getEntityName", "getLastHealthDataDate", "getEntityType", "isDeviceLWT6", "getProductNameKey"]),
        ...mapGetters("health", ["getTodayHR", "getLoadingTodayHR", "getTodayRR", "getLoadingTodayRR", "getLastWeekPoincare", "getLoadingLastWeekPoincare"]),
        isRespiratoryRate() {
            if (this.getTodayRR) {
                return true;
            }
            return false;
        },
        isHeartRate() {
            if (this.getTodayHR) {
                return true;
            }
            return false;
        },
        isHeartSignature() {
            if (this.getLastWeekPoincare.length) {
                return true
            }
            return false;
        },
        isTodayData() {
            return this.getTodayHR && this.getTodayRR
        },
    },

    async mounted() {
        if (this.getLastHealthDataDate) {
          let today = moment().format('YYYY-MM-DD');
          let one_week_ago = moment().add(-7, 'day').format('YYYY-MM-DD');

          await this.fetchLastWeekPoincare({
            start: one_week_ago,
            end: today,
            entity_id: localStorage.getItem('entityId')
          });
        }
    },

    beforeCreate() {
        let parameters = this.$route.query;
        if (parameters["token"]) {
            let token = parameters["token"];
            localStorage.setItem('token', token);
        }

        if (parameters["entity_id"]) {
            let entity_id = parameters["entity_id"];
            localStorage.setItem('entityId', entity_id);
        }
    },

    async created() {
        let parameters = this.$route.query;
        if (parameters["token"]) {
            let token = parameters["token"];
            await this.setToken(token);
        }

        if (parameters["entity_id"]) {
            let entity_id = parameters["entity_id"];
            await this.setEntityID(entity_id);
        }
        await this.setCurrentDate(moment());
        await this.setHealthRangeType('week');

        const layout = shallowRef(LayoutSummaryNoHeader);

        this.$emit("update:layout", layout);
    },
};
</script>

<style lang="scss" scoped>
.data-rate {
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    color: #8C8C8C;
    font-family: var(--default-font);

    strong {
        font-weight: 700;
        font-size: 24px;
        line-height: 34px;
        color: #3B3B3B;
    }
}

.loading-heart-signature {
    position: absolute;
    top: calc(50% - 14.5px);
    left: calc(50% - 30px);
}


@media (prefers-color-scheme: dark) {
    .data-rate {
        color: #8C8C8C;

        strong {
            color: #F0F0F0;
        }
    }

    .export-data-card {
        color: #F0F0F0;
    }
}
</style>