<template>
  <svg class="chevron" :class="'chevron-' + iconDirection" :height="iconSize" viewBox="0 0 11 18" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M2 2L9 9L2 16" :class="'icp-' + iconColor" stroke-width="2.5" stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: "IconChevron",

  props: {
    iconColor: {
      type: String,
      default: "white",
    },
    iconSize: {
      type: Number,
      default: 10,
    },
    iconDirection: {
      type: String,
      default: "right",
    },
  },
};
</script>

<style lang="scss" scoped>
.chevron {
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  -ms-transition: -ms-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;

  &-right {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }

  &-down {
    transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
  }

  &-left {
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
  }

  &-up {
    transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
  }
}

.active {
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  -ms-transition: -ms-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  opacity: 0.8;
}

.icp-black {
  stroke: #1B1C1E;
}

@media (prefers-color-scheme: dark) {
  .icp-black {
    stroke: #F5F5F5;
  }
}
</style>