import httpClient from './httpClient';

const END_POINT_TOKEN = '/api/v1/auth/token/';

//add this and remove login_data in postUserData to a direct access
// let login_data = { email: 'jean-baptiste.choquet@invoxia.com', password: 'password', };

const postUserData = (user_data) => httpClient.post(END_POINT_TOKEN, user_data);

export {
    postUserData,
}