<template>
    <div class="graph-container">
        <template v-if="this.series[0].data.length">
            <h2 class="heart-signature-nodata" v-if="this.series[0].data.length == 1">
                {{ $filters.capitalize($t('global.no_data_yet')) }}
            </h2>
            <apexchart :options="chartOptions" :series="series" :height="'100%'"></apexchart>
        </template>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { useCssVar } from '@vueuse/core'
import { ref } from 'vue'

import moment from 'moment';

export default {
    name: "GraphHeartRate",

    props: {
        isSummary: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        const defaultFont = useCssVar('--default-font', ref(null));
        return {
            try: 0,
            series: [{
                data: []
            }],
            chartOptions: {
                theme: {
                    monochrome: {
                        enabled: true,
                        color: '#FF1449',
                    }
                },
                chart: {
                    height: '100%',
                    type: 'scatter',
                    zoom: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false
                    },
                    animations: {
                        enabled: false,
                        easing: 'easeinout',
                        animateGradually: {
                            enabled: false,
                            delay: 1000
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 1500
                        }
                    }
                },
                dataLabels: {
                    enabled: false,
                },
                markers: {
                    size: 1.5,
                    colors: '#FF1449',
                    strokeWidth: 0,
                    fillOpacity: 0.5,
                    shape: "circle",
                    showNullDataPoints: false,
                },
                tooltip: {
                    enabled: false,
                },
                grid: {
                    show: true,
                    borderColor: "#D9D9D9",
                    strokeDashArray: 3,
                    position: "back",
                    xaxis: {
                        lines: {
                            show: true
                        }
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: -5,
                        left: 0
                    },
                },
                xaxis: {
                    type: 'numeric',
                    tickPlacement: 'between',
                    min: 200,
                    max: 2500,
                    labels: {
                        style: {
                            colors: '#8C8C8C',
                            fontSize: '10px',
                            fontFamily: defaultFont,
                            fontWeight: 400,
                            cssClass: 'apexcharts-xaxis-signature-label',
                        },
                        formatter: function (val) {
                            return parseFloat(val / 1000).toFixed(1)
                        }
                    },
                    axisBorder: {
                        show: false,
                        offsetY: -15,
                    },
                },
                yaxis: {
                    type: 'numeric',
                    tickAmount: 5,
                    min: 200,
                    max: 2500,
                    opposite: true,
                    labels: {
                        style: {
                            colors: '#1B1C1E',
                            fontSize: '10px',
                            fontFamily: defaultFont,
                            fontWeight: 400,
                            cssClass: 'apexcharts-yaxis-label',
                        },
                        formatter: function (val) {
                            return parseFloat(val / 1000).toFixed(1)
                        },
                        offsetX: -10,
                        offsetY: -5
                    },
                    axisBorder: {
                        show: false,
                        offsetX: 0,
                    },
                }
            }
        };
    },

    methods: {
        ...mapActions("health", ["fetchPoincare"]),

        async changevalue(startDate, endDate) {
            await this.fetchPoincare({ start: startDate, end: endDate, entity_id: localStorage.getItem('entityId') });
            if (this.isSummary) {
                this.series = [{
                    data: this.getLastWeekPoincare
                }]
            } else {
                this.series = [{
                    data: (this.getCurrentPoincare.length ? this.getCurrentPoincare : [[0, 0]])
                }]
            }
        },

        async updateTheme() {
            const defaultFont = useCssVar('--default-font', ref(null));
            if (this.getHealthRangeType == 'week') {
                await this.changevalue(moment(this.getCurrentDate).startOf('isoWeek').format('YYYY-MM-DD'), moment(this.getCurrentDate).endOf('isoWeek').format('YYYY-MM-DD'))

                this.chartOptions = {
                    xaxis: {
                        type: 'numeric',
                        tickPlacement: 'between',
                        min: 200,
                        max: this.maxHeartSignatureValue,
                        labels: {
                            style: {
                                colors: '#8C8C8C',
                                fontSize: '10px',
                                fontFamily: defaultFont,
                                fontWeight: 400,
                                cssClass: 'apexcharts-xaxis-signature-label',
                            },
                            formatter: function (val) {
                                return parseFloat(val / 1000).toFixed(1)
                            }
                        },
                        axisBorder: {
                            show: false,
                            offsetY: -15,
                        },
                    },
                    yaxis: {
                        type: 'numeric',
                        tickAmount: 5,
                        min: 200,
                        max: this.maxHeartSignatureValue,
                        opposite: true,
                        labels: {
                            style: {
                                colors: '#1B1C1E',
                                fontSize: '10px',
                                fontFamily: defaultFont,
                                fontWeight: 400,
                                cssClass: 'apexcharts-yaxis-label',
                            },
                            formatter: function (val) {
                                return parseFloat(val / 1000).toFixed(1)
                            },
                            offsetX: -10,
                            offsetY: -5
                        },
                        axisBorder: {
                            show: false,
                            offsetX: 0,
                        },
                    }
                }
            } else if (this.getHealthRangeType == 'month') {
                await this.changevalue(moment(this.getCurrentDate).startOf('month').format('YYYY-MM-DD'), moment(this.getCurrentDate).endOf('month').format('YYYY-MM-DD'))

                this.chartOptions = {
                    xaxis: {
                        type: 'numeric',
                        tickPlacement: 'between',
                        min: 200,
                        max: this.maxHeartSignatureValue,
                        labels: {
                            style: {
                                colors: '#8C8C8C',
                                fontSize: '10px',
                                fontFamily: defaultFont,
                                fontWeight: 400,
                                cssClass: 'apexcharts-xaxis-signature-label',
                            },
                            formatter: function (val) {
                                return parseFloat(val / 1000).toFixed(1)
                            }
                        },
                        axisBorder: {
                            show: false,
                            offsetY: -15,
                        },
                    },
                    yaxis: {
                        type: 'numeric',
                        tickAmount: 5,
                        min: 200,
                        max: this.maxHeartSignatureValue,
                        opposite: true,
                        labels: {
                            style: {
                                colors: '#1B1C1E',
                                fontSize: '10px',
                                fontFamily: defaultFont,
                                fontWeight: 400,
                                cssClass: 'apexcharts-yaxis-label',
                            },
                            formatter: function (val) {
                                return parseFloat(val / 1000).toFixed(1)
                            },
                            offsetX: -10,
                            offsetY: -5
                        },
                        axisBorder: {
                            show: false,
                            offsetX: 0,
                        },
                    }
                }
            }
        },
    },

    computed: {
        ...mapGetters("general", ["getCurrentDate"]),
        ...mapGetters("health", ["getLastWeekPoincare", "getLoadingLastWeekPoincare", "getCurrentPoincare", "getHealthRangeType", "getHealthDemo"]),

        maxHeartSignatureValue() {
            // var maxRow = this.series[0].data.map(function (row) { return Math.max.apply(Math, row); });
            // var max = Math.max.apply(null, maxRow);

            // return Math.ceil(max / 100) * 100;
            return 2500
        }
    },

    watch: {
        getHealthRangeType() {
            this.updateTheme();
        },
        getCurrentDate() {
            this.updateTheme();
        },
        getLoadingLastWeekPoincare() {
            if (this.getLoadingLastWeekPoincare == false) {
                this.series = [{
                    data: this.getLastWeekPoincare
                }]
            }
        }
    },

    mounted() {
        if (this.isSummary) {
            this.series = [{
                data: this.getLastWeekPoincare
            }]
        } else {
            this.series = [{
                data: this.getCurrentPoincare
            }]
            this.updateTheme()
        }
    }
}
</script>

<style lang="scss" scoped>
.graph-container {
    width: 100%;
    aspect-ratio: 4/4;
    margin: -1em -0em -1em 0em;
}

.heart-signature-nodata {
    position: absolute;
    top: 50%;
    left: 45%;
    transform: translate(-45%, -50%);
    z-index: 10;
}

@media (prefers-color-scheme: dark) {
    .heart-signature-nodata {
        color: #F0F0F0;
    }
}
</style>