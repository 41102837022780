<template>
    <div class="layout-dashboard" :class="{ 'frozen-container': getPageFrozenState }">
      <nav-bar-header />
      <div class="layout-content">
        <base-card class="interval-picker">
          <icon-chevron @click="previousDay()" :iconColor="'black'" :iconDirection="'left'" />
          {{ $filters.formatDay(getCurrentDate) }}
          <icon-chevron @click="nextDay()" :iconColor="'black'" :iconDirection="'right'" />
        </base-card>
        <slot />
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from "vuex";

  import moment from "moment";
  
  import NavBarHeader from "../components/layout/TheNavBarHeader.vue";
  import BaseCard from '../components/ui/BaseCard.vue';
  import IconChevron from '../components/svg/IconChevron.vue';
  
  export default {
    name: "LayoutSummaryOnlyDay",
  
    components: {
      NavBarHeader,
      BaseCard,
      IconChevron,
    },

    data() {
      return {
      };
    },

    methods: {
      ...mapActions("general", ["setCurrentDate"]),
  
      nextDay() {
        if (moment().startOf('day') >= moment(this.getCurrentDate)) {
          this.setCurrentDate(moment(this.getCurrentDate).clone().add(1, 'day'));
        }
      },
  
      previousDay() {
        this.setCurrentDate(moment(this.getCurrentDate).clone().add(-1, 'day'));
      },
    },
  
    computed: {
      ...mapGetters("general", ["getPageFrozenState", "getCurrentDate"]),
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .layout-dashboard {
    display: flex;
    flex-direction: column; 
    overflow: scroll;
    height: 100vh;
  
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .layout-content {
    margin-top: 6em;
  }
  
/*   .layout-content {
    padding: 1.5em;
    background-color: #F2F8FD;
    user-select: none;
    margin-top: 6em;
  } */

  .interval-picker {
    align-items: center;
    font-size: var(--default-font-size-small);
    line-height: 20px;
    font-weight: 500;
    color: #1B1C1E;
    font-family: var(--default-font);
    display: flex;
    gap: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  
    .chevron:first-child {
      margin-left: 1em;
    }
  
    .chevron:last-child {
      margin-right: 1em;
    }
  }
  
  @media (prefers-color-scheme: dark) {
/*     .layout-content {
      background-color: #010213
    } */

    .interval-picker {
      color: #F5F5F5;
    }
  }
  </style>