import httpClient from './httpClient';

const END_POINT_COLLAR = '/collar/v1/entities/';
const END_POINT_IMAGE = '/image_link/';
const END_POINT_HEALTH = '/health/';
const END_POINT_HEALTH_HISTORY = '/health_history';
const END_POINT_POINCARE = '/poincare/';
const END_POINT_REPORT = 'report/';


const END_POINT_PATH_ACTIVITY_SUMMARY = (entityId) => `/collar/v1/entities/${entityId}/activities/summary/`
const END_POINT_PATH_ACTIVITY_DATA = (entityId) => `/collar/v1/entities/${entityId}/activities/data/`
const END_POINT_PATH_SLEEP = (entityId) => `/collar/v1/entities/${entityId}/sleep`

const getImageProfilLink = (data) => httpClient.get(END_POINT_COLLAR + data.entity_id + END_POINT_IMAGE, { 
    useCache: true 
});
const getEntityInfos = (data) => httpClient.get(END_POINT_COLLAR + data.entity_id + '/');
const getHealth = (data) => httpClient.get(END_POINT_COLLAR + data.entity_id + END_POINT_HEALTH, { 
    params: {
        date_start: data.start, 
        date_end: data.end, 
        data: data.type, 
        limit:data.limit
    } 
});
const getHealthValues = (data) => httpClient.get(END_POINT_COLLAR + data.entity_id + END_POINT_HEALTH_HISTORY, {
    params: {
        date_start: data.start,
        date_end: data.end,
        data: data.type,
    }
});
const getHealthReportUrl = (data) => httpClient.get(END_POINT_COLLAR + data.entity_id + END_POINT_HEALTH + END_POINT_REPORT);

const getPoincare = (data) => httpClient.get(END_POINT_COLLAR + data.entity_id + END_POINT_POINCARE, { 
    params: {
        date_start: data.start, 
        date_end: data.end, 
        cluster: 1
    } 
});
const getActivities = (data) => httpClient.get(END_POINT_PATH_ACTIVITY_SUMMARY(data.entity_id), { 
    params: {
        date_start: data.start, 
        date_end: data.end
    } 
});
const getActivityData = (data) => httpClient.get(END_POINT_PATH_ACTIVITY_DATA(data.entity_id), { 
    params: {
        type: data.type, 
        date_start: data.start, 
        date_end: data.end
    } 
});

const getSleep = (data) => httpClient.get(END_POINT_PATH_SLEEP(data.entity_id), {
    params: {
        date_start: data.start, 
        date_end: data.end,
        type: 'sleeping'
    }
})

export {
    getImageProfilLink,
    getEntityInfos,
    getHealth,
    getHealthValues,
    getPoincare,
    getActivities,
    getActivityData,
    getHealthReportUrl,
    getSleep,
}

