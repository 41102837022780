import axios from 'axios';

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.get['Content-Type'] = '*/*';

const httpClient = axios.create({
    baseURL: "https://labs.invoxia.io",
    timeout: 30000,
    xsrfHeaderName: "X-CSRFTOKEN",
    xsrfCookieName: "csrftoken",
    xhrFields: {
        withCredentials: true
    }
});

const getAuthToken = () => localStorage.getItem('token');

const authInterceptor = (config) => {
    config.headers['Authorization'] = "Bearer " + getAuthToken();
    return config;
}

httpClient.interceptors.request.use(authInterceptor);

httpClient.interceptors.response.use(
    response => {
        if (response.status === 200 || response.status === 201) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    
    error => {
        if (error.response == undefined) {
            let vm = window.app
            vm.$router.push('/error')
            return Promise.reject(error)
        }
    
        const status = error.response.status
        
        if (status == 401) {
            let vm = window.app
            if (!localStorage.getItem('token')) {
                vm.$router.push('/login')
            }
        } else if (status == 403) {
            let vm = window.app
            localStorage.removeItem('token')
            if (vm.$route.name != '/login') {
                vm.$router.push('/login')
            }
        } else {
            console.log(error.response)
            console.log(error.message)
        }
        return Promise.reject(error)
    }
);

export default httpClient;
