<template>
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M18.961 19.169c-.233-.422-.443-.844-.636-1.265l.775-.285-.346-.938-.817.3c-.205-.531-.378-1.063-.516-1.595l.893-.181-.2-.98-.906.183c-.115-.635-.18-1.271-.2-1.908h.992v-1h-.985c.028-.634.102-1.269.223-1.906l.893.181.199-.98-.871-.177c.14-.527.31-1.056.513-1.587l.782.288.346-.938-.743-.273c.188-.419.393-.839.621-1.261 1.862 1.816 3.022 4.349 3.022 7.153 0 2.813-1.167 5.351-3.039 7.169m-12.33 1.257c.35-.609.65-1.219.922-1.831l1.102.405.345-.938-1.064-.392c.238-.627.442-1.256.6-1.887l1.071.217.199-.98-1.064-.216c.14-.766.22-1.534.241-2.304h1.017v-1h-1.023c-.03-.766-.119-1.533-.266-2.302l1.078-.218-.199-.98-1.093.222c-.16-.626-.358-1.252-.596-1.879l1.099-.404-.345-.939-1.135.418c-.268-.611-.564-1.222-.906-1.833 1.556-.999 3.402-1.585 5.386-1.585s3.83.586 5.386 1.585c-.341.611-.638 1.222-.905 1.833l-1.136-.418-.345.939 1.099.404c-.238.627-.436 1.253-.595 1.879l-1.094-.222-.199.98 1.077.218c-.146.769-.234 1.536-.265 2.302h-1.023v1h1.017c.021.77.1 1.538.241 2.304l-1.064.216.199.98 1.071-.217c.158.631.362 1.26.601 1.887l-1.065.392.345.938 1.102-.405c.272.612.573 1.222.922 1.831-1.553.991-3.391 1.574-5.369 1.574-1.977 0-3.816-.583-5.369-1.574m-4.631-8.426c0-2.804 1.161-5.337 3.023-7.153.227.421.432.842.62 1.261l-.742.273.345.938.783-.288c.203.531.373 1.06.512 1.587l-.871.177.199.98.893-.181c.121.637.196 1.272.224 1.906h-.986v1h.993c-.021.637-.086 1.273-.201 1.908l-.906-.183-.199.98.892.181c-.137.532-.311 1.064-.515 1.595l-.818-.3-.345.938.775.285c-.193.422-.403.843-.636 1.265-1.872-1.817-3.04-4.356-3.04-7.169m10-12c-6.623 0-12 5.377-12 12s5.377 12 12 12 12-5.377 12-12-5.377-12-12-12" :fill="color"/></svg>
</template>
  
<script>
export default {
    name: "IconPlaying",

    props: {
        color: {
            type: String,
            requied: true,
        },
    },

    data() {
        return {};
    },
};
</script>
  
<style lang="scss">

</style>